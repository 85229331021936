import { ReactComponent as StatusFailureCircleIcon } from '@app/assets/icon-status-failure-circle.svg';
import { ReactComponent as FormatUnsupportedJPEGIcon } from '@app/assets/icon-format-unsupported-jpeg.svg';
import Modal from '@app/components/Modal';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

export type UnsupportedFileModalProps = {
  show?: boolean;
  onHide?: () => void;
  fileNames?: string[];
};

const UnsupportedFileModal: React.FC<UnsupportedFileModalProps> = ({
  show,
  onHide,
  fileNames,
}) => (
  <Modal show={show} onHide={onHide}>
    <div className="c-unsupported-file-format">
      <div className="g-gutter-bottom-0-50">
        <StatusFailureCircleIcon />
      </div>
      <h2 className="t-heading-2 t-normal">
        <FormattedMessage {...messages.unsupportedFormat} />
      </h2>
      {fileNames?.map((fileName) => (
        <div
          key={fileName}
          className="c-unsupported-file-format__file g-gutter-top-2"
        >
          <div className="c-unsupported-file-format__file-img">
            <FormatUnsupportedJPEGIcon />
          </div>
          <div className="c-unsupported-file-format__file-name">{fileName}</div>
        </div>
      ))}
      <p className="t-body-2 g-gutter-top-2">
        <FormattedMessage {...messages.unsupportedFormatMessage1} />
        <br />
        <br />
        <FormattedMessage {...messages.unsupportedFormatMessage2} />
      </p>
      <div className="c-unsupported-file-format__file-actions g-gutter-top-2">
        <a
          href="mailto:support@kontroluje.me"
          className="c-button c-button--primary "
        >
          <span className="c-button__label">
            <FormattedMessage {...messages.contactSupport} />
          </span>
        </a>

        <button
          className="c-button c-button--primary-without-bg "
          onClick={onHide}
        >
          <span className="c-button__label">
            <FormattedMessage {...messages.close} />
          </span>
        </button>
      </div>
    </div>
  </Modal>
);

export default UnsupportedFileModal;
