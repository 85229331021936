import { IntlShape } from 'react-intl';
import { capitalize } from './string';

export const formatRelativeDateTime = (
  intl: IntlShape,
  value: string | number | Date | undefined,
): string => {
  const daysDiff = value
    ? Math.floor(new Date(value).getTime() / (1000 * 60 * 60 * 24)) -
      Math.floor(Date.now() / (1000 * 60 * 60 * 24))
    : NaN;

  return (
    (!isNaN(daysDiff) && daysDiff >= 0
      ? `${capitalize(
          intl.formatRelativeTime(daysDiff, 'day', {
            numeric: 'auto',
          }),
        )} `
      : '') +
    `${intl.formatDate(value, {
      dateStyle: 'short',
    })} – ${intl.formatTime(value)}`
  );
};
