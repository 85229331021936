import { ApiUrl } from '@app/consts';
import { Document } from '@app/types/Document';
import { api } from '@app/utils/api';
import { useMemo, useState } from 'react';
import { QueryObserverResult, useQueries } from 'react-query';
import { generatePath } from 'react-router-dom';

export type DocumentsProcessOptions = {
  retry: number;
  refetchInterval: number;
};

export type DocumentProcess = {
  document: Document;
  processed?: Document;
  isLoading: boolean;
  isError: boolean;
  isRetriesExceeded: boolean;
  retries: number;
};

const useDocumentsProcess = (
  documents: Document[],
  { retry, refetchInterval }: DocumentsProcessOptions,
): DocumentProcess[] => {
  const [retries, setRetries] = useState<Record<string, number>>({});
  const [processed, setProcessed] = useState<Record<string, Document>>({});

  const wordCountQueries = useQueries(
    documents.map((document) => ({
      queryKey: ['documentProcess', document.id],
      queryFn: () =>
        api
          .post<Document>(
            generatePath(ApiUrl.DocumentProcess, {
              documentId: document?.id,
            }),
          )
          .then(({ data }) => data),
      onSuccess: (data) => {
        const document = data as Document;
        setRetries((retries) => ({
          ...retries,
          [document.id]: (retries[document.id] || 0) + 1,
        }));
        if (document.words_count !== null) {
          setProcessed((processed) => ({
            ...processed,
            [document.id]: document,
          }));
        }
      },
      onError: () => {
        setRetries((retries) => ({
          ...retries,
          [document.id]: (retries[document.id] || 0) + 1,
        }));
      },
      enabled:
        document.words_count === null &&
        !processed[document.id] &&
        (retries[document.id] || 0) < retry,
      refetchInterval,
      refetchIntervalInBackground: true,
    })) || [],
  ) as QueryObserverResult<Document>[];

  return useMemo(
    () =>
      documents.map((document, index) => ({
        document,
        processed: processed[document.id],
        retries: retries[document.id],
        isLoading:
          wordCountQueries[index].isLoading ||
          (document.words_count === null &&
            !processed[document.id] &&
            (retries[document.id] || 0) < retry),
        isError: wordCountQueries[index].isError,
        isRetriesExceeded: (retries[document.id] || 0) >= retry,
      })),
    [documents, wordCountQueries, processed, retries, retry],
  );
};

export default useDocumentsProcess;
