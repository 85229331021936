import { defineMessages } from 'react-intl';

export const scope = 'orderStatus';

export default defineMessages({
  input: {
    id: `${scope}.input`,
    defaultMessage: 'Input',
  },
  awaitingPayment: {
    id: `${scope}.awaiting_payment`,
    defaultMessage: 'Awaiting payment',
  },
  paid: {
    id: `${scope}.paid`,
    defaultMessage: 'Paid',
  },
});
