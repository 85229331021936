import { defineMessages } from 'react-intl';

export const scope = 'pages.OrderSubmitPage';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Your document',
  },
  fileFormatsDescription: {
    id: `${scope}.fileFormatsDescription`,
    defaultMessage:
      'We currently work with over 17 file formats. Click on your document file icon below to find out more about how we work in that format.',
  },
  wordsCount: {
    id: `${scope}.wordsCount`,
    defaultMessage:
      '{wordsCount} <strong>{wordsCount, plural, one {word} other {words}}</strong>',
  },
  wordsCountingMessage: {
    id: `${scope}.wordsCountingMessage`,
    defaultMessage: 'Processing (< 5min)',
  },
  wordsCountingError: {
    id: `${scope}.wordsCountingError`,
    defaultMessage: 'Error',
  },
  briefYourEditor: {
    id: `${scope}.briefYourEditor`,
    defaultMessage: 'Brief your editor',
  },
  userTypeLabel: {
    id: `${scope}.userTypeLabel`,
    defaultMessage: 'I am a...',
  },
  userTypePlaceholder: {
    id: `${scope}.userTypePlaceholder`,
    defaultMessage: 'Please choose your role',
  },
  userTypeRequired: {
    id: `${scope}.userTypeRequired`,
    defaultMessage: 'Please choose your role',
  },
  typeLabel: {
    id: `${scope}.typeLabel`,
    defaultMessage: 'Document type',
  },
  typePlaceholder: {
    id: `${scope}.typePlaceholder`,
    defaultMessage: 'e.g. Essay',
  },
  typeMaxLength: {
    id: `${scope}.typeMaxLength`,
    defaultMessage:
      'Type of your document should contain maximum of 100 characters',
  },
  typeRequired: {
    id: `${scope}.typeRequired`,
    defaultMessage: 'Please enter type of your document',
  },
  typeSuggestionEssay: {
    id: `${scope}.typeSuggestionEssay`,
    defaultMessage: 'Essay',
  },
  typeSuggestionDissertation: {
    id: `${scope}.typeSuggestionDissertation`,
    defaultMessage: 'Dissertation',
  },
  typeSuggestionDissertationChapter: {
    id: `${scope}.typeSuggestionDissertationChapter`,
    defaultMessage: 'Dissertation Chapter',
  },
  typeSuggestionCV: { id: `${scope}.typeSuggestionCV`, defaultMessage: 'CV' },
  typeSuggestionCoverLetter: {
    id: `${scope}.typeSuggestionCoverLetter`,
    defaultMessage: 'Cover Letter',
  },
  typeSuggestionThesis: {
    id: `${scope}.typeSuggestionThesis`,
    defaultMessage: 'Thesis',
  },
  typeSuggestionThesisChapter: {
    id: `${scope}.typeSuggestionThesisChapter`,
    defaultMessage: 'Thesis Chapter',
  },
  typeSuggestionResearchPaper: {
    id: `${scope}.typeSuggestionResearchPaper`,
    defaultMessage: 'Research Paper',
  },
  typeSuggestionShortStory: {
    id: `${scope}.typeSuggestionShortStory`,
    defaultMessage: 'Short Story',
  },
  typeSuggestionManuscript: {
    id: `${scope}.typeSuggestionManuscript`,
    defaultMessage: 'Manuscript',
  },
  typeSuggestionReport: {
    id: `${scope}.typeSuggestionReport`,
    defaultMessage: 'Report',
  },
  typeSuggestionSurvey: {
    id: `${scope}.typeSuggestionSurvey`,
    defaultMessage: 'Survey',
  },
  typeSuggestionResearchProposal: {
    id: `${scope}.typeSuggestionResearchProposal`,
    defaultMessage: 'Research Proposal',
  },
  typeSuggestionOther: {
    id: `${scope}.typeSuggestionOther`,
    defaultMessage: 'Other',
  },
  typeSuggestionPressRelease: {
    id: `${scope}.typeSuggestionPressRelease`,
    defaultMessage: 'Press Release',
  },
  typeSuggestionWebsiteContent: {
    id: `${scope}.typeSuggestionWebsiteContent`,
    defaultMessage: 'Website Content',
  },
  typeSuggestionPresentation: {
    id: `${scope}.typeSuggestionPresentation`,
    defaultMessage: 'Presentation',
  },
  typeSuggestionBlog: {
    id: `${scope}.typeSuggestionBlog`,
    defaultMessage: 'Blog',
  },
  typeSuggestionAdmissionEssay: {
    id: `${scope}.typeSuggestionAdmissionEssay`,
    defaultMessage: 'Admission Essay',
  },
  typeSuggestionPoetry: {
    id: `${scope}.typeSuggestionPoetry`,
    defaultMessage: 'Poetry',
  },
  typeSuggestionLetter: {
    id: `${scope}.typeSuggestionLetter`,
    defaultMessage: 'Letter',
  },
  typeSuggestionAssignment: {
    id: `${scope}.typeSuggestionAssignment`,
    defaultMessage: 'Assignment',
  },
  typeSuggestionPaper: {
    id: `${scope}.typeSuggestionPaper`,
    defaultMessage: 'Paper',
  },
  typeSuggestionResume: {
    id: `${scope}.typeSuggestionResume`,
    defaultMessage: 'Resume',
  },
  typeSuggestionPersonalStatement: {
    id: `${scope}.typeSuggestionPersonalStatement`,
    defaultMessage: 'Personal Statement',
  },
  typeSuggestionJournalArticle: {
    id: `${scope}.typeSuggestionJournalArticle`,
    defaultMessage: 'Journal Article',
  },
  typeSuggestionGrantProposal: {
    id: `${scope}.typeSuggestionGrantProposal`,
    defaultMessage: 'Grant Proposal',
  },
  typeSuggestionSocialMediaContent: {
    id: `${scope}.typeSuggestionSocialMediaContent`,
    defaultMessage: 'Social Media Content',
  },
  typeSuggestionArticle: {
    id: `${scope}.typeSuggestionArticle`,
    defaultMessage: 'Article',
  },
  typeSuggestionBook: {
    id: `${scope}.typeSuggestionBook`,
    defaultMessage: 'Book',
  },
  typeSuggestionBusinessPlan: {
    id: `${scope}.typeSuggestionBusinessPlan`,
    defaultMessage: 'Business Plan',
  },
  typeSuggestionBrochure: {
    id: `${scope}.typeSuggestionBrochure`,
    defaultMessage: 'Brochure',
  },
  typeSuggestionEbook: {
    id: `${scope}.typeSuggestionEbook`,
    defaultMessage: 'Ebook',
  },
  typeSuggestionEmail: {
    id: `${scope}.typeSuggestionEmail`,
    defaultMessage: 'Email',
  },
  typeSuggestionMagazine: {
    id: `${scope}.typeSuggestionMagazine`,
    defaultMessage: 'Magazine',
  },
  typeSuggestionNovel: {
    id: `${scope}.typeSuggestionNovel`,
    defaultMessage: 'Novel',
  },
  typeSuggestionNewsletter: {
    id: `${scope}.typeSuggestionNewsletter`,
    defaultMessage: 'Newsletter',
  },
  typeSuggestionScreenplay: {
    id: `${scope}.typeSuggestionScreenplay`,
    defaultMessage: 'Screenplay',
  },
  typeSuggestionScript: {
    id: `${scope}.typeSuggestionScript`,
    defaultMessage: 'Script',
  },
  typeSuggestionSpeech: {
    id: `${scope}.typeSuggestionSpeech`,
    defaultMessage: 'Speech',
  },
  typeSuggestionStatementofPurpose: {
    id: `${scope}.typeSuggestionStatementofPurpose`,
    defaultMessage: 'Statement of Purpose',
  },
  typeSuggestionLiteratureReview: {
    id: `${scope}.typeSuggestionLiteratureReview`,
    defaultMessage: 'Literature Review',
  },
  typeSuggestionPhDProposal: {
    id: `${scope}.typeSuggestionPhDProposal`,
    defaultMessage: 'PhD Proposal',
  },
  typeSuggestionTranslatedContent: {
    id: `${scope}.typeSuggestionTranslatedContent`,
    defaultMessage: 'Translated Content',
  },
  typeSuggestionPhD: {
    id: `${scope}.typeSuggestionPhD`,
    defaultMessage: 'PhD',
  },
  subjectLabel: {
    id: `${scope}.subjectLabel`,
    defaultMessage: 'Document subject',
  },
  subjectPlaceholder: {
    id: `${scope}.subjectPlaceholder`,
    defaultMessage: 'Enter document subject',
  },
  subjectLanguagePlaceholder: {
    id: `${scope}.subjectLanguagePlaceholder`,
    defaultMessage: 'Please select language',
  },
  subjectMaxLength: {
    id: `${scope}.subjectMaxLength`,
    defaultMessage:
      'Subject of your document should contain maximum of 500 characters',
  },
  subjectRequired: {
    id: `${scope}.subjectRequired`,
    defaultMessage: 'Please enter subject of your document',
  },
  languageLabel: {
    id: `${scope}.languageLabel`,
    defaultMessage: 'Language',
  },
  languagePlaceholder: {
    id: `${scope}.languagePlaceholder`,
    defaultMessage: 'Language',
  },
  languageMaxLength: {
    id: `${scope}.languageMaxLength`,
    defaultMessage:
      'Language of your document should contain maximum of 50 characters',
  },
  languageRequired: {
    id: `${scope}.languageRequired`,
    defaultMessage: 'Please enter language of your document',
  },
  languageAmericanEnglish: {
    id: `${scope}.languageAmericanEnglish`,
    defaultMessage: 'American English',
  },
  languageBritishEnglish: {
    id: `${scope}.languageBritishEnglish`,
    defaultMessage: 'British English',
  },
  languageAustralianEnglish: {
    id: `${scope}.languageAustralianEnglish`,
    defaultMessage: 'Australian English',
  },
  languageCanadianEnglish: {
    id: `${scope}.languageCanadianEnglish`,
    defaultMessage: 'Canadian English',
  },
  styleLabel: {
    id: `${scope}.styleLabel`,
    defaultMessage: 'Style/Referencing',
  },
  stylePlaceholder: {
    id: `${scope}.stylePlaceholder`,
    defaultMessage: 'Style',
  },
  styleMaxLength: {
    id: `${scope}.styleMaxLength`,
    defaultMessage:
      'Style of your document should contain maximum of 100 characters',
  },
  styleRequired: {
    id: `${scope}.styleRequired`,
    defaultMessage: 'Please enter style of your document',
  },
  styleSuggestionAGLC: {
    id: `${scope}.styleSuggestionAGLC`,
    defaultMessage: 'AGLC',
  },
  styleSuggestionAMA: {
    id: `${scope}.styleSuggestionAMA`,
    defaultMessage: 'AMA',
  },
  styleSuggestionAPA: {
    id: `${scope}.styleSuggestionAPA`,
    defaultMessage: 'APA',
  },
  styleSuggestionChicagoAuthorDate: {
    id: `${scope}.styleSuggestionChicagoAuthorDate`,
    defaultMessage: 'Chicago (Author-Date)',
  },
  styleSuggestionChicagoFootnotes: {
    id: `${scope}.styleSuggestionChicagoFootnotes`,
    defaultMessage: 'Chicago (Footnotes)',
  },
  styleSuggestionHarvard: {
    id: `${scope}.styleSuggestionHarvard`,
    defaultMessage: 'Harvard',
  },
  styleSuggestionIEEE: {
    id: `${scope}.styleSuggestionIEEE`,
    defaultMessage: 'IEEE',
  },
  styleSuggestionMHRA: {
    id: `${scope}.styleSuggestionMHRA`,
    defaultMessage: 'MHRA',
  },
  styleSuggestionMLA: {
    id: `${scope}.styleSuggestionMLA`,
    defaultMessage: 'MLA',
  },
  styleSuggestionNA: {
    id: `${scope}.styleSuggestionNA`,
    defaultMessage: 'N/A',
  },
  styleSuggestionOSCOLA: {
    id: `${scope}.styleSuggestionOSCOLA`,
    defaultMessage: 'OSCOLA',
  },
  styleSuggestionOther: {
    id: `${scope}.styleSuggestionOther`,
    defaultMessage: 'Other (Please enter in notes below)',
  },
  styleSuggestionOxford: {
    id: `${scope}.styleSuggestionOxford`,
    defaultMessage: 'Oxford',
  },
  styleSuggestionTurabianAuthorDate: {
    id: `${scope}.styleSuggestionTurabianAuthorDate`,
    defaultMessage: 'Turabian (Author-Date)',
  },
  styleSuggestionTurabianFootnotes: {
    id: `${scope}.styleSuggestionTurabianFootnotes`,
    defaultMessage: 'Turabian (Footnotes)',
  },
  styleSuggestionVancouver: {
    id: `${scope}.styleSuggestionVancouver`,
    defaultMessage: 'Vancouver',
  },
  keywordsLabel: {
    id: `${scope}.keywordsLabel`,
    defaultMessage: 'Keywords',
  },
  keywordsPlaceholder: {
    id: `${scope}.keywordsPlaceholder`,
    defaultMessage: 'Add Keywords that describe your document',
  },
  notesLabel: {
    id: `${scope}.notesLabel`,
    defaultMessage: 'Notes',
  },
  notesPlaceholder: {
    id: `${scope}.notesPlaceholder`,
    defaultMessage: 'Add any additional description for your document',
  },
  servicesLabel: {
    id: `${scope}.servicesLabel`,
    defaultMessage: 'Select service',
  },
  basicServicePriceLabel: {
    id: `${scope}.basicServicePriceLabel`,
    defaultMessage: 'Price from',
  },
  basicServicePriceUnit: {
    id: `${scope}.basicServicePriceUnit`,
    defaultMessage: 'per word',
  },
  serviceCommingSoon: {
    id: `${scope}.serviceCommingSoon`,
    defaultMessage: 'Comming soon',
  },
  serviceGetInTouch: {
    id: `${scope}.serviceGetInTouch`,
    defaultMessage: 'Get in touch',
  },
  serviceProofreading: {
    id: `${scope}.serviceProofreading`,
    defaultMessage: 'Proofreading',
  },
  serviceProofreadingDescription: {
    id: `${scope}.serviceProofreadingDescription`,
    defaultMessage: 'Use preferred editors for - possibly slower delivery.',
  },
  serviceTranslation: {
    id: `${scope}.serviceTranslation`,
    defaultMessage: 'Translation',
  },
  serviceTranslationDescription: {
    id: `${scope}.serviceTranslationDescription`,
    defaultMessage: 'Use preferred editors for - possibly slower delivery.',
  },
  additionalServicesLabel: {
    id: `${scope}.additionalServicesLabel`,
    defaultMessage: 'Additional services',
  },
  priceFrom: {
    id: `${scope}.priceFrom`,
    defaultMessage: 'Price from {price} {currency}',
  },
  serviceEditing: {
    id: `${scope}.serviceEditing`,
    defaultMessage: 'Editing Service',
  },
  serviceEditingDescription: {
    id: `${scope}.serviceEditingDescription`,
    defaultMessage:
      'Our professional plagiarism report will help you to ensure your document is unique and fully referenced. We will compare your work against 56 billion web pages, 98 million academic articles, and 44 million books, producing a report that shows any non-unique content and its source. If you would like a plagiarism report without proofreading, please <a>email us</a>.',
  },
  serviceFormatting: {
    id: `${scope}.serviceFormatting`,
    defaultMessage: 'Formatting Service',
  },
  serviceFormattingDescription: {
    id: `${scope}.serviceFormattingDescription`,
    defaultMessage:
      'Our formatting service makes your document look great based on your specifications. Among other things, we can create a cover page, add a content page or list of figures, label charts, tables and images, number your pages, and format headers, footers, titles, subtitles and other text. If you would like your document formatted without proofreading, please <a>email us</a>',
  },
  delivery: {
    id: `${scope}.delivery`,
    defaultMessage: ' Delivery',
  },
  servicePlagiarism: {
    id: `${scope}.servicePlagiarism`,
    defaultMessage: 'Plagiarism Report',
  },
  servicePlagiarismDescription: {
    id: `${scope}.servicePlagiarismDescription`,
    defaultMessage: 'None',
  },
  translationAdditionalServices: {
    id: `${scope}.translationAdditionalServices`,
    defaultMessage: 'Add addtional services to your document translations.',
  },
  deliveryLabel: {
    id: `${scope}.deliveryLabel`,
    defaultMessage: 'Delivery',
  },
  deliveryDescription: {
    id: `${scope}.deliveryDescription`,
    defaultMessage: 'How fast do you need work returned?',
  },
  deliveryRegularLabel: {
    id: `${scope}.deliveryRegularLabel`,
    defaultMessage: 'Regular',
  },
  deliveryExpressLabel: {
    id: `${scope}.deliveryExpressLabel`,
    defaultMessage: 'Express',
  },
  deliveryRapidLabel: {
    id: `${scope}.deliveryRapidLabel`,
    defaultMessage: 'Rapid',
  },
  removeDocument: {
    id: `${scope}.removeDocument`,
    defaultMessage: 'Remove document',
  },
  proceedToPayment: {
    id: `${scope}.proceedToPayment`,
    defaultMessage: 'Confirm & Pay',
  },
  confirmOrder: {
    id: `${scope}.confirmOrder`,
    defaultMessage: 'Confirm',
  },
  yourPrice: {
    id: `${scope}.yourPrice`,
    defaultMessage: 'Your price',
  },
  estimatedDeliveryLabel: {
    id: `${scope}.estimatedDeliveryLabel`,
    defaultMessage: 'Estimated delivery',
  },
  deliveryDateTooltipText: {
    id: `${scope}.deliveryDateTooltipText`,
    defaultMessage: 'Delivery date',
  },
  paymentDueLabel: {
    id: `${scope}.paymentDueLabel`,
    defaultMessage: 'Payment due',
  },
  paymentDueTooltipText: {
    id: `${scope}.paymentDueTooltipText`,
    defaultMessage:
      'You document is under our minimum charge of {words} words. Please continue with order as we are still able to deliver service',
  },
  paymentPriceTaxInfoLabel: {
    id: `${scope}.paymentPriceTaxInfoLabel`,
    defaultMessage: 'VAT 21% included',
  },
  payByInvoiceLine1: {
    id: `${scope}.payByInvoiceLine1`,
    defaultMessage: 'Pay by invoice?',
  },
  payByInvoiceLine2: {
    id: `${scope}.payByInvoiceLine2`,
    defaultMessage:
      'Please <aBookACall>book a call</aBookACall> or <aEmail>Email</aEmail> our support as we will be able to help.',
  },
  viewFullQuote: {
    id: `${scope}.viewFullQuote`,
    defaultMessage: 'View Full Quote',
  },
  stripeCardPaymentInfo: {
    id: `${scope}.stripeCardPaymentInfo`,
    defaultMessage: 'All card payments are securely processed via Stripe.',
  },
  agreementCopy: {
    id: `${scope}.agreementCopy`,
    defaultMessage:
      'By clicking "Confirm & Pay", you agree to Kontrolujeme\'s <aTerms>Terms & Conditions</aTerms> and <aQuality>Quality Policy</aQuality>.',
  },
  translationEnglishToChech: {
    id: `${scope}.translationEnglishToChech`,
    defaultMessage: 'English to Czech',
  },
  translationChechToEnglish: {
    id: `${scope}.translationChechToEnglish`,
    defaultMessage: 'Czech to English',
  },
  formInvalidMessage: {
    id: `${scope}.formInvalidMessage`,
    defaultMessage: 'Please fill in the required information in the form!',
  },
});
