import React, { useState, useMemo, createContext, useCallback } from 'react';
import { IntlProvider as IntlReactProvider } from 'react-intl';
import en from '@app/translations/en.json';
import pl from '@app/translations/pl.json';
import cs from '@app/translations/cs.json';
import {
  DEFAULT_LOCALE,
  Locale,
  resolveLocale,
  storeLocale,
} from '@app/utils/i18n';

export type IntlProviderProps = {
  children: React.ReactNode;
};

export type LocaleState = {
  locale: Locale;
  setLocale: (locale: Locale) => void;
};

const translations = {
  en,
  pl,
  cs,
};

export const LocaleContext = createContext<LocaleState>({
  locale: DEFAULT_LOCALE,
  setLocale: () => {
    throw 'NotImplemented';
  },
});

const IntlProvider = ({ children }: IntlProviderProps): JSX.Element => {
  const [locale, _setLocale] = useState<Locale>(resolveLocale);
  const messages = useMemo(() => translations[locale], [locale]);

  const setLocale = useCallback(
    (locale: Locale) => {
      storeLocale(locale);
      _setLocale(locale);
    },
    [_setLocale],
  );

  return (
    <LocaleContext.Provider value={{ locale, setLocale }}>
      <IntlReactProvider locale={locale} messages={messages}>
        {children}
      </IntlReactProvider>
    </LocaleContext.Provider>
  );
};

export default IntlProvider;
