import { defineMessages } from 'react-intl';

export const scope = 'pages.AccountSettingsPage';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Account Settings',
  },
  subTitle: {
    id: `${scope}.subTitle`,
    defaultMessage:
      'If you need to amend information that is not editable or unsubscribe from transactional emails, please contact us.',
  },
  personalInformationHeading: {
    id: `${scope}.personalInformationHeading`,
    defaultMessage: 'Personal Information',
  },
  passwordHeading: {
    id: `${scope}.passwordHeading`,
    defaultMessage: 'Password',
  },
  passwordSubHeading: {
    id: `${scope}.passwordSubHeading`,
    defaultMessage:
      'If you wish to change your password, please request the change and we will send you an email with the link where you can reset you current one.',
  },
  userTypeHeading: {
    id: `${scope}.userTypeHeading`,
    defaultMessage: 'User type',
  },
  marketingNotificationsHeading: {
    id: `${scope}.marketingNotificationsHeading`,
    defaultMessage: 'Marketing notifications',
  },
  legalHeading: {
    id: `${scope}.legalHeading`,
    defaultMessage: 'Legal',
  },
  firstNameLabel: {
    id: `${scope}.firstNameLabel`,
    defaultMessage: 'First name',
  },
  firstNamePlaceholder: {
    id: `${scope}.firstNamePlaceholder`,
    defaultMessage: 'First name',
  },
  firstNameRequired: {
    id: `${scope}.firstNameRequired`,
    defaultMessage: 'Please enter your first name',
  },
  lastNameLabel: {
    id: `${scope}.lastNameLabel`,
    defaultMessage: 'Last name',
  },
  lastNamePlaceholder: {
    id: `${scope}.lastNamePlaceholder`,
    defaultMessage: 'Last name',
  },
  lastNameRequired: {
    id: `${scope}.lastNameRequired`,
    defaultMessage: 'Please enter your last name',
  },
  emailLabel: {
    id: `${scope}.emailLabel`,
    defaultMessage: 'E-mail address',
  },
  verified: {
    id: `${scope}.verified`,
    defaultMessage: 'Verified',
  },
  notVerified: {
    id: `${scope}.notVerified`,
    defaultMessage: 'Not verified',
  },
  phoneLabel: {
    id: `${scope}.phoneLabel`,
    defaultMessage: 'Phone Number',
  },
  phonePlaceholder: {
    id: `${scope}.phonePlaceholder`,
    defaultMessage: 'Phone Number',
  },
  phoneRequired: {
    id: `${scope}.phoneRequired`,
    defaultMessage: 'Please enter your phone number',
  },
  timezoneLabel: {
    id: `${scope}.timezoneLabel`,
    defaultMessage: 'Timezone',
  },
  timezonePlaceholder: {
    id: `${scope}.timezonePlaceholder`,
    defaultMessage: 'Timezone',
  },
  userTypeLabel: {
    id: `${scope}.userTypeLabel`,
    defaultMessage: 'User type',
  },
  userTypePlaceholder: {
    id: `${scope}.userTypePlaceholder`,
    defaultMessage: 'User type',
  },
  userTypeRequired: {
    id: `${scope}.userTypeRequired`,
    defaultMessage: 'Please enter user type',
  },
  changePasswordButton: {
    id: `${scope}.changePasswordButton`,
    defaultMessage: 'Request password change',
  },
  changePasswordSuccessMessage: {
    id: `${scope}.changePasswordSuccessMessage`,
    defaultMessage:
      'You will receive an email message with link to change your password.',
  },
  changePasswordErrorMessage: {
    id: `${scope}.changePasswordErrorMessage`,
    defaultMessage: 'Something went wrong while changing your password.',
  },
  isBusinessLabel: {
    id: `${scope}.isBusinessLabel`,
    defaultMessage: 'Are you business customer?',
  },
  companyNameLabel: {
    id: `${scope}.companyNameLabel`,
    defaultMessage: 'Company name',
  },
  companyNamePlaceholder: {
    id: `${scope}.companyNamePlaceholder`,
    defaultMessage: 'Company name',
  },
  companyNameRequired: {
    id: `${scope}.companyNameRequired`,
    defaultMessage: 'Please enter your Company name',
  },
  vatNumberLabel: {
    id: `${scope}.vatNumberLabel`,
    defaultMessage: 'VAT number',
  },
  vatNumberPlaceholder: {
    id: `${scope}.vatNumberPlaceholder`,
    defaultMessage: 'VAT number',
  },
  vatNumberRequired: {
    id: `${scope}.vatNumberRequired`,
    defaultMessage: 'Please enter your VAT number',
  },
  streetAddressLabel: {
    id: `${scope}.streetAddressLabel`,
    defaultMessage: 'Street',
  },
  streetAddressPlaceholder: {
    id: `${scope}.streetAddressPlaceholder`,
    defaultMessage: 'Street',
  },
  cityLabel: {
    id: `${scope}.cityLabel`,
    defaultMessage: 'City',
  },
  cityPlaceholder: {
    id: `${scope}.cityPlaceholder`,
    defaultMessage: 'City',
  },
  postcodeLabel: {
    id: `${scope}.postcodeLabel`,
    defaultMessage: 'Postcode',
  },
  postcodePlaceholder: {
    id: `${scope}.postcodePlaceholder`,
    defaultMessage: 'Postcode',
  },
  countryLabel: {
    id: `${scope}.countryLabel`,
    defaultMessage: 'Country',
  },
  countryPlaceholder: {
    id: `${scope}.countryPlaceholder`,
    defaultMessage: 'Country',
  },
  marketingAgreeLabel: {
    id: `${scope}.marketingAgreeLabel`,
    defaultMessage: 'Agree to receive marketing emails',
  },
  termsAndPrivacyAgreeLabel: {
    id: `${scope}.termsAndPrivacyAgreeLabel`,
    defaultMessage: 'You agreed to process your personal information',
  },
  termsAndPrivacyAgreeDescription: {
    id: `${scope}.termsAndPrivacyAgreeDescription`,
    defaultMessage:
      'If you wish to revoke or have any concerns, please <a>get in touch</a> with our support team.',
  },
  doYouHaveAnyQuestions: {
    id: `${scope}.doYouHaveAnyQuestions`,
    defaultMessage: 'Do you have any questions?',
  },
  contactSupport: {
    id: `${scope}.contactSupport`,
    defaultMessage: '<a>Contact</a> our support team.',
  },
  closeButton: {
    id: `${scope}.closeButton`,
    defaultMessage: 'Close',
  },
  submitButton: {
    id: `${scope}.submitButton`,
    defaultMessage: 'Save',
  },
  successMessage: {
    id: `${scope}.successMessage`,
    defaultMessage: 'All changes been succesfuly saved',
  },
  errorMessage: {
    id: `${scope}.errorMessage`,
    defaultMessage: 'Something went wrong while saving your account details',
  },
  termsAndConditions: {
    id: `${scope}.termsAndConditions`,
    defaultMessage: 'Terms and Conditions',
  },
  privacyPolicy: {
    id: `${scope}.privacyPolicy`,
    defaultMessage: 'Privacy Policy',
  },
  formDirtyMessage: {
    id: `${scope}.formDirtyMessage`,
    defaultMessage:
      'You made some changes changes. Before Save to apply your changes.',
  },
  formInvalidMessage: {
    id: `${scope}.formInvalidMessage`,
    defaultMessage:
      'There are some errors in fileds. Please amend and save again.',
  },
});
