import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '@app/assets/logo.svg';
import { ReactComponent as LogoSmall } from '@app/assets/logo-small.svg';
import useAuth from '@app/hooks/useAuth';
import { RouterUrl } from '@app/navigation/consts';
import React from 'react';
import Dropdown from './Dropdown';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import classNames from 'classnames';

const Header: React.FC = () => {
  const { user } = useAuth();

  return (
    <header
      className={classNames('l-app-header', {
        'l-app-header--authorized': !!user,
      })}
    >
      <div className="l-inner">
        <div className="g-row">
          <div className="g-col-12 l-app-header__inner">
            <div className="l-app-header__logo">
              <Link to={RouterUrl.Home} className="c-app-header-logo">
                <span className="c-app-header-logo__label">
                  <FormattedMessage {...messages.logoLabel} />
                </span>
                {user ? <LogoSmall /> : <Logo />}
              </Link>
            </div>

            <div className="l-app-header__upload-action">
              <Link
                to={RouterUrl.AddDocument}
                className="c-button c-button--primary "
              >
                <span className="c-button__label">
                  <FormattedMessage {...messages.upload} />
                </span>
              </Link>
            </div>

            {user == null ? (
              <div className="l-app-header__log-in">
                <Link
                  to={RouterUrl.SignIn}
                  className="c-button c-button--primary"
                >
                  <span className="c-button__label">
                    <FormattedMessage {...messages.signIn} />
                  </span>
                </Link>
              </div>
            ) : (
              <Dropdown />
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
