import { defineMessages } from 'react-intl';

export const scope = 'pages.SignUpConfirmPage';

export default defineMessages({
  welcomeMessage: {
    id: `${scope}.welcomeMessage`,
    defaultMessage: 'Welcome {user} to Kontroluje.me',
  },
  messageLine1: {
    id: `${scope}.messageLine1`,
    defaultMessage: 'We can’t wait to help you perfect your writing.',
  },
  messageLine2: {
    id: `${scope}.messageLine2`,
    defaultMessage:
      'If you haven’t uploaded a document already, get started by clicking the link below.',
  },
  uploadDocument: {
    id: `${scope}.uploadDocument`,
    defaultMessage: 'Upload Your First Document',
  },
});
