import UserAvatar from '@app/components/UserAvatar';
import { DocumentComment as DocumentCommentType } from '@app/types/DocumentComment';
import { formatRelativeDateTime } from '@app/utils/intl';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';

export interface DocumentCommentProps {
  comment: DocumentCommentType;
}

const DocumentComment: React.FC<DocumentCommentProps> = ({ comment }) => {
  const intl = useIntl();

  return (
    <div className="document-comment">
      <div className="document-comment__meta">
        <UserAvatar
          user={comment.author}
          className="document-comment__avatar"
        />

        <div className="document-comment__info">
          <span className="document-comment__author">
            {comment.author?.first_name} {comment.author?.last_name}
          </span>

          <span className="document-comment__time">
            <FormattedMessage
              {...messages.commentedOn}
              values={{
                date: formatRelativeDateTime(intl, comment.created_at),
              }}
            />
          </span>
        </div>
      </div>

      <span className="document-comment__content">{comment.message}</span>
    </div>
  );
};

export default DocumentComment;
