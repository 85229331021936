import classNames from 'classnames';
import React from 'react';
import { ReactComponent as Loading } from '@app/assets/loading.svg';
import { ReactComponent as CheckFile } from '@app/assets/check-file.svg';
import { ReactComponent as IconFileDocx } from '@app/assets/icon-file-docx.svg';
import { FileRejection, useDropzone } from 'react-dropzone';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

export type DropzoneProps = {
  onDropAccepted?: (files: File[]) => void;
  onDropRejected?: (fileRejections: FileRejection[]) => void;
  accept?: string | string[];
  multiple?: boolean;
  maxFiles?: number;
  maxSize?: number;
  loading?: boolean;
  disabled?: boolean;
};

const Dropzone: React.FC<DropzoneProps> = ({
  accept,
  multiple,
  maxFiles,
  maxSize,
  loading,
  disabled,
  onDropAccepted,
  onDropRejected,
}) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept,
    disabled,
    multiple,
    maxFiles,
    maxSize,
    onDropRejected,
    onDropAccepted,
    preventDropOnDocument: true,
  });

  return (
    <div className="l-inner-whitebox-upload-a-content ui-bg--purple-05">
      <div className="g-gutter-top-4 g-gutter-bottom-4">
        <div className="c-new-file-order">
          <div className="c-new-file-order__dropdown">
            <div
              {...getRootProps({
                className: classNames(
                  'c-app-upload-box ui-form ui-form__field ui-form__field--file is-large',
                  'dz-max-files-reached',
                  {
                    'dz-started is-sending': loading,
                  },
                  { 'dz-drag-hover': isDragActive },
                ),
              })}
            >
              <input {...getInputProps()} />
              <div className="c-app-upload-box__inner">
                <div className="c-app-upload-box__main">
                  <div className="c-app-upload-box__icon">
                    <IconFileDocx />
                    <div className="c-app-upload-box__desktop-content">
                      <p className="c-app-upload-box__desktop-heading">
                        <FormattedMessage {...messages.fileUploadMessage} />
                        <br />
                        <span className="ui-color--navy-04">
                          <FormattedMessage
                            {...messages.orBrowseComputer}
                            values={{
                              u: function u(chunks: React.ReactNode) {
                                return (
                                  <span className="t-underline">{chunks}</span>
                                );
                              },
                            }}
                          />
                        </span>
                      </p>
                    </div>
                  </div>
                  <p className="c-app-upload-box__title ui-hide-desktop">
                    <FormattedMessage {...messages.borwseDevice} />
                  </p>

                  <p className="t-small ui-color--navy-03 t-lineheight-15 g-gutter-top-3">
                    <FormattedMessage {...messages.supportedFilesLabel} />
                  </p>
                  <p className="t-small t-bold t-upper t-lineheight-15 ui-color--blueberry-simpan-in-01">
                    <FormattedMessage {...messages.supportedFiles} />
                  </p>
                </div>

                <div className="c-app-upload-box__main-on-drag">
                  <div className="c-app-upload-box__icon">
                    <CheckFile />
                    <div className="c-app-upload-box__desktop-content">
                      <p className="c-app-upload-box__desktop-heading">
                        <FormattedMessage {...messages.dropFileHere} />
                      </p>
                    </div>
                  </div>
                </div>

                <div className="c-app-upload-box__main-loading">
                  <div className="c-app-upload-box__icon">
                    <Loading />
                    <div className="c-app-upload-box__desktop-content">
                      <p className="c-app-upload-box__desktop-heading">
                        <FormattedMessage {...messages.uploadingFile} />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="c-new-file-order__aside-info
        g-gutter-top-3-desktop
        g-gutter-top-2-tablet
        g-gutter-top-2-mobile
        g-gutter-bottom-3-desktop"
          >
            <h2 className="t-body-1">
              <FormattedMessage {...messages.otherFilesLabel} />
            </h2>
            <p className="t-small g-gutter-top-1 t-bold t-upper ui-color--blueberry-simpan-in-01">
              <FormattedMessage {...messages.otherFiles} />
            </p>
            <p className="t-body-3 g-gutter-top-1">
              <FormattedMessage
                {...messages.otherFilesDescription}
                values={{
                  light: function light(chunks: React.ReactNode) {
                    return <span className="ui-color--navy-03">{chunks}</span>;
                  },
                }}
              />
            </p>
            <div className="c-new-file-order__aside-info-action g-gutter-top-2">
              <a
                href="mailto:support@kontroluje.me"
                className="c-button c-button--navy-light c-button--small"
              >
                <span className="c-button__label">
                  <FormattedMessage {...messages.sendUsEmailButton} />
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dropzone;
