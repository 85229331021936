import { defineMessages } from 'react-intl';

export const scope = 'service';

export default defineMessages({
  proofreading: {
    id: `${scope}.proofreading`,
    defaultMessage: 'Proofreading',
  },
  translation: {
    id: `${scope}.translation`,
    defaultMessage: 'Translation',
  },
  formatting: {
    id: `${scope}.formatting`,
    defaultMessage: 'Formatting',
  },
  editing: {
    id: `${scope}.editing`,
    defaultMessage: 'Editing',
  },
  plagiarism: {
    id: `${scope}.plagiarism`,
    defaultMessage: 'Plagiarism',
  },
});
