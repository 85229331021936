import * as Yup from 'yup';
import messages from './messages';

export const sectionsLinks = {
  personalInformation: 'personal-information',
  password: 'password',
  userType: 'user-type',
  marketingNotifications: 'marketing-notifications',
  legal: 'legal',
};

export const sections = [
  {
    message: messages.personalInformationHeading,
    href: sectionsLinks.personalInformation,
  },
  {
    message: messages.passwordHeading,
    href: sectionsLinks.password,
  },
  {
    message: messages.userTypeHeading,
    href: sectionsLinks.userType,
  },
  {
    message: messages.marketingNotificationsHeading,
    href: sectionsLinks.marketingNotifications,
  },
  {
    message: messages.legalHeading,
    href: sectionsLinks.legal,
  },
];

export const UserAccountSchema = Yup.object().shape({
  first_name: Yup.string().required(messages.firstNameRequired.id),
  last_name: Yup.string().required(messages.lastNameRequired.id),
  phone: Yup.string().required(messages.phoneRequired.id),
  timezone: Yup.string(),
  is_business: Yup.boolean(),
  company_name: Yup.string().when('is_business', {
    is: true,
    then: Yup.string().required(messages.companyNameRequired.id),
  }),
  vat_number: Yup.string().when('is_business', {
    is: true,
    then: Yup.string().required(messages.vatNumberRequired.id),
  }),
  type: Yup.string().nullable().required(messages.userTypeRequired.id),
  street_address: Yup.string(),
  city: Yup.string(),
  postcode: Yup.string(),
  country: Yup.string(),
});
