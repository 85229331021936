import React, { useCallback, useState } from 'react';
import { useMutation } from 'react-query';
import { FormattedMessage, useIntl } from 'react-intl';
import { api } from '@app/utils/api';
import messages from './messages';
import { Helmet } from 'react-helmet-async';
import { ApiUrl } from '@app/consts';
import { generatePath, useHistory } from 'react-router-dom';
import { RouterUrl } from '@app/navigation/consts';
import { Order } from '@app/types/Order';
import { AWSS3PresignedPost } from '@app/types/AWSS3PresignedPost';
import Dropzone from './Dropzone';
import UnsupportedFileModal from './UnsupportedFileModal';
import { FileRejection } from 'react-dropzone';
import axios from 'axios';
import { splitPath } from '@app/utils/path';

const AddDocumentPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const [rejectedFiles, setRejectedFiles] = useState<string[]>([]);

  const uploadMutation = useMutation(
    async (files: File[]) => {
      const order_documents = [];
      for (const file of files) {
        const {
          data: { url, file_url, fields },
        } = await api.post<AWSS3PresignedPost>(ApiUrl.DocumentSignS3, {
          file_name: file.name,
          file_type: file.type,
        });
        const [, file_name, file_extension] = splitPath(file.name);
        order_documents.push({
          document: { file_url, file_name, file_extension },
        });
        const formData = new FormData();
        Object.entries(fields).forEach(([name, value]) =>
          formData.append(name, value),
        );
        formData.append('file', file);
        await axios.post(url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      }
      const order = await api.post<Order>(ApiUrl.Orders, { order_documents });
      return order;
    },
    {
      onSuccess: ({ data: { token } }) =>
        history.push(
          generatePath(RouterUrl.OrderSubmit, { orderToken: token }),
        ),
      onError: (_, files) => setRejectedFiles(files.map(({ name }) => name)),
    },
  );

  const handleDropAccepted = useCallback(
    (files: File[]) => uploadMutation.mutate(files),
    [uploadMutation],
  );

  const handleDropRejected = useCallback(
    (files: FileRejection[]) =>
      setRejectedFiles(files.map(({ file: { name } }) => name)),
    [],
  );

  const handleUnsupportedModalClose = useCallback(
    () => setRejectedFiles([]),
    [],
  );

  return (
    <main className="l-main">
      <Helmet>
        <title>{formatMessage(messages.title)}</title>
      </Helmet>
      <div
        className="l-sec
        g-gutter-top-2-mobile
        g-gutter-top-2-tablet
        g-gutter-top-4-desktop
        g-gutter-bottom-2-mobile
        g-gutter-bottom-4-tablet
        g-gutter-bottom-2-desktop"
      >
        <div className="l-inner">
          <div className="g-row">
            <div
              className="g-col-12
              g-col-tablet-sm-10
              g-col-tablet-8
              g-col-desktop-8
              g-col-offset-tablet-sm-1
              g-col-offset-tablet-2
              g-col-offset-desktop-2"
            >
              <div
                className="c-sticky-box-start js-sticky-box-start"
                data-sticky-name="right-column-price"
              ></div>
              <h1 className="t-headline-2">
                <FormattedMessage {...messages.title} />
              </h1>
              <div className="g-gutter-top-2">
                <Dropzone
                  accept={['.doc', '.docx', 'application/pdf']}
                  multiple={false}
                  maxFiles={1}
                  maxSize={20 * 1024 * 1024}
                  onDropAccepted={handleDropAccepted}
                  onDropRejected={handleDropRejected}
                  loading={uploadMutation.isLoading}
                  disabled={
                    uploadMutation.isLoading ||
                    uploadMutation.data?.data != null
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <UnsupportedFileModal
        show={rejectedFiles.length > 0}
        onHide={handleUnsupportedModalClose}
        fileNames={rejectedFiles}
      />
    </main>
  );
};

export default AddDocumentPage;
