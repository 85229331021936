export const LOCALE_KEY = 'locale';

export type Locale = 'en' | 'pl' | 'cs';

export const LOCALES: Locale[] = ['en', 'pl', 'cs'];

export const DEFAULT_LOCALE =
  (process.env.REACT_APP_DEFAULT_LOCALE as Locale) || 'cs';

export const FORCE_DEFAULT_LOCALE =
  process.env.REACT_APP_FORCE_DEFAULT_LOCALE?.toLowerCase() !== 'false';

export const isValidLocale = (locale: string): locale is Locale =>
  LOCALES.find((_locale) => _locale === locale) !== undefined;

export const storeLocale = (locale: Locale): void =>
  localStorage.setItem(LOCALE_KEY, locale);

export const resolveLocale = (): Locale => {
  if (FORCE_DEFAULT_LOCALE) {
    return DEFAULT_LOCALE;
  }

  const locale = (
    localStorage.getItem(LOCALE_KEY) ||
    navigator.languages?.[0] ||
    navigator.language
  )
    ?.toLowerCase()
    ?.substring(0, 2);

  if (isValidLocale(locale)) {
    return locale;
  }

  return DEFAULT_LOCALE;
};
