export type APIError<T> = {
  [P in keyof T]?: string[] | APIError<T[P]>;
} & {
  non_field_errors?: string[];
};

export const parseAPIErrors = <T = unknown>(error: APIError<T>): string[] => {
  const errors: string[] = [];
  if (error instanceof Array) {
    error.forEach((e) => {
      errors.push(...parseAPIErrors(e));
    });
  } else if (typeof error === 'object' && error != null) {
    errors.push(...parseAPIErrors<unknown>(Object.values(error)));
  } else if (typeof error === 'string') {
    errors.push(error);
  } else if (error != null) {
    errors.push(`${error}`);
  }
  return errors;
};

export const parseAPIError = <T = unknown>(error: APIError<T>): string =>
  parseAPIErrors(error).join(', ');
