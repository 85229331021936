import { TokenResponse } from '@app/types/TokenResponse';
import axios from 'axios';
import { resolveLocale } from './i18n';
import { getToken, storeToken } from './tokenStorage';

export const api = axios.create({ baseURL: process.env.REACT_APP_API_URL });
export const authApi = axios.create({ baseURL: process.env.REACT_APP_API_URL });

let tokenRefreshing: Promise<void> | null = null;

const refreshToken = (token: string): Promise<void> => {
  if (tokenRefreshing === null) {
    tokenRefreshing = authApi
      .post<TokenResponse>('/auth/token/', {
        token,
      })
      .catch((error) => {
        storeToken(null);
        return Promise.reject(error);
      })
      .then(({ data }) => {
        storeToken(data.token);
        tokenRefreshing = null;
      });
  }
  return tokenRefreshing;
};

authApi.interceptors.request.use((config) => {
  const locale = resolveLocale();
  config.headers['Accept-Language'] = locale;
  return config;
});

api.interceptors.request.use((config) => {
  const token = getToken();
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  const locale = resolveLocale();
  config.headers['Accept-Language'] = locale;
  return config;
});

api.interceptors.response.use(undefined, async (error) => {
  if (error?.response?.status === 401) {
    const token = getToken();
    if (token) {
      await refreshToken(token);
      return api(error.config);
    }
  }
  return Promise.reject(error);
});
