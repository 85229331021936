import useAuth from '@app/hooks/useAuth';
import { RouterUrl } from '@app/navigation/consts';
import React, { useCallback, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { generatePath, Link, useHistory } from 'react-router-dom';
import { ReactComponent as CloseCircleIcon } from '@app/assets/icon-close-circle-btn.svg';
import UserAvatar from '../UserAvatar';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import useOnClickOutside from '@app/hooks/useOnClickOutside';
import { ExternalLink } from '@app/consts';

const Dropdown: React.FC = () => {
  const menuRef = useRef<HTMLDivElement>(null);
  const toggleRef = useRef<HTMLDivElement>(null);
  const [show, setShow] = useState(false);
  const { user, setAuth } = useAuth();
  const history = useHistory();

  const signOut = useCallback(() => {
    setAuth({});
    history.push(RouterUrl.SignIn);
  }, [history, setAuth]);

  const close = useCallback(() => setShow(false), []);
  const toggle = useCallback(() => setShow((show) => !show), []);

  const handleClickOutside = useCallback(
    (event: MouseEvent | TouchEvent) => {
      if (
        event.target instanceof HTMLElement &&
        !toggleRef?.current?.contains(event.target)
      ) {
        close();
      }
    },
    [close],
  );

  useOnClickOutside(show ? menuRef : undefined, handleClickOutside);

  return (
    <>
      <div
        ref={toggleRef}
        className="l-app-header__user-initials"
        onClick={toggle}
      >
        <UserAvatar user={user} />
      </div>
      <div ref={menuRef} className="l-app-header__menu">
        <Helmet>
          <body {...(show ? { 'data-has-open-user-header-menu': true } : {})} />
        </Helmet>
        <div className="l-app-header__close" onClick={close}>
          <CloseCircleIcon />
        </div>

        <nav className="l-app-header__user-info">
          <UserAvatar user={user} />

          <div className="l-app-header__user-content">
            <div className="t-text-2">
              {user?.first_name} {user?.last_name}
            </div>
            <div className="t-text-2 ui-color--navy-03">{user?.email}</div>
          </div>
        </nav>

        <nav className="l-app-header__navigation">
          <div className="c-app-header-nav">
            <ul className="c-app-header-nav__list">
              <li className="c-app-header-nav__item">
                <Link
                  to={generatePath(RouterUrl.Documents, {
                    documentId: undefined,
                  })}
                  onClick={close}
                  className="c-app-header-nav__link"
                >
                  <div className="c-app-header-nav__text">
                    <span>
                      <FormattedMessage {...messages.documents} />
                    </span>
                  </div>
                </Link>
              </li>
              <li className="c-app-header-nav__item">
                <Link
                  to={generatePath(RouterUrl.AccountSettings)}
                  onClick={close}
                  className="c-app-header-nav__link"
                >
                  <div className="c-app-header-nav__text">
                    <span>
                      <FormattedMessage {...messages.accountSettings} />
                    </span>
                  </div>
                </Link>
              </li>
              <li className="c-app-header-nav__item">
                <a className="c-app-header-nav__link" onClick={signOut}>
                  <div className="c-app-header-nav__text">
                    <span>
                      <FormattedMessage {...messages.logOut} />
                    </span>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </nav>

        <div className="l-app-header__bottom">
          <a
            className="ui-link-basic"
            href={ExternalLink.PrivacyPolicy}
            target="_blank"
            rel="noreferrer"
          >
            <FormattedMessage {...messages.privacyPolicy} />
          </a>
          <br />
          <a
            className="ui-link-basic"
            href={ExternalLink.TermsAndConditions}
            target="_blank"
            rel="noreferrer"
          >
            <FormattedMessage {...messages.termsOfService} />
          </a>
        </div>
      </div>
    </>
  );
};

export default Dropdown;
