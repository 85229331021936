import { Switch, Route, Redirect, generatePath } from 'react-router-dom';
import { RouterUrl } from './consts';
import AddDocumentPage from '@app/pages/AddDocumentPage';
import AccountSettingsPage from '@app/pages/AccountSettingsPage';
import SignInPage from '@app/pages/SignInPage';
import SignUpConfirmPage from '@app/pages/SignUpConfirmPage';
import SignUpPage from '@app/pages/SignUpPage';
import OrderSubmitPage from '@app/pages/OrderSubmitPage';
import OrderConfirmPage from '@app/pages/OrderConfirmPage';
import PaymentDeclinedPage from '@app/pages/PaymentDeclinedPage';
import DocumentsPage from '@app/pages/DocumentsPage';
import ForgotPasswordPage from '@app/pages/ForgotPasswordPage';
import ResetPasswordPage from '@app/pages/ResetPasswordPage';
import useAuth from '@app/hooks/useAuth';
import PrivateRoute from './PrivateRoute';

const Routes = (): JSX.Element => {
  const { user, initialised } = useAuth();

  return (
    <Switch>
      <Route path={RouterUrl.SignUpConfirm} component={SignUpConfirmPage} />
      <Route path={RouterUrl.AddDocument} component={AddDocumentPage} />
      <Route path={RouterUrl.OrderSubmit} component={OrderSubmitPage} />
      <Route path={RouterUrl.OrderConfirm} component={OrderConfirmPage} />
      <Route path={RouterUrl.PaymentDeclined} component={PaymentDeclinedPage} />
      <Route path={RouterUrl.ResetPassword} component={ResetPasswordPage} />
      <PrivateRoute
        path={RouterUrl.AccountSettings}
        component={AccountSettingsPage}
      />
      <PrivateRoute path={RouterUrl.Documents} component={DocumentsPage} />
      {!user && <Route path={RouterUrl.SignUp} component={SignUpPage} />}
      {!user && <Route path={RouterUrl.SignIn} component={SignInPage} />}
      {!user && (
        <Route path={RouterUrl.ForgotPassword} component={ForgotPasswordPage} />
      )}
      {initialised && (
        <Route path="*">
          <Redirect
            to={
              user
                ? generatePath(RouterUrl.Documents, { documentId: undefined })
                : RouterUrl.SignIn
            }
          />
        </Route>
      )}
    </Switch>
  );
};

export default Routes;
