import { ReactComponent as InfoIcon } from '@app/assets/info.svg';
import useOnClickOutside from '@app/hooks/useOnClickOutside';
import classNames from 'classnames';
import { useCallback, useRef, useState } from 'react';

const InfoTooltip: React.FC = ({ children }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [show, setShow] = useState(false);
  const toggle = useCallback(() => setShow((show) => !show), []);
  const close = useCallback(() => setShow(false), []);

  useOnClickOutside(show ? containerRef : undefined, close);

  return (
    <div
      ref={containerRef}
      className={classNames('c-info-tooltip__info', {
        'has-opened-tooltip': show,
      })}
    >
      <figure className="c-info-tooltip__info-trigger" onClick={toggle}>
        <InfoIcon />
      </figure>
      <div className="c-info-tooltip__info-content">
        <div className="c-info-tooltip__info-content__inner">{children}</div>
      </div>
    </div>
  );
};

export default InfoTooltip;
