export type FormLabelProps = {
  htmlFor?: string;
};

const FormLabel: React.FC<FormLabelProps> = ({ children, ...props }) => (
  <label {...props} className="ui-form__label">
    {children}
  </label>
);

export default FormLabel;
