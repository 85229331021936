import { Link, useHistory, useParams } from 'react-router-dom';
import { useMutation } from 'react-query';
import React from 'react';
import ResetPasswordForm from './ResetPasswordForm';
import { authApi } from '@app/utils/api';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import { Helmet } from 'react-helmet-async';
import { ApiUrl } from '@app/consts';
import { ResetPasswordRequest } from '@app/types/ResetPasswordRequest';
import { RouterUrl } from '@app/navigation/consts';

const ResetPasswordPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { uid, token } = useParams<
    Pick<ResetPasswordRequest, 'uid' | 'token'>
  >();

  const mutation = useMutation(
    (values: Pick<ResetPasswordRequest, 'new_password1' | 'new_password2'>) =>
      authApi.post<void>(ApiUrl.PasswordResetConfirm, {
        ...values,
        uid,
        token,
      }),
    { onSuccess: () => history.push(RouterUrl.SignIn) },
  );

  return (
    <main className="l-main">
      <Helmet>
        <title>{formatMessage(messages.formTitle)}</title>
        <body className="ui-theme--authorization-page" />
      </Helmet>
      <div
        className="l-sec
    g-gutter-top-2-tablet
    g-gutter-top-3-desktop
    g-gutter-bottom-2-mobile
    g-gutter-bottom-2-tablet
    g-gutter-bottom-3-desktop"
      >
        <div className="l-inner">
          <div className="g-row">
            <div
              className="g-col-12
                g-col-tablet-sm-8
                g-col-tablet-6
                g-col-offset-tablet-sm-2
                g-col-offset-tablet-3
                g-col-offset-desktop-3"
            >
              <div
                className="l-inner-whitebox-auth
                ui-bg--light-03
                g-gutter-top-4-desktop
                g-gutter-top-4-tablet
                g-gutter-top-8-mobile
                g-gutter-bottom-4-desktop
                g-gutter-bottom-4-tablet
                g-gutter-bottom-4-mobile"
              >
                <h2 className="t-headline-2 g-gutter-bottom-2">
                  <FormattedMessage {...messages.formTitle} />
                </h2>

                <ResetPasswordForm
                  onSubmit={mutation.mutate}
                  disabled={mutation.isLoading}
                  error={
                    mutation.isError
                      ? formatMessage(messages.errorMessage)
                      : undefined
                  }
                />
              </div>

              <div className="c-auth-change-mode-bar-wrapper">
                <div className="c-auth-change-mode-bar">
                  <div className="c-auth-change-mode-bar__inner l-inner-whitebox-auth">
                    <p className="c-auth-change-mode-bar__content">
                      {`${formatMessage(messages.formFooter)} `}
                      <Link to={RouterUrl.SignUp}>
                        <FormattedMessage {...messages.signUp} />
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ResetPasswordPage;
