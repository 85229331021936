import { defineMessages } from 'react-intl';

export const scope = 'pages.SignInPage';

export default defineMessages({
  formTitle: {
    id: `${scope}.formTitle`,
    defaultMessage: 'Log in',
  },
  formFooter: {
    id: `${scope}.formFooter`,
    defaultMessage: 'Dont have an account yet?',
  },
  signUp: {
    id: `${scope}.signUp`,
    defaultMessage: 'Sign up',
  },
});
