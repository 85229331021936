import { defineMessages } from 'react-intl';

export const scope = 'pages.ResetPasswordPage';

export default defineMessages({
  formTitle: {
    id: `${scope}.formTitle`,
    defaultMessage: 'Reset Password',
  },
  formFooter: {
    id: `${scope}.formFooter`,
    defaultMessage: 'Dont have an account yet?',
  },
  signUp: {
    id: `${scope}.signUp`,
    defaultMessage: 'Sign up',
  },
  newPassword1Label: {
    id: `${scope}.newPassword1Label`,
    defaultMessage: 'Type your new password',
  },
  newPassword1Placeholder: {
    id: `${scope}.newPassword1Placeholder`,
    defaultMessage: 'Type here',
  },
  newPassword1Required: {
    id: `${scope}.newPassword1Required`,
    defaultMessage: 'Please enter your password',
  },
  newPassword1MinLength: {
    id: `${scope}.newPassword1MinLength`,
    defaultMessage: 'Your password should contain minimum of 6 characters',
  },
  newPassword1MaxLength: {
    id: `${scope}.newPassword1MaxLength`,
    defaultMessage: 'Your password should contain maximum of 50 characters',
  },
  newPassword2Label: {
    id: `${scope}.newPassword2Label`,
    defaultMessage: 'Retype password agin',
  },
  newPassword2Placeholder: {
    id: `${scope}.newPassword2Placeholder`,
    defaultMessage: 'Type here',
  },
  newPassword2Required: {
    id: `${scope}.newPassword2Required`,
    defaultMessage: 'Please confirm your password',
  },
  newPassword2NotMatch: {
    id: `${scope}.newPassword2NotMatch`,
    defaultMessage: 'Your password does not match',
  },
  havingProblems: {
    id: `${scope}.havingProblems`,
    defaultMessage: 'Having problems?',
  },
  errorMessage: {
    id: `${scope}.errorMessage`,
    defaultMessage: 'Something went wrong',
  },
  getHelp: {
    id: `${scope}.getHelp`,
    defaultMessage: 'Get help',
  },
  submitButton: {
    id: `${scope}.submitButton`,
    defaultMessage: 'Save and login',
  },
});
