import { api } from '@app/utils/api';
import React, { useCallback, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import { Document } from '@app/types/Document';
import messages from './messages';
import { ApiUrl } from '@app/consts';
import { LimitOffsetResponse } from '@app/types/limit-offset';
import { NumberParam, useQueryParam, withDefault } from 'use-query-params';
import EmptyResult from './EmptyResult';
import DocumentsList from './DocumentsList';
import DocumentDetails from './DocumentDetails';
import DocumentComment from './DocumentComment';
import { OrderStatus } from '@app/types/internal';
import { useHistory, useParams } from 'react-router';
import { generatePath } from 'react-router-dom';
import { RouterUrl } from '@app/navigation/consts';
import OrderDetails from './OrderDetails';
import DocumentDrawer from './DocumentDrawer';

const DocumentsPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const [page] = useQueryParam('page', withDefault(NumberParam, 1));
  const { documentId, action } = useParams<{
    documentId: string;
    action?: 'order';
  }>();

  const limit = 100;
  const offset = (page - 1) * limit;

  const { data, isLoading } = useQuery(['documents', limit, offset], () =>
    api
      .get<LimitOffsetResponse<Document>>(ApiUrl.Documents, {
        params: {
          limit,
          offset,
          ordering: '-created_at',
          order_document__order__status__in: `${OrderStatus.AwaitingPayment},${OrderStatus.Paid}`,
        },
      })
      .then(({ data }) => data),
  );

  const document = useMemo(
    () => data?.results?.find((document) => document.id === documentId),
    [documentId, data],
  );

  const handleDocumentClick = useCallback(
    (document?: Document | null) => {
      history.push(
        generatePath(RouterUrl.Documents, { documentId: document?.id }),
      );
    },
    [history],
  );

  const handleDocumentDetailsHide = useCallback(() => {
    if (!action) {
      history.push(generatePath(RouterUrl.Documents));
    }
  }, [history, action]);

  const handleOrderDetailsHide = useCallback(() => {
    if (action) {
      history.push(generatePath(RouterUrl.Documents, { documentId }));
    }
  }, [history, action, documentId]);

  return (
    <main className="l-main">
      <Helmet>
        <title>{formatMessage(messages.title)}</title>
      </Helmet>
      {!isLoading &&
        (data?.results.length ? (
          <>
            <div
              className="l-sec
        g-gutter-top-4-desktop
        g-gutter-bottom-2-desktop
        g-gutter-bottom-4-tablet"
            >
              <div className="l-inner">
                <div className="g-row">
                  <div className="g-col-12">
                    {/* Temporary disabled
                    <div className="g-gutter-bottom-2">
                      <a href="#" className="t-body-2 ">
                        <span>{`${formatMessage(messages.title)} `}</span>
                        <ChevronDownIcon />
                      </a>
                    </div> */}
                    <DocumentsList
                      documents={data.results}
                      onItemClick={handleDocumentClick}
                    />
                  </div>
                </div>
              </div>
            </div>

            <DocumentDrawer
              document={document}
              show={!!documentId}
              onHide={handleDocumentDetailsHide}
              error={
                documentId &&
                data &&
                !document &&
                formatMessage(messages.documentNotFound)
              }
            >
              <DocumentDetails document={document} />

              {document?.document_comments?.map((comment) => (
                <DocumentComment key={comment.id} comment={comment} />
              ))}
            </DocumentDrawer>

            <DocumentDrawer
              document={document}
              show={!!documentId && action === 'order'}
              onHide={handleOrderDetailsHide}
              error={
                documentId &&
                data &&
                !document &&
                formatMessage(messages.documentNotFound)
              }
            >
              <OrderDetails document={document} />
            </DocumentDrawer>
          </>
        ) : (
          <EmptyResult />
        ))}
    </main>
  );
};

export default DocumentsPage;
