import { APIError, parseAPIErrors } from '@app/utils/parseAPIError';

export type ErrorMessageProps = {
  error?: APIError<unknown>;
};

const ErrorMessage: React.FC<ErrorMessageProps> = ({ children, error }) => (
  <span className="c-status--failure">
    {children && <span>{children}</span>}
    {error && parseAPIErrors(error).map((e) => <span key={e}>{e}</span>)}
  </span>
);

export default ErrorMessage;
