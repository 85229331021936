import { Link, useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';
import { AxiosResponse } from 'axios';
import useAuth from '@app/hooks/useAuth';
import { RegisterRequest } from '@app/types/RegisterRequest';
import { TokenResponse } from '@app/types/TokenResponse';
import { authApi } from '@app/utils/api';
import React from 'react';
import messages from './messages';
import { FormattedMessage, useIntl } from 'react-intl';
import { RouterUrl } from '@app/navigation/consts';
import { Helmet } from 'react-helmet-async';
import { ApiUrl } from '@app/consts';
import SignUpForm from '@app/components/SignUpForm';
import { authMessages } from '@app/messages';

const SignUpPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const { setAuth } = useAuth();
  const history = useHistory();

  const mutation = useMutation(
    (values: RegisterRequest) =>
      authApi.post<TokenResponse>(ApiUrl.AuthRegister, values),
    {
      onSuccess: (response: AxiosResponse<TokenResponse>) => {
        setAuth(response.data);
        history.push(RouterUrl.SignUpConfirm);
      },
    },
  );

  return (
    <main className="l-main">
      <Helmet>
        <title>{formatMessage(messages.formTitle)}</title>
        <body className="ui-theme--authorization-page" />
      </Helmet>
      <div
        className="l-sec
                g-gutter-top-2-desktop
                g-gutter-bottom-2-desktop
                g-gutter-bottom-4-tablet"
      >
        <div className="l-inner">
          <div className="g-row">
            <div
              className="g-col-12
                            g-col-tablet-sm-10
                            g-col-tablet-6
                            g-col-offset-tablet-sm-1
                            g-col-offset-tablet-3
                            g-col-offset-desktop-3"
            >
              <div
                className="l-inner-whitebox-auth
                                ui-bg--light-03
                                g-gutter-top-4
                                g-gutter-bottom-4"
              >
                <h2 className="t-headline-2-desktop t-headline-3-mobile g-gutter-bottom-2">
                  <FormattedMessage {...messages.formTitle} />
                </h2>

                <SignUpForm
                  onSubmit={mutation.mutate}
                  disabled={mutation.isLoading}
                  error={mutation.error}
                />
              </div>

              <div className="c-auth-change-mode-bar-wrapper">
                <div className="c-auth-change-mode-bar">
                  <div className="c-auth-change-mode-bar__inner l-inner-whitebox-auth">
                    <p className="c-auth-change-mode-bar__content">
                      {`${formatMessage(authMessages.signInMessage)} `}
                      <Link to={RouterUrl.SignIn}>
                        <FormattedMessage {...authMessages.signIn} />
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default SignUpPage;
