import React from 'react';
import { Element } from 'react-scroll';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import { Form, Formik, FormikHelpers } from 'formik';
import { User } from '@app/types/User';
import { sectionsLinks, UserAccountSchema } from './consts';
import PersonalInformationField from './PersonalInformationsField';
import UserTypeField from './UserTypeField';
import MarketingNotificationsField from './MarketingNotificationsField';
import Alert from '@app/components/Alert';
import ActionPopupFeedback from '@app/components/ActionPopupFeedback';
import { ExternalLink } from '@app/consts';

export interface AccountSettingsFormProps {
  user: User;
  disabled?: boolean;
  className?: string;
  success?: boolean;
  error?: boolean;
  passwordChangeSuccess?: boolean;
  passwordChangeError?: boolean;
  onSubmit: (user: User, formikHelpers: FormikHelpers<User>) => void;
  onPasswordChange?: () => void;
}

const AccountSettingsForm: React.FC<AccountSettingsFormProps> = ({
  user,
  disabled,
  className,
  success,
  error,
  passwordChangeSuccess,
  passwordChangeError,
  onSubmit,
  onPasswordChange,
}) => {
  const intl = useIntl();

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={user}
      validationSchema={UserAccountSchema}
      enableReinitialize
    >
      {({ isValid, dirty }) => (
        <Form
          autoComplete="off"
          className={className}
          style={{ paddingBottom: '100vh' }}
        >
          <div className="g-row">
            <div className="g-col-12">
              <h1 className="t-headline-1-desktop t-headline-2-mobile g-gutter-bottom-1-50">
                <FormattedMessage {...messages.title} />
              </h1>
              <p className="t-body-1">
                <FormattedMessage {...messages.subTitle} />
              </p>
            </div>
          </div>

          <Element
            className="g-gutter-top-3 g-gutter-bottom-3"
            name={sectionsLinks.personalInformation}
          >
            <div className="g-row">
              <div className="g-col-12">
                <h2 className="t-headline-3 g-gutter-bottom-2">
                  <FormattedMessage {...messages.personalInformationHeading} />
                </h2>
              </div>

              <PersonalInformationField disabled={disabled} />
            </div>
          </Element>

          {/* SEPARATOR */}
          <span className="c-section-separator"></span>
          {/* SEPARATOR */}

          <Element
            className="g-gutter-top-3 g-gutter-bottom-3"
            name={sectionsLinks.password}
          >
            <div className="g-row">
              <div className="g-col-12">
                <h2 className="t-headline-3 g-gutter-bottom-2">
                  <FormattedMessage {...messages.passwordHeading} />
                </h2>
                <p className="t-body-1">
                  <FormattedMessage {...messages.passwordSubHeading} />
                </p>
              </div>

              <div className="g-gutter-top-3 g-gutter-bottom-3 g-col-12">
                <button
                  type="button"
                  className="c-button c-button--primary g-spacing-bottom-1"
                  onClick={onPasswordChange}
                >
                  <span className="c-button__label">
                    <FormattedMessage {...messages.changePasswordButton} />
                  </span>
                </button>

                {passwordChangeSuccess && (
                  <Alert variant="success">
                    <FormattedMessage
                      {...messages.changePasswordSuccessMessage}
                    />
                  </Alert>
                )}

                {passwordChangeError && (
                  <Alert variant="error">
                    <FormattedMessage
                      {...messages.changePasswordErrorMessage}
                    />
                  </Alert>
                )}
              </div>
            </div>
          </Element>

          {/* SEPARATOR */}
          <span className="c-section-separator"></span>
          {/* SEPARATOR */}

          <Element
            className="g-gutter-top-3 g-gutter-bottom-3"
            name={sectionsLinks.userType}
          >
            <div className="g-row">
              <div className="g-col-12">
                <h2 className="t-headline-3 g-gutter-bottom-2">
                  <FormattedMessage {...messages.userTypeHeading} />
                </h2>
              </div>

              <UserTypeField disabled={disabled} />
            </div>
          </Element>

          {/* SEPARATOR */}
          <span className="c-section-separator"></span>
          {/* SEPARATOR */}

          <Element
            className="g-gutter-top-3 g-gutter-bottom-3"
            name={sectionsLinks.marketingNotifications}
          >
            <div className="g-row">
              <div className="g-col-12">
                <h2 className="t-headline-3 g-gutter-bottom-2">
                  <FormattedMessage
                    {...messages.marketingNotificationsHeading}
                  />
                </h2>
              </div>

              <MarketingNotificationsField disabled={disabled} />
            </div>
          </Element>

          {/* SEPARATOR */}
          <span className="c-section-separator"></span>
          {/* SEPARATOR */}

          <Element
            className="ui-form g-gutter-top-3"
            name={sectionsLinks.legal}
          >
            <div className="g-row">
              <div className="g-col-12">
                <h2 className="t-headline-3 g-gutter-bottom-2">
                  <FormattedMessage {...messages.legalHeading} />
                </h2>
              </div>
              <div className="g-col-12">
                {/* LEGAL LINKS */}
                <ul className="c-legal-links">
                  <li className="c-legal-links__item">
                    <a
                      className="c-legal-links__link t-body-2"
                      href={ExternalLink.TermsAndConditions}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>
                        <FormattedMessage {...messages.termsAndConditions} />
                      </span>
                    </a>
                  </li>
                  <li className="c-legal-links__item">
                    <a
                      className="c-legal-links__link t-body-2"
                      href={ExternalLink.PrivacyPolicy}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>
                        <FormattedMessage {...messages.privacyPolicy} />
                      </span>
                    </a>
                  </li>
                </ul>
                {/* LEGAL LINKS */}
              </div>
            </div>
          </Element>

          {dirty && (
            <ActionPopupFeedback
              message={intl.formatMessage(messages.formDirtyMessage)}
              buttonText={intl.formatMessage(messages.submitButton)}
              buttonType="submit"
              disabled={disabled}
            />
          )}

          {!isValid && (
            <ActionPopupFeedback
              variant="error"
              message={intl.formatMessage(messages.formInvalidMessage)}
            />
          )}

          {error && (
            <ActionPopupFeedback
              variant="error"
              message={intl.formatMessage(messages.errorMessage)}
              buttonText={intl.formatMessage(messages.submitButton)}
              buttonType="submit"
              disabled={disabled}
            />
          )}

          {success && (
            <ActionPopupFeedback
              variant="success"
              message={intl.formatMessage(messages.successMessage)}
            />
          )}
        </Form>
      )}
    </Formik>
  );
};

export default AccountSettingsForm;
