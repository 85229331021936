import { defineMessages } from 'react-intl';

export const scope = 'components.AuthModal';

export default defineMessages({
  signUpHeader: {
    id: `${scope}.signUpHeader`,
    defaultMessage: 'Please sign up',
  },
  signInHeader: {
    id: `${scope}.signInHeader`,
    defaultMessage: 'Log in',
  },
});
