import FormGroup from '@app/components/FormGroup';
import FormSwitchField from '@app/components/FormSwitchField';
import Switch from '@app/components/Switch';
import { formatName } from '@app/utils/formatName';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';

export interface MarketingNotificationsFieldProps {
  name?: string;
  disabled?: boolean;
}

const MarketingNotificationsField: React.FC<MarketingNotificationsFieldProps> = ({
  name: namePrefix,
  disabled,
}) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <FormSwitchField
        name={formatName(namePrefix, 'is_marketing_agree')}
        className="g-gutter-bottom-0-75"
        label={formatMessage(messages.marketingAgreeLabel)}
        disabled={disabled}
      />

      <FormGroup className="g-gutter-bottom-0-75">
        <Switch disabled checked>
          <span>
            <FormattedMessage {...messages.termsAndPrivacyAgreeLabel} />
          </span>
          <span className="description">
            <FormattedMessage
              {...messages.termsAndPrivacyAgreeDescription}
              values={{
                a: function u(chunks: React.ReactNode) {
                  return (
                    <a
                      href="mailto:support@kontroluje.me"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {chunks}
                    </a>
                  );
                },
              }}
            />
          </span>
        </Switch>
      </FormGroup>
    </>
  );
};

export default MarketingNotificationsField;
