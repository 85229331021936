import { defineMessages } from 'react-intl';

export const scope = 'components.Header';

export default defineMessages({
  logoLabel: {
    id: `${scope}.logoLabel`,
    defaultMessage: 'Kontroluje.me Logo',
  },
  signIn: {
    id: `${scope}.signIn`,
    defaultMessage: 'Sign in',
  },
  upload: {
    id: `${scope}.upload`,
    defaultMessage: 'Upload',
  },
  documents: {
    id: `${scope}.documents`,
    defaultMessage: 'Documents',
  },
  accountSettings: {
    id: `${scope}.accountSettings`,
    defaultMessage: 'Account Settings',
  },
  logOut: {
    id: `${scope}.logOut`,
    defaultMessage: 'Log out',
  },
  privacyPolicy: {
    id: `${scope}.privacyPolicy`,
    defaultMessage: 'Privacy Policy',
  },
  termsOfService: {
    id: `${scope}.termsOfService`,
    defaultMessage: 'Terms of Service',
  },
});
