import classNames from 'classnames';
import { useRef } from 'react';

export type SelectProps = React.DetailedHTMLProps<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
>;

const Select: React.FC<SelectProps> = ({
  children,
  className,
  placeholder,
  ...props
}) => {
  const ref = useRef<HTMLSelectElement>(null);

  const chosen =
    props.value ||
    (ref.current?.value !== null &&
      ref.current?.value !== undefined &&
      ref.current.value.length > 0);

  return (
    <select
      ref={ref}
      className={classNames(
        'ui-form__select',
        {
          'ui-form__select--choosen': chosen,
        },
        className,
      )}
      {...props}
    >
      {placeholder && (
        <option disabled value="">
          {placeholder}
        </option>
      )}
      {children}
    </select>
  );
};

export default Select;
