import { Link, useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';
import { AxiosResponse } from 'axios';
import React from 'react';
import useAuth from '@app/hooks/useAuth';
import { LoginRequest } from '@app/types/LoginRequest';
import { TokenResponse } from '@app/types/TokenResponse';
import { authApi } from '@app/utils/api';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import { RouterUrl } from '@app/navigation/consts';
import { Helmet } from 'react-helmet-async';
import { ApiUrl } from '@app/consts';
import SignInForm from '@app/components/SignInForm';

const SignInPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const { setAuth } = useAuth();
  const history = useHistory();

  const mutation = useMutation(
    (values: LoginRequest) =>
      authApi.post<TokenResponse>(ApiUrl.AuthLogin, values),
    {
      onSuccess: (response: AxiosResponse<TokenResponse>) => {
        const redirectTo = new URLSearchParams(history.location.search).get(
          'redirectTo',
        );
        setAuth(response.data);
        if (redirectTo) {
          history.push(redirectTo);
        } else {
          history.push(RouterUrl.Home);
        }
      },
    },
  );

  return (
    <main className="l-main">
      <Helmet>
        <title>{formatMessage(messages.formTitle)}</title>
        <body className="ui-theme--authorization-page" />
      </Helmet>
      <div
        className="l-sec
    g-gutter-top-2-tablet
    g-gutter-top-3-desktop
    g-gutter-bottom-2-mobile
    g-gutter-bottom-2-tablet
    g-gutter-bottom-3-desktop"
      >
        <div className="l-inner">
          <div className="g-row">
            <div
              className="g-col-12
                g-col-tablet-sm-8
                g-col-tablet-6
                g-col-offset-tablet-sm-2
                g-col-offset-tablet-3
                g-col-offset-desktop-3"
            >
              <div
                className="l-inner-whitebox-auth
                    ui-bg--light-03
                    g-gutter-top-4-desktop
                    g-gutter-top-4-tablet
                    g-gutter-top-2-mobile
                    g-gutter-bottom-4-desktop
                    g-gutter-bottom-4-tablet
                    g-gutter-bottom-4-mobile"
              >
                <h2 className="t-headline-2 g-gutter-bottom-2">
                  <FormattedMessage {...messages.formTitle} />
                </h2>

                <SignInForm
                  onSubmit={mutation.mutate}
                  disabled={mutation.isLoading}
                  error={mutation.error}
                />
              </div>

              <div className="c-auth-change-mode-bar-wrapper">
                <div className="c-auth-change-mode-bar">
                  <div className="c-auth-change-mode-bar__inner l-inner-whitebox-auth">
                    <p className="c-auth-change-mode-bar__content">
                      {`${formatMessage(messages.formFooter)} `}
                      <Link to={RouterUrl.SignUp}>
                        <FormattedMessage {...messages.signUp} />
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default SignInPage;
