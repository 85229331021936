import React, { createContext, useCallback, useMemo, useState } from 'react';
import { User } from '@app/types/User';
import { useQuery } from 'react-query';
import { api } from '@app/utils/api';
import { getToken, storeToken } from '@app/utils/tokenStorage';
import { ApiUrl } from '@app/consts';

export interface Auth {
  user?: User | null;
  token?: string | null;
  initialised: boolean;
  setAuth: (auth: Omit<Auth, 'setAuth' | 'initialised'>) => void;
}

export interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthContext = createContext<Auth>({
  user: null,
  token: null,
  initialised: false,
  setAuth: () => {
    throw 'NotImplemented';
  },
});

const AuthProvider = ({ children }: AuthProviderProps): JSX.Element => {
  const token = useMemo(getToken, []);
  const [auth, _setAuth] = useState<Omit<Auth, 'setAuth'>>({
    token,
    initialised: !token,
  });

  useQuery('authUser', () => api.get<User>(ApiUrl.AuthUser), {
    onSuccess: ({ data }) =>
      _setAuth(({ token }) => ({ token, user: data, initialised: true })),
    onError: () => _setAuth({ initialised: true }),
    enabled: !auth.initialised,
  });

  const setAuth = useCallback((auth: Omit<Auth, 'setAuth' | 'initialised'>) => {
    storeToken(auth?.token || null);
    _setAuth({ ...auth, initialised: true });
  }, []);

  return (
    <AuthContext.Provider value={{ ...auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
