import { DeliveryType } from '@app/types/DeliveryType';
import * as Yup from 'yup';
import messages from './messages';

export const DocumentSchema = Yup.object().shape({
  user_type: Yup.string().nullable().required(messages.userTypeRequired.id),
  type: Yup.string()
    .nullable()
    .max(100, messages.typeMaxLength.id)
    .required(messages.typeRequired.id),
  subject: Yup.string()
    .nullable()
    .max(500, messages.subjectMaxLength.id)
    .required(messages.subjectRequired.id),
  language: Yup.string()
    .nullable()
    .max(50, messages.languageMaxLength.id)
    .required(messages.languageRequired.id),
  style: Yup.string().nullable().max(100, messages.styleMaxLength.id),
  notes: Yup.string().nullable(),
});

export const OrderDocumentSchema = Yup.object().shape({
  document: DocumentSchema,
  proofreading: Yup.bool(),
  editing: Yup.bool(),
  formatting: Yup.bool(),
  delivery: Yup.mixed<DeliveryType>().oneOf(Object.values(DeliveryType)),
});

export const OrderSchema = Yup.object().shape({
  order_documents: Yup.array().of(OrderDocumentSchema),
});

export const typeSuggestions = [
  messages.typeSuggestionEssay,
  messages.typeSuggestionDissertation,
  messages.typeSuggestionDissertationChapter,
  messages.typeSuggestionCV,
  messages.typeSuggestionCoverLetter,
  messages.typeSuggestionThesis,
  messages.typeSuggestionThesisChapter,
  messages.typeSuggestionResearchPaper,
  messages.typeSuggestionShortStory,
  messages.typeSuggestionManuscript,
  messages.typeSuggestionReport,
  messages.typeSuggestionSurvey,
  messages.typeSuggestionResearchProposal,
  messages.typeSuggestionOther,
  messages.typeSuggestionPressRelease,
  messages.typeSuggestionWebsiteContent,
  messages.typeSuggestionPresentation,
  messages.typeSuggestionBlog,
  messages.typeSuggestionAdmissionEssay,
  messages.typeSuggestionPoetry,
  messages.typeSuggestionLetter,
  messages.typeSuggestionAssignment,
  messages.typeSuggestionPaper,
  messages.typeSuggestionResume,
  messages.typeSuggestionPersonalStatement,
  messages.typeSuggestionJournalArticle,
  messages.typeSuggestionGrantProposal,
  messages.typeSuggestionSocialMediaContent,
  messages.typeSuggestionArticle,
  messages.typeSuggestionBook,
  messages.typeSuggestionBusinessPlan,
  messages.typeSuggestionBrochure,
  messages.typeSuggestionEbook,
  messages.typeSuggestionEmail,
  messages.typeSuggestionMagazine,
  messages.typeSuggestionNovel,
  messages.typeSuggestionNewsletter,
  messages.typeSuggestionScreenplay,
  messages.typeSuggestionScript,
  messages.typeSuggestionSpeech,
  messages.typeSuggestionStatementofPurpose,
  messages.typeSuggestionLiteratureReview,
  messages.typeSuggestionPhDProposal,
  messages.typeSuggestionTranslatedContent,
  messages.typeSuggestionPhD,
];

export const styleSuggestions = [
  messages.styleSuggestionAGLC,
  messages.styleSuggestionAMA,
  messages.styleSuggestionAPA,
  messages.styleSuggestionChicagoAuthorDate,
  messages.styleSuggestionChicagoFootnotes,
  messages.styleSuggestionHarvard,
  messages.styleSuggestionIEEE,
  messages.styleSuggestionMHRA,
  messages.styleSuggestionMLA,
  messages.styleSuggestionOSCOLA,
  messages.styleSuggestionOther,
  messages.styleSuggestionOxford,
  messages.styleSuggestionTurabianAuthorDate,
  messages.styleSuggestionTurabianFootnotes,
  messages.styleSuggestionVancouver,
  messages.styleSuggestionNA,
];

export const languageSuggestionsProofreading = [
  messages.languageAmericanEnglish,
  messages.languageBritishEnglish,
  messages.languageAustralianEnglish,
  messages.languageCanadianEnglish,
];

export const languageSuggestionsTranslations = [
  messages.translationEnglishToChech,
  messages.translationChechToEnglish,
];
