import ilustration from '@app/assets/ilustration-02.svg';
import { RouterUrl } from '@app/navigation/consts';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import messages from './messages';

const PaymentDeclinedPage: React.FC = () => {
  const { formatMessage } = useIntl();
  return (
    <main className="l-main">
      <Helmet>
        <title>{formatMessage(messages.title, { user: null })}</title>
      </Helmet>
      <div
        className="l-sec
        l-sec--app-complete-sign-up
        g-gutter-top-4-mobile
        g-gutter-top-4-tablet
        g-gutter-bottom-4-mobile
        g-gutter-bottom-4-tablet
        g-gutter-bottom-5-desktop"
      >
        <div className="l-inner">
          <div className="g-row">
            <div className="g-col-12">
              <h1 className="t-center t-headline-2-desktop t-headline-3-mobile">
                <FormattedMessage {...messages.title} />
              </h1>
            </div>
            <div
              className="g-col-12
                g-gutter-top-4-desktop
                g-gutter-top-4-tablet
                g-gutter-top-2-mobile"
            >
              <div className="c-complete-sign-up">
                <figure className="c-complete-sign-up__img">
                  <img src={ilustration} alt="Ilustration" />
                </figure>
                <p className="c-complete-sign-up__desc t-body-1">
                  <FormattedMessage {...messages.messageLine1} />
                </p>
                <p className="c-complete-sign-up__desc t-body-1">
                  <FormattedMessage {...messages.messageLine2} />
                </p>
                <div className="c-complete-sign-up__action">
                  <Link
                    to={RouterUrl.AddDocument}
                    className="c-button c-button--primary"
                  >
                    <span className="c-button__label">
                      <FormattedMessage {...messages.buttonTryAgain} />
                    </span>
                  </Link>
                  <Link
                    to={RouterUrl.Home}
                    className="c-button c-button--primary-inverted"
                  >
                    <span className="c-button__label">
                      <FormattedMessage {...messages.buttonCancel} />
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default PaymentDeclinedPage;
