import { User } from '@app/types/User';
import classNames from 'classnames';
import { useMemo } from 'react';

export type UserAvatarProps = {
  user?: Partial<Pick<User, 'first_name' | 'last_name' | 'email'>> | null;
  className?: string;
};

const UserAvatar: React.FC<UserAvatarProps> = ({ user, className }) => {
  const userInitials = useMemo(() => {
    const { first_name, last_name, email } = user || {};

    if (first_name || last_name) {
      return `${first_name?.substring(0, 1)}${last_name?.substring(0, 1)}`;
    }

    if (email) {
      return email.substring(0, 2);
    }

    return null;
  }, [user]);

  return (
    <span className={classNames('c-user-profile-initials', className)}>
      {userInitials}
    </span>
  );
};

export default UserAvatar;
