import { defineMessages } from 'react-intl';

export const scope = 'components.SignUpForm';

export default defineMessages({
  typeLabel: {
    id: `${scope}.typeLabel`,
    defaultMessage: 'I am a...',
  },
  typePlaceholder: {
    id: `${scope}.typePlaceholder`,
    defaultMessage: 'Please select your role',
  },
  typeRequired: {
    id: `${scope}.typeRequired`,
    defaultMessage: 'Please choose your role',
  },
  firstNameLabel: {
    id: `${scope}.firstNameLabel`,
    defaultMessage: 'First name',
  },
  firstNamePlaceholder: {
    id: `${scope}.firstNamePlaceholder`,
    defaultMessage: 'First name',
  },
  firstNameRequired: {
    id: `${scope}.firstNameRequired`,
    defaultMessage: 'Please enter your first name',
  },
  lastNameLabel: {
    id: `${scope}.lastNameLabel`,
    defaultMessage: 'Last name',
  },
  lastNamePlaceholder: {
    id: `${scope}.lastNamePlaceholder`,
    defaultMessage: 'Last name',
  },
  lastNameRequired: {
    id: `${scope}.lastNameRequired`,
    defaultMessage: 'Please enter your last name',
  },
  phoneLabel: {
    id: `${scope}.phoneLabel`,
    defaultMessage: 'Phone',
  },
  phonePlaceholder: {
    id: `${scope}.phonePlaceholder`,
    defaultMessage: 'Phone',
  },
  phoneRequired: {
    id: `${scope}.phoneRequired`,
    defaultMessage: 'Please enter your phone number',
  },
  emailLabel: {
    id: `${scope}.emailLabel`,
    defaultMessage: 'E-mail',
  },
  emailPlaceholder: {
    id: `${scope}.emailPlaceholder`,
    defaultMessage: 'E-mail',
  },
  emailRequired: {
    id: `${scope}.emailRequired`,
    defaultMessage: 'Please enter your email address',
  },
  emailInvalid: {
    id: `${scope}.emailInvalid`,
    defaultMessage: 'Please enter a valid email address',
  },
  emailExists: {
    id: `${scope}.emailExists`,
    defaultMessage:
      'The e-mail already exists, use the <a>Login form</a> instead.',
  },
  password1Label: {
    id: `${scope}.password1Label`,
    defaultMessage: 'Password',
  },
  password1Placeholder: {
    id: `${scope}.password1Placeholder`,
    defaultMessage: 'Password',
  },
  password1Required: {
    id: `${scope}.password1Required`,
    defaultMessage: 'Please enter your password',
  },
  password1MinLength: {
    id: `${scope}.password1MinLength`,
    defaultMessage: 'Your password should contain minimum of 6 characters',
  },
  password1MaxLength: {
    id: `${scope}.password1MaxLength`,
    defaultMessage: 'Your password should contain maximum of 50 characters',
  },
  password2Label: {
    id: `${scope}.password2Label`,
    defaultMessage: 'Confirm password',
  },
  password2Placeholder: {
    id: `${scope}.password2Placeholder`,
    defaultMessage: 'Confirm password',
  },
  password2Required: {
    id: `${scope}.password2Required`,
    defaultMessage: 'Please confirm your password',
  },
  password2NotMatch: {
    id: `${scope}.password2NotMatch`,
    defaultMessage: 'Your password does not match',
  },
  marketingAgreeLabel: {
    id: `${scope}.marketingAgreeLabel`,
    defaultMessage:
      "Don't email me about product updates. If this box is left unchecked, we will occasionally send helpful and relevant emails. You can unsubscribe at any time.",
  },
  termsAndPrivacyAgreeLabel: {
    id: `${scope}.termsAndPrivacyAgreeLabel`,
    defaultMessage: 'I agree to the Terms and Privacy Policy.',
  },
  termsAndPrivacyAgreeRequired: {
    id: `${scope}.termsAndPrivacyAgreeRequired`,
    defaultMessage: 'You must accept our Terms and Privacy Policy',
  },
  submitButton: {
    id: `${scope}.submitButton`,
    defaultMessage: 'Sign up',
  },
});
