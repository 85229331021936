import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import { useIntl } from 'react-intl';
import messages from './messages';
import { sections } from './consts';
import { useMutation, useQueryClient } from 'react-query';
import { api } from '@app/utils/api';
import { User } from '@app/types/User';
import { ApiUrl } from '@app/consts';
import AccountSettingsNavigation from './AccountSettingsNavigation';
import AccountSettingsForm from './AccountSettingsForm';
import useAuth from '@app/hooks/useAuth';

const AccountSettingsPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const queryClient = useQueryClient();
  const { user } = useAuth();

  const updateAccount = useMutation(
    (user: User) => api.put<User>(ApiUrl.AuthUser, user),
    {
      onSuccess: (data) => {
        queryClient.setQueryData('authUser', data);
      },
    },
  );

  const resetPassword = useMutation(() =>
    api.post<void>(ApiUrl.PasswordReset, { email: user?.email }),
  );

  useEffect(() => {
    if (updateAccount.isSuccess) {
      const timer = setTimeout(() => {
        updateAccount.reset();
      }, 5000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [updateAccount]);

  if (!user) return null;

  return (
    <main className="l-main">
      <Helmet>
        <title>{formatMessage(messages.title)}</title>
      </Helmet>
      <div
        className="l-sec
        g-gutter-top-4-tablet
        g-gutter-top-4-desktop
        g-gutter-bottom-2-mobile
        g-gutter-bottom-4-tablet
        g-gutter-bottom-4-desktop"
      >
        <div className="l-inner l-inner--with-aside-nav l-inner--with-aside-nav--account-settings">
          <div className="g-row">
            <div className="g-col-tablet-sm-4 g-col-tablet-2 ui-hide-mobile ui-hide-tablet-sm">
              <AccountSettingsNavigation items={sections} />
            </div>

            <div className="g-col-12 g-col-tablet-sm-7 g-col-tablet-8 g-gutter-top-2-desktop g-gutter-top-3-mobile">
              <AccountSettingsForm
                className="l-inner l-inner--tight"
                user={user}
                disabled={updateAccount.isLoading || resetPassword.isLoading}
                success={updateAccount.isSuccess}
                error={updateAccount.isError}
                onSubmit={async (user, { resetForm }) => {
                  await updateAccount.mutateAsync(user);
                  resetForm();
                }}
                onPasswordChange={resetPassword.mutate}
                passwordChangeSuccess={resetPassword.isSuccess}
                passwordChangeError={resetPassword.isError}
              />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default AccountSettingsPage;
