import { defineMessages } from 'react-intl';

export const scope = 'userType';

export default defineMessages({
  student: {
    id: `${scope}.student`,
    defaultMessage: 'Student',
  },
  business: {
    id: `${scope}.business`,
    defaultMessage: 'Business',
  },
  researcher: {
    id: `${scope}.researcher`,
    defaultMessage: 'Researcher',
  },
  author: {
    id: `${scope}.author`,
    defaultMessage: 'Author',
  },
  professional: {
    id: `${scope}.professional`,
    defaultMessage: 'Professional',
  },
});
