import { useEffect } from 'react';

const useOnClickOutside = (
  ref?: React.RefObject<HTMLDivElement>,
  callback?: (event: MouseEvent | TouchEvent) => void,
): void => {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      if (
        event.target instanceof Node &&
        !ref?.current?.contains(event.target)
      ) {
        callback?.(event);
      }
    };

    if (ref) {
      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);
    }

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, callback]);
};

export default useOnClickOutside;
