import { Document } from '@app/types/Document';
import { useIntl, FormattedNumber, FormattedMessage } from 'react-intl';
import { OrderStatus } from '@app/types/OrderStatus';
import React from 'react';
import DocumentStatusIcon from './DocumentStatusIcon';
import messages from './messages';
import DocumentIcon from './DocumentIcon';
import classNames from 'classnames';
import { DocumentStatus } from '@app/types/internal';

export type DocumentsListProps = {
  documents: Document[];
  onItemClick?: (document: Document) => void;
};

const DocumentsList: React.FC<DocumentsListProps> = ({
  documents,
  onItemClick,
}) => {
  const { formatNumber } = useIntl();
  return (
    <table className="t-order-status-table" cellSpacing="0" cellPadding="0">
      <thead>
        <tr>
          <th className="document">
            <FormattedMessage {...messages.documentHeader} />
          </th>
          <th className="order">
            <FormattedMessage {...messages.orderHeader} />
          </th>
          <th className="words">
            <FormattedMessage {...messages.wordsHeader} />
          </th>
          <th className="price">
            <FormattedMessage {...messages.priceHeader} />
          </th>
          <th className="status">
            <FormattedMessage {...messages.statusHeader} />
          </th>
        </tr>
      </thead>
      <tbody>
        {documents.map((document) => (
          <tr key={document.id} onClick={() => onItemClick?.(document)}>
            <td className="document" data-th="Document">
              <span className="c-file-header">
                <span className="c-file-header__icon">
                  <DocumentIcon format={document?.format} />
                </span>
                <span className="c-file-header__title">
                  {document.subject || document.file_name || '-'}
                </span>
              </span>
            </td>
            <td className="order" data-th="Order">
              {document.order_document?.order?.id || '-'}
            </td>
            <td className="words" data-th="Words">
              {document.words_count ? (
                <FormattedNumber value={document?.words_count} />
              ) : (
                '-'
              )}
            </td>
            <td className="price" data-th="Price">
              {document.order_document?.total_amount
                ? `${formatNumber(document.order_document?.total_amount, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })} ${document.order_document?.order?.currency}`
                : '-'}
            </td>
            <td className="status" data-th="Status">
              <div
                className={classNames('c-status', {
                  'c-status--in-queue':
                    document?.status === DocumentStatus.InQueue,
                  'c-status--in-progress':
                    document?.status === DocumentStatus.InProgress,
                  'c-status--failure':
                    document?.status === DocumentStatus.FeedbackRequired,
                  'c-status--success':
                    document?.status === DocumentStatus.Completed,
                })}
              >
                <span className="c-status__icon ui-icon-wrapper c-icon-wrapper">
                  {document.order_document?.order?.status ===
                    OrderStatus.Paid && (
                    <DocumentStatusIcon status={document.status} />
                  )}
                </span>
                <span className="c-status__text">
                  <FormattedMessage
                    id={
                      document.order_document?.order?.status ===
                      OrderStatus.Paid
                        ? `documentStatus.${document.status}`
                        : `orderStatus.${document.order_document?.order?.status}`
                    }
                  />
                </span>
              </div>
            </td>
            <td className="details" data-th="Details">
              <span className="ui-link-basic-underlined">
                <FormattedMessage {...messages.showDetails} />
              </span>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default DocumentsList;
