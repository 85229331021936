import { ApiUrl } from '@app/consts';
import { DeliveryType } from '@app/types/DeliveryType';
import { OrderDocument } from '@app/types/OrderDocument';
import { PaymentsConfig } from '@app/types/PaymentsConfig';
import { Pricing } from '@app/types/Pricing';
import { api } from '@app/utils/api';
import { useField } from 'formik';
import { createContext, useMemo } from 'react';
import { useQuery } from 'react-query';
import { Document } from '@app/types/Document';
import useMemoCompare from '@app/hooks/useMemoCompare';
import useDocumentsProcess from '@app/hooks/useDocumentsProcess';

export interface CheckoutLine {
  words_count: number | null;
  words_count_loading?: boolean;
  words_count_error?: boolean;
  proofreading: boolean;
  proofreading_amount: number;
  proofreading_basic_amount: number;
  proofreading_delivery_amount: number;
  editing: boolean;
  editing_amount: number;
  editing_delivery_amount: number;
  formatting: boolean;
  formatting_amount: number;
  formatting_delivery_amount: number;
  plagiarism: boolean;
  plagiarism_amount: number;
  plagiarism_delivery_amount: number;
  translation: boolean;
  translation_amount: number;
  translation_delivery_amount: number;
  delivery: DeliveryType;
  delivery_amount: number;
  delivery_regular_amount: number;
  delivery_regular_time: number | null;
  delivery_regular_date: Date | null;
  delivery_express_amount: number;
  delivery_express_time: number | null;
  delivery_express_date: Date | null;
  delivery_rapid_amount: number;
  delivery_rapid_date: Date | null;
  delivery_rapid_time: number | null;
  total_amount: number;
  pricing?: Pricing;
}

export interface CheckoutState {
  lines: Record<number, CheckoutLine>;
  total_amount: number;
  delivery_time: number | null;
  delivery_date: Date | null;
  currency: string;
}

export const CheckoutContext = createContext<CheckoutState>({
  lines: {},
  total_amount: 0,
  delivery_time: null,
  delivery_date: null,
  currency: '',
});

const DefaultPricing: Pricing = {
  id: -1,
  document_min_length: 1000,
  document_format: '',
  proofreading_price: 0.66,
  editing_price: 0.297,
  formatting_price: 0.165,
  plagiarism_price: 0.69,
  translation_price: 1.21,
  words_per_day: 10000,
  words_per_day_translation: 4000,
  translation_word_count_percentage: 120,
  delivery_regular_price_percentage: 0,
  delivery_regular_time: 48,
  delivery_express_price_percentage: 100,
  delivery_express_time: 24,
  delivery_rapid_price_percentage: 200,
  delivery_rapid_time: 12,
  created_at: '',
  modified_at: '',
};

const roundAmount = (value: number) =>
  Math.round((value + Number.EPSILON) * 100) / 100;

const getRelativeDate = (hours: number) =>
  new Date(Math.ceil(Date.now() / (60 * 60 * 1000) + hours) * (60 * 60 * 1000));

const getDeliveryHours = (
  hours: number,
  words_count: number | null,
  words_per_day: number,
) => Math.ceil((hours * (words_count || 1)) / words_per_day);

const isCheckoutSelectionEqual = (
  prev?: OrderDocument[],
  next?: OrderDocument[],
) =>
  prev != null &&
  next != null &&
  prev.length === next.length &&
  prev.every(
    (val, idx) =>
      val.id === next[idx].id &&
      val.proofreading === next[idx].proofreading &&
      val.editing === next[idx].editing &&
      val.formatting === next[idx].formatting &&
      val.plagiarism === next[idx].plagiarism &&
      val.translation === next[idx].translation &&
      val.delivery === next[idx].delivery,
  );

const CheckoutProvider: React.FC = ({ children }) => {
  const [, { value }] = useField<OrderDocument[]>('order_documents');
  const values = useMemoCompare(value, isCheckoutSelectionEqual);

  const { data: pricingMap } = useQuery('pricingMap', () =>
    api
      .get<Pricing[]>(ApiUrl.Pricing)
      .then(({ data }) =>
        data.reduce(
          (map, cur) => ({ ...map, [cur.document_format]: cur }),
          {} as Record<string, Pricing>,
        ),
      ),
  );

  const { data: currency } = useQuery('paymentsCurrency', () =>
    api
      .get<PaymentsConfig>(ApiUrl.PaymentsConfig)
      .then(({ data }) => data.currency),
  );

  const documents = useMemo(
    () =>
      (values || [])
        .map(({ document }) => document)
        .filter((document): document is Document => document !== undefined),
    [values],
  );

  const processed = useDocumentsProcess(documents, {
    retry: 60,
    refetchInterval: 5000,
  });

  const checkout: CheckoutState = useMemo(() => {
    let total_amount = 0;
    let delivery_date: Date | null = null;
    let delivery_time: number | null = null;

    const lines: Record<number, CheckoutLine> | undefined = values?.reduce(
      (
        lines,
        {
          id,
          proofreading,
          editing,
          formatting,
          plagiarism,
          translation,
          delivery,
          document,
        },
        index,
      ) => {
        const line: CheckoutLine = {
          words_count:
            document?.words_count === undefined ? null : document?.words_count,
          proofreading,
          proofreading_amount: 0,
          proofreading_basic_amount: 0,
          proofreading_delivery_amount: 0,
          editing,
          editing_amount: 0,
          editing_delivery_amount: 0,
          formatting,
          formatting_amount: 0,
          formatting_delivery_amount: 0,
          plagiarism,
          plagiarism_amount: 0,
          plagiarism_delivery_amount: 0,
          translation,
          translation_amount: 0,
          translation_delivery_amount: 0,
          delivery_regular_amount: 0,
          delivery_regular_time: null,
          delivery_regular_date: null,
          delivery_express_amount: 0,
          delivery_express_time: null,
          delivery_express_date: null,
          delivery_rapid_amount: 0,
          delivery_rapid_date: null,
          delivery_rapid_time: null,
          delivery,
          delivery_amount: 0,
          total_amount: 0,
          pricing: {
            ...DefaultPricing,
            ...(document?.format && pricingMap?.[document?.format]),
          },
        };

        if (line.words_count === null) {
          const result = processed[index];
          if (result) {
            line.words_count =
              result.processed?.words_count === undefined
                ? line.words_count
                : result.processed.words_count;
            line.words_count_loading = result.isLoading;
            line.words_count_error = result.isError || result.isRetriesExceeded;
          } else {
            line.words_count_loading = true;
            line.words_count_error = undefined;
          }
        }

        if (line.pricing) {
          let words_count =
            line.words_count !== null &&
            line.words_count > line.pricing.document_min_length
              ? line.words_count
              : line.pricing.document_min_length;

          const baseWordsCount = words_count;
          const isAdditionalService = translation
            ? proofreading || editing || formatting || plagiarism
            : editing || formatting || plagiarism;

          if (translation) {
            words_count = Math.round(
              (words_count * line.pricing.translation_word_count_percentage) /
                100,
            );
          }

          const wordsPerDay = translation
            ? line.pricing.words_per_day_translation
            : line.pricing.words_per_day;

          line.delivery_regular_time = getDeliveryHours(
            line.pricing.delivery_regular_time,
            baseWordsCount,
            wordsPerDay,
          );
          line.delivery_express_time = getDeliveryHours(
            line.pricing.delivery_express_time,
            baseWordsCount,
            wordsPerDay,
          );
          line.delivery_rapid_time = getDeliveryHours(
            line.pricing.delivery_rapid_time,
            baseWordsCount,
            wordsPerDay,
          );

          if (translation && isAdditionalService) {
            line.delivery_regular_time += getDeliveryHours(
              line.pricing.delivery_regular_time,
              words_count,
              line.pricing.words_per_day,
            );
            line.delivery_express_time += getDeliveryHours(
              line.pricing.delivery_express_time,
              words_count,
              line.pricing.words_per_day,
            );
            line.delivery_rapid_time += getDeliveryHours(
              line.pricing.delivery_rapid_time,
              words_count,
              line.pricing.words_per_day,
            );
          }

          line.delivery_regular_date = getRelativeDate(
            line.delivery_regular_time,
          );
          line.delivery_express_date = getRelativeDate(
            line.delivery_express_time,
          );
          line.delivery_rapid_date = getRelativeDate(line.delivery_rapid_time);

          let delivery_price_percentage = 0;
          if (delivery === DeliveryType.Regular) {
            delivery_price_percentage =
              line.pricing.delivery_regular_price_percentage;
            if (
              !delivery_time ||
              delivery_time < line.pricing.delivery_regular_time
            ) {
              delivery_time = line.pricing.delivery_regular_time;
              delivery_date = line.delivery_regular_date;
            }
          } else if (delivery === DeliveryType.Express) {
            delivery_price_percentage =
              line.pricing.delivery_express_price_percentage;
            if (
              !delivery_time ||
              delivery_time < line.pricing.delivery_express_time
            ) {
              delivery_time = line.pricing.delivery_express_time;
              delivery_date = line.delivery_express_date;
            }
          } else if (delivery === DeliveryType.Rapid) {
            delivery_price_percentage =
              line.pricing.delivery_rapid_price_percentage;
            if (
              !delivery_time ||
              delivery_time < line.pricing.delivery_rapid_time
            ) {
              delivery_time = line.pricing.delivery_rapid_time;
              delivery_date = line.delivery_rapid_date;
            }
          }

          if (words_count !== null) {
            line.proofreading_amount = roundAmount(
              line.pricing.proofreading_price * words_count,
            );
            line.editing_amount = roundAmount(
              line.pricing.editing_price * words_count,
            );
            line.formatting_amount = roundAmount(
              line.pricing.formatting_price * words_count,
            );
            line.plagiarism_amount = roundAmount(
              line.pricing.plagiarism_price * words_count,
            );
            line.translation_amount = roundAmount(
              line.pricing.translation_price * baseWordsCount,
            );
            line.proofreading_basic_amount = roundAmount(
              line.pricing.proofreading_price * baseWordsCount,
            );

            if (proofreading) {
              line.proofreading_delivery_amount = roundAmount(
                (line.proofreading_amount * delivery_price_percentage) / 100,
              );

              line.total_amount = roundAmount(
                line.total_amount + line.proofreading_amount,
              );

              line.delivery_amount = roundAmount(
                line.delivery_amount + line.proofreading_delivery_amount,
              );

              line.delivery_regular_amount = roundAmount(
                line.delivery_regular_amount +
                  (line.proofreading_amount *
                    line.pricing.delivery_regular_price_percentage) /
                    100,
              );
              line.delivery_express_amount = roundAmount(
                line.delivery_express_amount +
                  (line.proofreading_amount *
                    line.pricing.delivery_express_price_percentage) /
                    100,
              );
              line.delivery_rapid_amount = roundAmount(
                line.delivery_rapid_amount +
                  (line.proofreading_amount *
                    line.pricing.delivery_rapid_price_percentage) /
                    100,
              );
            }

            if (editing) {
              line.editing_delivery_amount = roundAmount(
                (line.editing_amount * delivery_price_percentage) / 100,
              );

              line.total_amount = roundAmount(
                line.total_amount + line.editing_amount,
              );

              line.delivery_amount = roundAmount(
                line.delivery_amount + line.editing_delivery_amount,
              );

              line.delivery_regular_amount = roundAmount(
                line.delivery_regular_amount +
                  (line.editing_amount *
                    line.pricing.delivery_regular_price_percentage) /
                    100,
              );
              line.delivery_express_amount = roundAmount(
                line.delivery_express_amount +
                  (line.editing_amount *
                    line.pricing.delivery_express_price_percentage) /
                    100,
              );
              line.delivery_rapid_amount = roundAmount(
                line.delivery_rapid_amount +
                  (line.editing_amount *
                    line.pricing.delivery_rapid_price_percentage) /
                    100,
              );
            }

            if (formatting) {
              line.formatting_delivery_amount = roundAmount(
                (line.formatting_amount * delivery_price_percentage) / 100,
              );

              line.total_amount = roundAmount(
                line.total_amount + line.formatting_amount,
              );

              line.delivery_amount = roundAmount(
                line.delivery_amount + line.formatting_delivery_amount,
              );

              line.delivery_regular_amount = roundAmount(
                line.delivery_regular_amount +
                  (line.formatting_amount *
                    line.pricing.delivery_regular_price_percentage) /
                    100,
              );
              line.delivery_express_amount = roundAmount(
                line.delivery_express_amount +
                  (line.formatting_amount *
                    line.pricing.delivery_express_price_percentage) /
                    100,
              );
              line.delivery_rapid_amount = roundAmount(
                line.delivery_rapid_amount +
                  (line.formatting_amount *
                    line.pricing.delivery_rapid_price_percentage) /
                    100,
              );
            }

            if (plagiarism) {
              line.plagiarism_delivery_amount = roundAmount(
                (line.plagiarism_amount * delivery_price_percentage) / 100,
              );

              line.total_amount = roundAmount(
                line.total_amount + line.plagiarism_amount,
              );

              line.delivery_amount = roundAmount(
                line.delivery_amount + line.plagiarism_delivery_amount,
              );

              line.delivery_regular_amount = roundAmount(
                line.delivery_regular_amount +
                  (line.plagiarism_amount *
                    line.pricing.delivery_regular_price_percentage) /
                    100,
              );
              line.delivery_express_amount = roundAmount(
                line.delivery_express_amount +
                  (line.plagiarism_amount *
                    line.pricing.delivery_express_price_percentage) /
                    100,
              );
              line.delivery_rapid_amount = roundAmount(
                line.delivery_rapid_amount +
                  (line.plagiarism_amount *
                    line.pricing.delivery_rapid_price_percentage) /
                    100,
              );
            }

            if (translation) {
              line.translation_delivery_amount = roundAmount(
                (line.translation_amount * delivery_price_percentage) / 100,
              );

              line.total_amount = roundAmount(
                line.total_amount + line.translation_amount,
              );

              line.delivery_amount = roundAmount(
                line.delivery_amount + line.translation_delivery_amount,
              );

              line.delivery_regular_amount = roundAmount(
                line.delivery_regular_amount +
                  (line.translation_amount *
                    line.pricing.delivery_regular_price_percentage) /
                    100,
              );
              line.delivery_express_amount = roundAmount(
                line.delivery_express_amount +
                  (line.translation_amount *
                    line.pricing.delivery_express_price_percentage) /
                    100,
              );
              line.delivery_rapid_amount = roundAmount(
                line.delivery_rapid_amount +
                  (line.translation_amount *
                    line.pricing.delivery_rapid_price_percentage) /
                    100,
              );
            }

            line.total_amount = roundAmount(
              line.total_amount + line.delivery_amount,
            );

            total_amount = roundAmount(total_amount + line.total_amount);
          }
        }
        return { ...lines, [id]: line };
      },
      {},
    );

    return {
      lines: lines || {},
      total_amount,
      delivery_time,
      delivery_date,
      currency: currency || '',
    };
  }, [values, currency, pricingMap, processed]);

  return (
    <CheckoutContext.Provider value={checkout}>
      {children}
    </CheckoutContext.Provider>
  );
};

export default CheckoutProvider;
