import { defineMessages } from 'react-intl';

export const scope = 'auth';

export default defineMessages({
  signInMessage: {
    id: `${scope}.signInMessage`,
    defaultMessage: 'Already have an account?',
  },
  signIn: {
    id: `${scope}.signInButton`,
    defaultMessage: 'Log in',
  },
  signUpMessage: {
    id: `${scope}.signUpMessage`,
    defaultMessage: 'Dont have an account yet?',
  },
  signUp: {
    id: `${scope}.signUp`,
    defaultMessage: 'Sign up',
  },
});
