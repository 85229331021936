import { defineMessages } from 'react-intl';

export const scope = 'pages.OrderConfirmPage';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Your order is with our experts!',
  },
  message: {
    id: `${scope}.message`,
    defaultMessage:
      'Please find a summary of your order below. We will email you once it’s complete.',
  },
  orderIdLabel: {
    id: `${scope}.orderIdLabel`,
    defaultMessage: 'Order #:',
  },
  wordCountLabel: {
    id: `${scope}.wordCountLabel`,
    defaultMessage: 'Word count:',
  },
  nameLabel: {
    id: `${scope}.nameLabel`,
    defaultMessage: 'Name:',
  },
  servicesLabel: {
    id: `${scope}.servicesLabel`,
    defaultMessage: 'Services:',
  },
  deliveryLabel: {
    id: `${scope}.deliveryLabel`,
    defaultMessage: 'Delivery:',
  },
  costLabel: {
    id: `${scope}.costLabel`,
    defaultMessage: 'Cost:',
  },
  continueToMyDocuments: {
    id: `${scope}.continueToMyDocuments`,
    defaultMessage: 'Continue to my documents',
  },
});
