import { defineMessages } from 'react-intl';

export const scope = 'pages.DocumentsPage';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'All Documents',
  },
  emptyTitle: {
    id: `${scope}.emptyTitle`,
    defaultMessage: 'Nothing uploaded yet?',
  },
  emptyMessage: {
    id: `${scope}.emptyMessage`,
    defaultMessage:
      'We we’re just wondering if you’re interested in uploading your first document. There’s no time to lose!',
  },
  uploadDocument: {
    id: `${scope}.uploadDocument`,
    defaultMessage: 'Upload Your First Document',
  },
  documentHeader: {
    id: `${scope}.documentHeader`,
    defaultMessage: 'Document',
  },
  orderHeader: {
    id: `${scope}.orderHeader`,
    defaultMessage: 'Order',
  },
  wordsHeader: {
    id: `${scope}.wordsHeader`,
    defaultMessage: 'Words',
  },
  priceHeader: {
    id: `${scope}.priceHeader`,
    defaultMessage: 'Price',
  },
  statusHeader: {
    id: `${scope}.statusHeader`,
    defaultMessage: 'Status',
  },
  showDetails: {
    id: `${scope}.showDetails`,
    defaultMessage: 'Show details',
  },
  orderIdLabel: {
    id: `${scope}.orderIdLabel`,
    defaultMessage: 'Order #',
  },
  wordsCountLabel: {
    id: `${scope}.wordsCountLabel`,
    defaultMessage: 'Words',
  },
  servicesLabel: {
    id: `${scope}.servicesLabel`,
    defaultMessage: 'Services',
  },
  createdAtLabel: {
    id: `${scope}.createdAtLabel`,
    defaultMessage: 'Submited',
  },
  deliveryExpectedAtLabel: {
    id: `${scope}.deliveryExpectedAtLabel`,
    defaultMessage: 'Expected',
  },
  priceLabel: {
    id: `${scope}.priceLabel`,
    defaultMessage: 'Price',
  },
  originalFileLabel: {
    id: `${scope}.originalFileLabel`,
    defaultMessage: 'Original file',
  },
  cleanCopyFileLabel: {
    id: `${scope}.cleanCopyFileLabel`,
    defaultMessage: 'Clean copy',
  },
  trackedChangesFileLabel: {
    id: `${scope}.trackedChangesFileLabel`,
    defaultMessage: 'Tracked changes',
  },
  certificateFileLabel: {
    id: `${scope}.certificateFileLabel`,
    defaultMessage: 'Certificate',
  },
  download: {
    id: `${scope}.download`,
    defaultMessage: 'Download',
  },
  documentNotFound: {
    id: `${scope}.documentNotFound`,
    defaultMessage: 'Document Not found',
  },
  orderDetailsLabel: {
    id: `${scope}.orderDetailsLabel`,
    defaultMessage: 'Order Details',
  },
  orderDetailsLink: {
    id: `${scope}.orderDetailsLink`,
    defaultMessage: 'Details',
  },
  receiptLabel: {
    id: `${scope}.receiptLabel`,
    defaultMessage: 'Receipt',
  },
  receiptLink: {
    id: `${scope}.receiptLink`,
    defaultMessage: 'Details',
  },
  commentedOn: {
    id: `${scope}.commentedOn`,
    defaultMessage: 'commented on {date}',
  },
  documentFileLabel: {
    id: `${scope}.documentFileLabel`,
    defaultMessage: 'Document',
  },
  typeLabel: {
    id: `${scope}.typeLabel`,
    defaultMessage: 'Type',
  },
  subjectLabel: {
    id: `${scope}.subjectLabel`,
    defaultMessage: 'Subject',
  },
  languageLabel: {
    id: `${scope}.languageLabel`,
    defaultMessage: 'Language',
  },
  styleLabel: {
    id: `${scope}.styleLabel`,
    defaultMessage: 'Referencing style',
  },
  keywordsLabel: {
    id: `${scope}.keywordsLabel`,
    defaultMessage: 'Keywords',
  },
  notesLabel: {
    id: `${scope}.notesLabel`,
    defaultMessage: 'Notes',
  },
});
