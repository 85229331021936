import { FormattedMessage } from 'react-intl';
import messages from './messages';
import { Formik, Form, FieldArray } from 'formik';
import { Order } from '@app/types/Order';
import { OrderSchema } from './consts';
import React, { useMemo } from 'react';
import OrderDocumentField from './OrderDocumentField';
import OrderSummary from './OrderSummary';
import CheckoutProvider from '@app/providers/checkout-provider';

export type OrderFormProps = {
  order: Order;
  onSubmit?: (order: Order) => void;
  disabled?: boolean;
  error?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
};

const OrderForm: React.FC<OrderFormProps> = ({
  order,
  onSubmit,
  error,
  disabled,
}) => {
  const initialValues: Order = useMemo(
    () => ({
      ...order,
      order_documents: order.order_documents?.map((orderDocument) => ({
        ...orderDocument,
        document: orderDocument.document
          ? {
              ...orderDocument.document,
              user_type: orderDocument.document?.user_type || '',
              type: orderDocument.document?.type || '',
              subject: orderDocument.document?.subject || '',
              language: orderDocument.document?.language || '',
              style: orderDocument.document?.style || '',
              keywords: orderDocument.document?.keywords || [],
              notes: orderDocument.document?.notes || '',
            }
          : undefined,
      })),
    }),
    [order],
  );
  return (
    <Formik
      onSubmit={(values) => onSubmit?.(values)}
      initialValues={initialValues}
      validationSchema={OrderSchema}
    >
      {({ values }) => (
        <CheckoutProvider>
          <Form
            className="l-sec g-gutter-top-2-mobile g-gutter-top-2-tablet g-gutter-top-2-desktop g-gutter-bottom-2-mobile g-gutter-bottom-4-tablet g-gutter-bottom-2-desktop"
            autoComplete="off"
          >
            <div className="l-inner">
              <div className="g-row">
                <div className="g-col-12 g-col-tablet-8">
                  <div
                    className="c-sticky-box-start js-sticky-box-start"
                    data-sticky-name="right-column-price"
                  ></div>
                  <div className="c-order-main-heading">
                    <h1 className="c-order-main-heading__title t-headline-2">
                      <FormattedMessage {...messages.title} />
                    </h1>
                  </div>

                  <div className="g-gutter-top-2">
                    <div className="l-inner l-inner--order-form-content ui-bg--light-03">
                      <FieldArray
                        name="order_documents"
                        render={() =>
                          values.order_documents?.map(({ id }, index) => (
                            <OrderDocumentField
                              key={id}
                              name={`order_documents[${index}]`}
                              disabled={disabled}
                            />
                          ))
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="g-col-12 g-col-tablet-4 g-col-desktop-4 g-gutter-top-4-tablet g-gutter-top-2-mobile c-your-price-box-sticky-wrapper">
                  <OrderSummary
                    order={values}
                    disabled={disabled}
                    error={error}
                  />
                </div>
              </div>
            </div>
          </Form>
        </CheckoutProvider>
      )}
    </Formik>
  );
};

export default OrderForm;
