import classNames from 'classnames';
import { useMemo } from 'react';

export interface AlertProps {
  variant?: 'default' | 'success' | 'error' | 'warning';
  className?: string;
}

const Alert: React.FC<AlertProps> = ({
  variant = 'default',
  className,
  children,
}) => {
  const wrapperClassName = useMemo(
    () =>
      classNames(
        'c-sidebar-order-details__status',
        {
          'c-sidebar-order-details__status--in-queue': variant === 'default',
          'c-sidebar-order-details__status--in-progress': variant === 'warning',
          'c-sidebar-order-details__status--failure': variant === 'error',
          'c-sidebar-order-details__status--completed': variant === 'success',
        },
        className,
      ),
    [className, variant],
  );

  return (
    <div className={wrapperClassName} style={{ height: 'auto' }}>
      {children}
    </div>
  );
};

export default Alert;
