import { defineMessages } from 'react-intl';

export const scope = 'pages.PaymentDeclinedPage';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Payment declined!',
  },
  messageLine1: {
    id: `${scope}.messageLine1`,
    defaultMessage:
      'We we’re just wondering if you’re interested in uploading your first document.',
  },
  messageLine2: {
    id: `${scope}.messageLine2`,
    defaultMessage: 'There’s no time to lose!',
  },
  buttonTryAgain: {
    id: `${scope}.tryAgain`,
    defaultMessage: 'Try again',
  },
  buttonCancel: {
    id: `${scope}.cancel`,
    defaultMessage: 'cancel',
  },
});
