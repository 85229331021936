export function splitPath(
  value: string,
): [string | undefined, string | undefined, string | undefined] {
  let name = value?.split('\\').pop()?.split('/').pop();
  const path =
    value?.substring(0, value.length - (name?.length || 0)) || undefined;
  const extension = /(?:(\.[^.]+))?$/.exec(name || '')?.[1];
  if (extension?.length) {
    name = name?.substring(0, name.length - extension.length);
  }
  return [path, name, extension];
}
