import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Route } from 'react-router-dom';
import Footer from './components/Footer';
import Header from './components/Header';
import Routes from './navigation/Routes';
import AuthProvider from './providers/auth-provider';
import IntlProvider from './providers/intl-provider';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { authApi } from './utils/api';
import { ApiUrl } from './consts';
import { PaymentsConfig } from './types/PaymentsConfig';
import { QueryParamProvider } from 'use-query-params';

const stripePromise = authApi
  .get<PaymentsConfig>(ApiUrl.PaymentsConfig)
  .then(({ data: { public_key } }) => loadStripe(public_key));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const App = (): JSX.Element => (
  <IntlProvider>
    <QueryClientProvider client={queryClient}>
      <HelmetProvider>
        <Elements stripe={stripePromise}>
          <BrowserRouter>
            <QueryParamProvider ReactRouterRoute={Route}>
              <AuthProvider>
                <Header />
                <Helmet
                  titleTemplate="%s - kontroluje.me"
                  defaultTitle="kontroluje.me"
                />
                <Routes />
                <Footer />
              </AuthProvider>
            </QueryParamProvider>
          </BrowserRouter>
        </Elements>
      </HelmetProvider>
    </QueryClientProvider>
  </IntlProvider>
);

export default App;
