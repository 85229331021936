import { ReactComponent as DocxIcon } from '@app/assets/icon-tiny-format-docx.svg';
import { ReactComponent as PdfIcon } from '@app/assets/icon-tiny-format-pdf.svg';

export type DocumentIconProps = {
  format?: string;
};

const icons: { [format: string]: JSX.Element } = {
  pdf: <PdfIcon />,
  doc: <DocxIcon />,
  docx: <DocxIcon />,
};

const DocumentIcon: React.FC<DocumentIconProps> = ({ format }) =>
  (format && icons[format]) || <></>;

export default DocumentIcon;
