import Modal, { ModalProps } from '@app/components/Modal';
import { ApiUrl } from '@app/consts';
import useAuth from '@app/hooks/useAuth';
import { LoginRequest } from '@app/types/LoginRequest';
import { RegisterRequest } from '@app/types/RegisterRequest';
import { TokenResponse } from '@app/types/TokenResponse';
import { authApi } from '@app/utils/api';
import { AxiosResponse } from 'axios';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import SignInForm from '../SignInForm';
import SignUpForm from '../SignUpForm';
import messages from './messages';
import { authMessages } from '@app/messages';

export type AuthModalProps = ModalProps & {
  onAuth: () => void;
};

const AuthModal: React.FC<AuthModalProps> = ({ show, onHide, onAuth }) => {
  const { formatMessage } = useIntl();
  const [mode, setMode] = useState<'sign-in' | 'sign-up'>('sign-up');
  const { setAuth } = useAuth();

  const signIn = useMutation(
    (values: LoginRequest) =>
      authApi.post<TokenResponse>(ApiUrl.AuthLogin, values),
    {
      onSuccess: (response: AxiosResponse<TokenResponse>) => {
        setAuth(response.data);
        onAuth();
      },
    },
  );

  const signUp = useMutation(
    (values: RegisterRequest) =>
      authApi.post<TokenResponse>(ApiUrl.AuthRegister, {
        ...values,
        password2: values.password1,
      }),
    {
      onSuccess: (response: AxiosResponse<TokenResponse>) => {
        setAuth(response.data);
        onAuth();
      },
    },
  );

  return (
    <Modal show={show} onHide={onHide} modalTheme="small-auth-box">
      <div className="l-inner">
        <div className="g-row">
          <div className="g-col-12">
            <div
              className="
                    ui-bg--light-03
                    g-gutter-top-4-desktop
                    g-gutter-top-4-tablet
                    g-gutter-top-2-mobile
                    g-gutter-bottom-4-desktop
                    g-gutter-bottom-4-tablet
                    g-gutter-bottom-4-mobile"
            >
              {mode === 'sign-up' ? (
                <>
                  <h2 className="t-headline-2 g-gutter-bottom-2">
                    <FormattedMessage {...messages.signUpHeader} />
                  </h2>
                  <SignUpForm
                    compact
                    onSubmit={signUp.mutate}
                    disabled={signUp.isLoading}
                    error={signUp.error}
                    onLoginClick={() => setMode('sign-in')}
                  />
                </>
              ) : (
                <>
                  <h2 className="t-headline-2 g-gutter-bottom-2">
                    <FormattedMessage {...messages.signInHeader} />
                  </h2>
                  <SignInForm
                    compact
                    onSubmit={signIn.mutate}
                    disabled={signIn.isLoading}
                    error={signIn.error}
                  />
                </>
              )}
            </div>

            <div className="c-auth-change-mode-bar-wrapper">
              <div className="c-auth-change-mode-bar">
                <div className="c-auth-change-mode-bar__inner l-inner-whitebox-auth">
                  {mode === 'sign-up' ? (
                    <p className="c-auth-change-mode-bar__content">
                      {`${formatMessage(authMessages.signInMessage)} `}
                      <a onClick={() => setMode('sign-in')}>
                        <FormattedMessage {...authMessages.signIn} />
                      </a>
                    </p>
                  ) : (
                    <p className="c-auth-change-mode-bar__content">
                      {`${formatMessage(authMessages.signUpMessage)} `}
                      <a onClick={() => setMode('sign-up')}>
                        <FormattedMessage {...authMessages.signUp} />
                      </a>
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AuthModal;
