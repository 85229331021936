import { defineMessages } from 'react-intl';

export const scope = 'pages.AddDocumentPage';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Add your document',
  },
  fileFormatsDescription: {
    id: `${scope}.fileFormatsDescription`,
    defaultMessage:
      'We currently work with over 17 file formats. Click on your document file icon below to find out more about how we work in that format.',
  },
  unsupportedFormat: {
    id: `${scope}.unsupportedFormat`,
    defaultMessage: 'Unsupported Format',
  },
  unsupportedFormatMessage1: {
    id: `${scope}.unsupportedFormatMessage1`,
    defaultMessage:
      'The file listed above has not been uploaded, because it is in a format that we do not support.',
  },
  unsupportedFormatMessage2: {
    id: `${scope}.unsupportedFormatMessage2`,
    defaultMessage: 'Please contact our support as we will be able to help.',
  },
  contactSupport: {
    id: `${scope}.contactSupport`,
    defaultMessage: 'Contact support',
  },
  close: {
    id: `${scope}.close`,
    defaultMessage: 'Close',
  },
  instantQuoteMessage: {
    id: `${scope}.instantQuoteMessage`,
    defaultMessage: 'You can also upload a document to get an instant quote',
  },
  fileUploadMessage: {
    id: `${scope}.fileUploadMessage`,
    defaultMessage: 'Drag & drop your file here',
  },
  orBrowseComputer: {
    id: `${scope}.orBrowseComputer`,
    defaultMessage: 'or <u>browse</u> your computer',
  },
  borwseDevice: {
    id: `${scope}.borwseDevice`,
    defaultMessage: 'Browse from your device',
  },
  dropFileHere: {
    id: `${scope}.dropFileHere`,
    defaultMessage: 'Drop your file here!',
  },
  uploadingFile: {
    id: `${scope}.uploadingFile`,
    defaultMessage: 'Your file is being uploaded!',
  },
  uploadingFileCompleted: {
    id: `${scope}.uploadingFileCompleted`,
    defaultMessage: 'Your file has been uploaded!',
  },
  supportedFilesLabel: {
    id: `${scope}.supportedFilesLabel`,
    defaultMessage: 'Supported files for uplad',
  },
  supportedFiles: {
    id: `${scope}.supportedFiles`,
    defaultMessage: 'Doc, Docx, PDF',
  },
  otherFilesLabel: {
    id: `${scope}.otherFilesLabel`,
    defaultMessage: 'For other file types like',
  },
  otherFiles: {
    id: `${scope}.otherFiles`,
    defaultMessage: 'PPT, KEY, LATEX, OTF, RFT, JPEG, PNG, PAGES,',
  },
  otherFilesDescription: {
    id: `${scope}.otherFilesDescription`,
    defaultMessage:
      'Get in touch <light>with our support team as we are able to edit documents.</light>',
  },
  sendUsEmailButton: {
    id: `${scope}.sendUsEmailButton`,
    defaultMessage: 'Send us email',
  },
});
