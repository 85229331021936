import { defineMessages } from 'react-intl';

export const scope = 'components.Footer';

export default defineMessages({
  privacyPolicy: {
    id: `${scope}.privacyPolicy`,
    defaultMessage: 'Privacy Policy',
  },
  terms: {
    id: `${scope}.terms`,
    defaultMessage: 'Terms',
  },
});
