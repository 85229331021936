import { defineMessages } from 'react-intl';

export const scope = 'delivery';

export default defineMessages({
  regular: {
    id: `${scope}.regular`,
    defaultMessage: 'Regular',
  },
  express: {
    id: `${scope}.express`,
    defaultMessage: 'Express',
  },
  rapid: {
    id: `${scope}.rapid`,
    defaultMessage: 'Rapid',
  },
});
