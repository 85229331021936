import { useField } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import FormGroup from './FormGroup';
import Switch, { SwitchProps } from './Switch';

export interface FormSwitchFieldProps extends SwitchProps {
  name: string;
  label?: string;
}

const FormSwitchField: React.FC<FormSwitchFieldProps> = ({
  name,
  className,
  label,
  children,
  ...inputProps
}) => {
  const [field, { touched, error }] = useField(name);
  const { formatMessage } = useIntl();

  return (
    <FormGroup
      className={className}
      error={touched && error && formatMessage({ id: error })}
    >
      <Switch {...field} {...inputProps} checked={field.value}>
        {label}
        {children}
      </Switch>
    </FormGroup>
  );
};

export default FormSwitchField;
