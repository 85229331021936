import { FieldInputProps, useField } from 'formik';
import React, { useCallback, useMemo, useState } from 'react';
import Autosuggest, { ChangeEvent, InputProps } from 'react-autosuggest';
import { MessageDescriptor, useIntl } from 'react-intl';

export type Suggestion = MessageDescriptor;

export type FormattedAutosuggestFieldProps = FieldInputProps<string> &
  InputProps<string> & {
    suggestions: Suggestion[];
  };

const FormattedAutosuggestField: React.FC<FormattedAutosuggestFieldProps> = ({
  name,
  suggestions,
  ...inputProps
}) => {
  const [field, , helpers] = useField(name);
  const { formatMessage } = useIntl();
  const [filteredSugestions, setFilteredSugestions] = useState<Suggestion[]>(
    [],
  );

  const formattedSuggestions = useMemo(
    () =>
      suggestions.reduce(
        (formatted, suggestion) =>
          suggestion.id
            ? {
                ...formatted,
                [suggestion.id]: formatMessage(suggestion),
              }
            : formatted,
        {} as Record<string, string>,
      ),
    [formatMessage, suggestions],
  );

  const handleChange = useCallback(
    (_: unknown, { newValue }: ChangeEvent) => helpers.setValue(newValue),
    [helpers],
  );

  const getSuggestionValue = useCallback(
    ({ defaultMessage }: Suggestion) =>
      typeof defaultMessage === 'string' ? defaultMessage : '',
    [],
  );

  const renderSuggestion = useCallback(
    ({ id }: Suggestion) => (id ? formattedSuggestions[id] : ''),
    [formattedSuggestions],
  );

  const filterSuggestions = useCallback(
    ({ value }: { value: string }) => {
      const inputValue = value.trim().toLowerCase();
      if (inputValue.length === 0) {
        setFilteredSugestions([]);
      } else {
        setFilteredSugestions(
          suggestions.filter(
            ({ id }) =>
              id &&
              formattedSuggestions[id]
                .toLowerCase()
                .slice(0, inputValue.length) === inputValue,
          ),
        );
      }
    },
    [suggestions, formattedSuggestions],
  );

  const clearSuggestions = useCallback(() => setFilteredSugestions([]), []);

  return (
    <Autosuggest
      id={`${name}-autosuggest`}
      suggestions={filteredSugestions}
      onSuggestionsFetchRequested={filterSuggestions}
      onSuggestionsClearRequested={clearSuggestions}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      inputProps={{
        ...inputProps,
        ...field,
        onChange: handleChange,
      }}
    />
  );
};

export default FormattedAutosuggestField;
