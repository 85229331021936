export enum ApiUrl {
  AuthLogin = '/auth/login/',
  AuthRegister = '/auth/register/',
  AuthUser = '/auth/user/',
  TokenRefresh = '/auth/token/',
  PasswordReset = '/auth/password/reset/',
  PasswordResetConfirm = '/auth/password/reset/confirm/',
  Documents = '/documents/',
  DocumentSignS3 = '/documents/sign-s3/',
  Document = '/documents/:documentId/',
  DocumentWordCount = '/documents/:documentId?/count-words/',
  DocumentProcess = '/documents/:documentId?/process/',
  PaymentsConfig = '/payments/config/',
  Pricing = '/pricing/',
  Orders = '/orders/',
  Order = '/orders/:orderToken/',
  OrderCheckout = '/orders/:orderToken/checkout/',
}

export enum ExternalLink {
  TermsAndConditions = 'https://kontroluje.me/obchodni-podminky/',
  PrivacyPolicy = 'https://kontroluje.me/ochrana-osobnich-udaju/',
}
