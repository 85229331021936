import classNames from 'classnames';
import FormError from './FormError';

export type FormGroupProps = {
  error?: React.ReactNode;
  className?: string;
};

const FormGroup: React.FC<FormGroupProps> = ({
  children,
  className,
  error,
}) => (
  <div
    className={classNames('ui-form__field g-col-12', className, {
      'ui-form__field--error': !!error && error != null,
    })}
  >
    {children}
    {error && <FormError>{error}</FormError>}
  </div>
);

export default FormGroup;
