import { useFormik } from 'formik';
import FormAction from '@app/components/FormAction';
import FormError from '@app/components/FormError';
import FormGroup from '@app/components/FormGroup';
import FormLabel from '@app/components/FormLabel';
import { ForgotPasswordRequest } from '@app/types/ForgotPasswordRequest';
import { ForgotPasswordSchema } from './consts';
import { FormattedMessage, useIntl } from 'react-intl';
import React from 'react';
import messages from './messages';

export type ForgotPasswordFormProps = {
  onSubmit: (values: ForgotPasswordRequest) => void;
  disabled?: boolean;
  error?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
};

const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({
  onSubmit,
  disabled,
  error,
}) => {
  const { formatMessage } = useIntl();
  const formik = useFormik<ForgotPasswordRequest>({
    initialValues: {
      email: '',
    },
    validationSchema: ForgotPasswordSchema,
    onSubmit,
  });

  return (
    <form
      className="c-form ui-form ui-color--navy-01"
      autoComplete="off"
      onSubmit={formik.handleSubmit}
    >
      <div className="g-row">
        <FormGroup
          className="g-gutter-bottom-0-75"
          error={
            formik.touched.email &&
            formik.errors.email &&
            formatMessage({ id: formik.errors.email })
          }
        >
          <FormLabel htmlFor="email">
            <FormattedMessage {...messages.emailLabel} />
          </FormLabel>
          <input
            className="f-field__control"
            placeholder={formatMessage(messages.emailPlaceholder)}
            type="email"
            disabled={disabled}
            {...formik.getFieldProps('email')}
          />
        </FormGroup>

        <FormAction className="g-gutter-top-1">
          <button
            type="submit"
            className="c-button c-button--primary c-button--to-right"
            disabled={disabled}
          >
            <span className="c-button__label">
              <FormattedMessage {...messages.submitButton} />
            </span>
          </button>
        </FormAction>

        {error && <FormError error={error?.response?.data} />}
      </div>
    </form>
  );
};

export default ForgotPasswordForm;
