import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import PhoneInput from 'react-phone-number-input';
import FormAction from '@app/components/FormAction';
import FormError from '@app/components/FormError';
import FormGroup from '@app/components/FormGroup';
import FormLabel from '@app/components/FormLabel';
import { RegisterRequest } from '@app/types/RegisterRequest';
import { SignupBaseSchema, SignupSchema } from './consts';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import { UserType } from '@app/types/UserType';
import Select from '../Select';

type SignUpFormProps = {
  onSubmit: (values: RegisterRequest) => void;
  disabled?: boolean;
  error?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  compact?: boolean;
  onLoginClick?: () => void;
};

const validate = (values: RegisterRequest) => {
  if (values.password1 !== values.password2) {
    return { password2: messages.password2NotMatch.id };
  }
};

const SignUpForm: React.FC<SignUpFormProps> = ({
  onSubmit,
  disabled,
  error,
  compact,
  onLoginClick,
}) => {
  const { formatMessage } = useIntl();
  const formik = useFormik<RegisterRequest>({
    initialValues: {
      type: '',
      first_name: '',
      last_name: '',
      phone: '',
      email: '',
      password1: '',
      password2: '',
      is_marketing_agree: true,
      is_terms_privacy_agree: false,
    },
    validationSchema: compact ? SignupBaseSchema : SignupSchema,
    validate: compact ? undefined : validate,
    onSubmit,
  });

  const { setFieldError } = formik;

  useEffect(() => {
    if (error?.response?.data?.email) {
      setFieldError('email', messages.emailExists.id);
    }
  }, [error, setFieldError]);

  return (
    <form
      className="c-form ui-form ui-color--navy-01"
      autoComplete="off"
      onSubmit={formik.handleSubmit}
    >
      <div className="g-row">
        {!compact && (
          <>
            <FormGroup
              className="g-gutter-bottom-1"
              error={
                formik.touched.type &&
                formik.errors.type &&
                formatMessage({ id: formik.errors.type })
              }
            >
              <FormLabel htmlFor="type">
                <FormattedMessage {...messages.typeLabel} />
              </FormLabel>
              <Select
                disabled={disabled}
                placeholder={formatMessage(messages.typePlaceholder)}
                {...formik.getFieldProps('type')}
              >
                {Object.values(UserType).map((userType) => (
                  <option key={userType} value={userType}>
                    {formatMessage({ id: `userType.${userType}` })}
                  </option>
                ))}
              </Select>
            </FormGroup>

            <FormGroup
              className="g-gutter-bottom-1"
              error={
                formik.touched.first_name &&
                formik.errors.first_name &&
                formatMessage({ id: formik.errors.first_name })
              }
            >
              <FormLabel htmlFor="first_name">
                <FormattedMessage {...messages.firstNameLabel} />
              </FormLabel>
              <input
                className="f-field__control"
                placeholder={formatMessage(messages.firstNamePlaceholder)}
                type="text"
                disabled={disabled}
                {...formik.getFieldProps('first_name')}
              />
            </FormGroup>

            <FormGroup
              className="g-gutter-bottom-1"
              error={
                formik.touched.last_name &&
                formik.errors.last_name &&
                formatMessage({ id: formik.errors.last_name })
              }
            >
              <FormLabel htmlFor="last_name">
                <FormattedMessage {...messages.lastNameLabel} />
              </FormLabel>
              <input
                className="f-field__control"
                placeholder={formatMessage(messages.lastNamePlaceholder)}
                type="text"
                disabled={disabled}
                {...formik.getFieldProps('last_name')}
              />
            </FormGroup>

            <FormGroup
              className="g-gutter-bottom-1"
              error={
                formik.touched.phone &&
                formik.errors.phone &&
                formatMessage({ id: formik.errors.phone })
              }
            >
              <FormLabel htmlFor="phone">
                <FormattedMessage {...messages.phoneLabel} />
              </FormLabel>
              <PhoneInput
                placeholder={formatMessage(messages.phonePlaceholder)}
                disabled={disabled}
                {...formik.getFieldProps('phone')}
                onChange={(value) => formik.setFieldValue('phone', value)}
                international
                defaultCountry="CZ"
                addInternationalOption={false}
              />
            </FormGroup>
          </>
        )}

        <FormGroup
          className="g-gutter-bottom-1"
          error={
            formik.touched.email &&
            formik.errors.email &&
            formatMessage(
              {
                id: formik.errors.email,
              },
              {
                a: function a(chunks: React.ReactNode) {
                  return (
                    <a
                      className="ui-link-basic-underlined"
                      onClick={onLoginClick}
                    >
                      {chunks}
                    </a>
                  );
                },
              },
            )
          }
        >
          <FormLabel htmlFor="email">
            <FormattedMessage {...messages.emailLabel} />
          </FormLabel>
          <input
            className="f-field__control"
            placeholder={formatMessage(messages.emailPlaceholder)}
            type="email"
            disabled={disabled}
            {...formik.getFieldProps('email')}
          />
        </FormGroup>

        <FormGroup
          className="g-gutter-bottom-1"
          error={
            formik.touched.password1 &&
            formik.errors.password1 &&
            formatMessage({ id: formik.errors.password1 })
          }
        >
          <FormLabel htmlFor="password1">
            <FormattedMessage {...messages.password1Label} />
          </FormLabel>
          <input
            className="f-field__control"
            placeholder={formatMessage(messages.password1Placeholder)}
            type="password"
            disabled={disabled}
            {...formik.getFieldProps('password1')}
          />
        </FormGroup>

        {!compact && (
          <FormGroup
            className="g-gutter-bottom-1"
            error={
              formik.touched.password2 &&
              formik.errors.password2 &&
              formatMessage({ id: formik.errors.password2 })
            }
          >
            <FormLabel htmlFor="password2">
              <FormattedMessage {...messages.password2Label} />
            </FormLabel>
            <input
              className="f-field__control"
              placeholder={formatMessage(messages.password2Placeholder)}
              type="password"
              disabled={disabled}
              {...formik.getFieldProps('password2')}
            />
          </FormGroup>
        )}

        <FormGroup
          error={
            formik.touched.is_marketing_agree &&
            formik.errors.is_marketing_agree &&
            formatMessage({ id: formik.errors.is_marketing_agree })
          }
          className="g-gutter-bottom-1-50"
        >
          <label className="f-field__checkbox">
            <input
              type="checkbox"
              {...formik.getFieldProps('is_marketing_agree')}
              checked={!formik.values.is_marketing_agree}
              onChange={(e) =>
                formik.setFieldValue('is_marketing_agree', !e.target.checked)
              }
            />
            <span className="checkmark"></span>
            <FormattedMessage {...messages.marketingAgreeLabel} />
          </label>
        </FormGroup>

        <FormGroup
          error={
            formik.touched.is_terms_privacy_agree &&
            formik.errors.is_terms_privacy_agree &&
            formatMessage({ id: formik.errors.is_terms_privacy_agree })
          }
        >
          <label className="f-field__checkbox">
            <input
              type="checkbox"
              {...formik.getFieldProps('is_terms_privacy_agree')}
            />
            <span className="checkmark"></span>
            <FormattedMessage {...messages.termsAndPrivacyAgreeLabel} />
          </label>
        </FormGroup>

        <FormAction className="g-gutter-top-1-50">
          <button
            type="submit"
            className="c-button c-button--to-right c-button--primary"
            disabled={disabled}
          >
            <span className="c-button__label">
              <FormattedMessage {...messages.submitButton} />
            </span>
          </button>
        </FormAction>

        {error && !error?.response?.data?.email && (
          <FormError error={error?.response?.data} />
        )}
      </div>
    </form>
  );
};

export default SignUpForm;
