import { useFormik } from 'formik';
import FormAction from '@app/components/FormAction';
import FormGroup from '@app/components/FormGroup';
import FormLabel from '@app/components/FormLabel';
import { ResetPasswordRequest } from '@app/types/ResetPasswordRequest';
import { ResetPasswordSchema } from './consts';
import { FormattedMessage, useIntl } from 'react-intl';
import React from 'react';
import messages from './messages';
import ErrorMessage from '@app/components/ErrorMessage';

export type ResetPasswordFormProps = {
  onSubmit: (
    values: Pick<ResetPasswordRequest, 'new_password1' | 'new_password2'>,
  ) => void;
  disabled?: boolean;
  error?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
};

const validate = (
  values: Pick<ResetPasswordRequest, 'new_password1' | 'new_password2'>,
) => {
  if (values.new_password1 !== values.new_password2) {
    return { new_password2: messages.newPassword2NotMatch.id };
  }
};

const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({
  onSubmit,
  disabled,
  error,
}) => {
  const { formatMessage } = useIntl();
  const formik = useFormik<
    Pick<ResetPasswordRequest, 'new_password1' | 'new_password2'>
  >({
    initialValues: {
      new_password1: '',
      new_password2: '',
    },
    validationSchema: ResetPasswordSchema,
    validate,
    onSubmit,
  });

  return (
    <form
      className="c-form ui-form ui-color--navy-01"
      autoComplete="off"
      onSubmit={formik.handleSubmit}
    >
      <div className="g-row">
        <FormGroup
          className="g-gutter-bottom-1"
          error={
            formik.touched.new_password1 &&
            formik.errors.new_password1 &&
            formatMessage({ id: formik.errors.new_password1 })
          }
        >
          <FormLabel htmlFor="new_password1">
            <FormattedMessage {...messages.newPassword1Label} />
          </FormLabel>
          <input
            className="f-field__control"
            placeholder={formatMessage(messages.newPassword1Placeholder)}
            type="password"
            disabled={disabled}
            {...formik.getFieldProps('new_password1')}
          />
        </FormGroup>

        <FormGroup
          className="g-gutter-bottom-1"
          error={
            formik.touched.new_password2 &&
            formik.errors.new_password2 &&
            formatMessage({ id: formik.errors.new_password2 })
          }
        >
          <FormLabel htmlFor="new_password2">
            <FormattedMessage {...messages.newPassword2Label} />
          </FormLabel>
          <input
            className="f-field__control"
            placeholder={formatMessage(messages.newPassword2Placeholder)}
            type="password"
            disabled={disabled}
            {...formik.getFieldProps('new_password2')}
          />
        </FormGroup>

        <FormAction className="g-gutter-top-1">
          <div className="t-body-2 ui-color--blue-05">
            <FormattedMessage {...messages.havingProblems} />
            <br />
            <a
              href="mailto:support@kontroluje.me"
              className="ui-link-basic-underlined ui-color--navy-02"
            >
              <FormattedMessage {...messages.getHelp} />
            </a>
          </div>
          <button
            type="submit"
            className="c-button c-button--primary"
            disabled={disabled}
          >
            <span className="c-button__label">
              <FormattedMessage {...messages.submitButton} />
            </span>
          </button>

          {error && <ErrorMessage error={error} />}
        </FormAction>
      </div>
    </form>
  );
};

export default ResetPasswordForm;
