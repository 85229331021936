import FormInputField from '@app/components/FormInputField';
import FormSelectField from '@app/components/FormSelectField';
import FormSwitchField from '@app/components/FormSwitchField';
import { UserType } from '@app/types/UserType';
import { formatName } from '@app/utils/formatName';
import { useField, useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';

export interface UserTypeFieldProps {
  name?: string;
  disabled?: boolean;
}

const UserTypeField: React.FC<UserTypeFieldProps> = ({
  name: namePrefix,
  disabled,
}) => {
  const { formatMessage } = useIntl();
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [{ value: isBusiness }] = useField<boolean>(
    formatName(namePrefix, 'is_business'),
  );

  useEffect(() => {
    if (isBusiness) {
      setFieldTouched(formatName(namePrefix, 'company_name'));
      setFieldTouched(formatName(namePrefix, 'vat_number'));
    } else {
      setFieldValue(formatName(namePrefix, 'company_name'), '');
      setFieldValue(formatName(namePrefix, 'vat_number'), '');
    }
  }, [setFieldValue, setFieldTouched, namePrefix, isBusiness]);

  return (
    <div className="ui-form ui-form--inner-account-settings">
      <FormSwitchField
        className="g-gutter-bottom-0-75"
        name={formatName(namePrefix, 'is_business')}
        label={formatMessage(messages.isBusinessLabel)}
        disabled={disabled}
      />

      {isBusiness && (
        <>
          <FormInputField
            className="g-gutter-bottom-1"
            name={formatName(namePrefix, 'company_name')}
            label={formatMessage(messages.companyNameLabel)}
            placeholder={formatMessage(messages.companyNamePlaceholder)}
            disabled={disabled}
          />

          <FormInputField
            className="g-gutter-bottom-1"
            name={formatName(namePrefix, 'vat_number')}
            label={formatMessage(messages.vatNumberLabel)}
            placeholder={formatMessage(messages.vatNumberPlaceholder)}
            disabled={disabled}
          />
        </>
      )}

      <FormSelectField
        className="g-gutter-bottom-1"
        name={formatName(namePrefix, 'type')}
        label={formatMessage(messages.userTypeLabel)}
        placeholder={formatMessage(messages.userTypePlaceholder)}
        disabled={disabled}
      >
        {Object.values(UserType).map((userType) => (
          <option key={userType} value={userType}>
            {formatMessage({ id: `userType.${userType}` })}
          </option>
        ))}
      </FormSelectField>

      <FormInputField
        className="g-gutter-bottom-1"
        name={formatName(namePrefix, 'street_address')}
        label={formatMessage(messages.streetAddressLabel)}
        placeholder={formatMessage(messages.streetAddressLabel)}
        disabled={disabled}
      />

      <FormInputField
        className="g-gutter-bottom-1"
        name={formatName(namePrefix, 'city')}
        label={formatMessage(messages.cityLabel)}
        placeholder={formatMessage(messages.cityPlaceholder)}
        disabled={disabled}
      />

      <FormInputField
        className="g-gutter-bottom-1"
        name={formatName(namePrefix, 'postcode')}
        label={formatMessage(messages.postcodeLabel)}
        placeholder={formatMessage(messages.postcodePlaceholder)}
        disabled={disabled}
      />

      <FormInputField
        className="g-gutter-bottom-1"
        name={formatName(namePrefix, 'country')}
        label={formatMessage(messages.countryLabel)}
        placeholder={formatMessage(messages.countryPlaceholder)}
        disabled={disabled}
      />
    </div>
  );
};

export default UserTypeField;
