import { APIError, parseAPIErrors } from '@app/utils/parseAPIError';

export type FormErrorProps = {
  error?: APIError<unknown>;
};

const FormError: React.FC<FormErrorProps> = ({ children, error }) => (
  <ul className="parsley-errors-list filled" aria-hidden="false">
    {children && <li className="parsley-custom-error-message">{children}</li>}
    {error &&
      parseAPIErrors(error).map((e) => (
        <li key={e} className="parsley-custom-error-message">
          {e}
        </li>
      ))}
  </ul>
);

export default FormError;
