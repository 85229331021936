import { ReactComponent as StatusInQueueIcon } from '@app/assets/icon-status-in-queue.svg';
import { ReactComponent as StatusInProgressIcon } from '@app/assets/icon-status-in-progress.svg';
import { ReactComponent as StatusFeedbackRequiredIcon } from '@app/assets/icon-status-failure.svg';
import { ReactComponent as StatusCompleteIcon } from '@app/assets/icon-status-complete.svg';
import { DocumentStatus } from '@app/types/internal';

export type DocumentStatusIconProps = {
  status?: DocumentStatus;
};

const icons = {
  [DocumentStatus.InQueue]: <StatusInQueueIcon />,
  [DocumentStatus.InProgress]: <StatusInProgressIcon />,
  [DocumentStatus.FeedbackRequired]: <StatusFeedbackRequiredIcon />,
  [DocumentStatus.Completed]: <StatusCompleteIcon />,
};

const DocumentStatusIcon: React.FC<DocumentStatusIconProps> = ({ status }) =>
  (status && icons[status]) || <></>;

export default DocumentStatusIcon;
