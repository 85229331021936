import classNames from 'classnames';
import { useMemo } from 'react';

export interface ActionPopupFeedbackProps {
  message: string;
  buttonText?: string;
  variant?: 'success' | 'error';
  className?: string;
  buttonType?: React.ButtonHTMLAttributes<unknown>['type'];
  disabled?: boolean;
  onButtonClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const ActionPopupFeedback: React.FC<ActionPopupFeedbackProps> = ({
  variant,
  className,
  message,
  buttonText,
  buttonType,
  disabled,
  onButtonClick,
}) => {
  const wrapperClassName = useMemo(
    () =>
      classNames(
        'c-action-popup-feedback',
        {
          'c-action-popup-feedback--error': variant === 'error',
          'c-action-popup-feedback--success': variant === 'success',
        },
        className,
      ),
    [className, variant],
  );

  return (
    <div className={wrapperClassName}>
      <div className="l-inner c-action-popup-feedback__inner">
        <p className="c-action-popup-feedback__text">{message}</p>
        <div className="c-action-popup-feedback__action">
          {buttonText && (
            <button
              className={classNames('c-button c-button--primary', {
                'c-button--primary-inverted': variant === 'success',
              })}
              type={buttonType}
              onClick={onButtonClick}
              disabled={disabled}
            >
              <span className="c-button__label">{buttonText}</span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ActionPopupFeedback;
