import { ExternalLink } from '@app/consts';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

const Footer: React.FC = () => (
  <footer className="l-app-footer">
    <div className="l-inner">
      <div className="c-app-footer">
        <div className="c-app-footer__bottom">
          <ul className="c-app-footer__bottom__list ui-color--navy-03 t-text-6">
            <li className="c-app-footer__bottom__item c-app-footer__bottom__item--static">
              <p>&#xA9; Kontroluje.me 2020</p>
            </li>

            <li className="c-app-footer__bottom__item">
              <a
                href={ExternalLink.PrivacyPolicy}
                target="_blank"
                rel="noreferrer"
              >
                <span>
                  <FormattedMessage {...messages.privacyPolicy} />
                </span>
              </a>
            </li>

            <li className="c-app-footer__bottom__item">
              <a
                href={ExternalLink.TermsAndConditions}
                target="_blank"
                rel="noreferrer"
              >
                <span>
                  <FormattedMessage {...messages.terms} />
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
