import FormInputField from '@app/components/FormInputField';
import FormPhoneInputField from '@app/components/FormPhoneInputField';
import FormSelectField from '@app/components/FormSelectField';
import { formatName } from '@app/utils/formatName';
import React from 'react';
import { useIntl } from 'react-intl';
import { listTimeZones } from 'timezone-support';
import messages from './messages';

export interface PersonalInformationFieldProps {
  name?: string;
  disabled?: boolean;
}

const timezones = listTimeZones();

const PersonalInformationField: React.FC<PersonalInformationFieldProps> = ({
  name: namePrefix,
  disabled,
  children,
}) => {
  const { formatMessage } = useIntl();

  return (
    <div className="ui-form ui-form--inner-account-settings">
      <FormInputField
        className="g-gutter-bottom-1"
        name={formatName(namePrefix, 'first_name')}
        label={formatMessage(messages.firstNameLabel)}
        placeholder={formatMessage(messages.firstNamePlaceholder)}
        disabled={disabled}
      />

      <FormInputField
        className="g-gutter-bottom-1"
        name={formatName(namePrefix, 'last_name')}
        label={formatMessage(messages.lastNameLabel)}
        placeholder={formatMessage(messages.lastNamePlaceholder)}
        disabled={disabled}
      />

      <FormInputField
        className="g-gutter-bottom-1"
        name={formatName(namePrefix, 'email')}
        label={formatMessage(messages.emailLabel)}
        readOnly
      />

      <FormPhoneInputField
        className="g-gutter-bottom-1"
        name={formatName(namePrefix, 'phone')}
        label={formatMessage(messages.phoneLabel)}
        placeholder={formatMessage(messages.phonePlaceholder)}
        disabled={disabled}
      />

      <FormSelectField
        className="g-gutter-bottom-1"
        name={formatName(namePrefix, 'timezone')}
        label={formatMessage(messages.timezoneLabel)}
        placeholder={formatMessage(messages.timezonePlaceholder)}
        disabled={disabled}
      >
        {timezones.map((timezone) => (
          <option key={timezone} value={timezone}>
            {timezone}
          </option>
        ))}
      </FormSelectField>

      {children}
    </div>
  );
};

export default PersonalInformationField;
