import { defineMessages } from 'react-intl';

export const scope = 'pages.ForgotPasswordPage';

export default defineMessages({
  formTitle: {
    id: `${scope}.formTitle`,
    defaultMessage: 'Forgot Password',
  },
  formDescription: {
    id: `${scope}.formDescription`,
    defaultMessage:
      'Enter the email associated with your account and we’ll send an email with instructions to reset your password.',
  },
  signUp: {
    id: `${scope}.signUp`,
    defaultMessage: 'Sign up',
  },
  emailLabel: {
    id: `${scope}.emailLabel`,
    defaultMessage: 'E-mail',
  },
  emailPlaceholder: {
    id: `${scope}.emailPlaceholder`,
    defaultMessage: 'E-mail',
  },
  emailRequired: {
    id: `${scope}.emailRequired`,
    defaultMessage: 'Please enter your email address',
  },
  emailInvalid: {
    id: `${scope}.emailInvalid`,
    defaultMessage: 'Please enter a valid email address',
  },
  submitButton: {
    id: `${scope}.submitButton`,
    defaultMessage: 'Send me instructions',
  },
  successHeader: {
    id: `${scope}.successHeader`,
    defaultMessage: 'Instructions sent!',
  },
  successMessage1: {
    id: `${scope}.successMessage1`,
    defaultMessage:
      'Instructions for resetting your password have been sent to <b>{email}</b>.',
  },
  successMessage2: {
    id: `${scope}.successMessage2`,
    defaultMessage:
      'You’ll receive this email within 5 minutes. Be sure to check your spam folder, too.',
  },
  backToLogin: {
    id: `${scope}.backToLogin`,
    defaultMessage: 'Back to login',
  },
});
