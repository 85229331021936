import classNames from 'classnames';

export type SwitchProps = React.HTMLProps<HTMLInputElement>;

const Switch: React.FC<SwitchProps> = ({
  className,
  disabled,
  children,
  ...inputProps
}) => (
  <div className={classNames('ui-form__field', className)}>
    <label
      className={classNames('f-field__switch', {
        'f-field__switch--disabled': disabled,
      })}
    >
      <input type="checkbox" disabled={disabled} {...inputProps} />
      <span className="checkmark"></span>
      <span className="text">{children}</span>
    </label>
  </div>
);

export default Switch;
