import { ReactComponent as CloseIcon } from '@app/assets/icon-close-btn.svg';
import classNames from 'classnames';
import { ReactComponent as FailureIcon } from '@app/assets/icon-status-failure.svg';
import { Document } from '@app/types/Document';
import { OrderStatus } from '@app/types/OrderStatus';
import { DocumentStatus } from '@app/types/DocumentStatus';
import { FormattedMessage } from 'react-intl';
import React, { useCallback, useRef } from 'react';
import DocumentStatusIcon from './DocumentStatusIcon';
import DocumentIcon from './DocumentIcon';
import useOnClickOutside from '@app/hooks/useOnClickOutside';
import FormattedRelativeDateTime from '@app/components/FormattedRelativeDateTime';

export type DocumentDrawerProps = {
  document?: Document | null;
  show?: boolean;
  onHide?: () => void;
  error: React.ReactNode;
};

const DocumentDrawer: React.FC<DocumentDrawerProps> = ({
  document,
  show,
  onHide,
  error,
  children,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const close = useCallback(() => onHide?.(), [onHide]);

  useOnClickOutside(containerRef, close);

  return (
    <div
      ref={containerRef}
      className={classNames('l-sec-sidebar l-sec-sidebar--order-details', {
        'is-opened': show,
      })}
    >
      <div className="c-sidebar-order-details">
        <div className="c-sidebar-order-details__top">
          <div className="c-sidebar-order-details__icon">
            <DocumentIcon format={document?.format} />
          </div>
          <div className="c-sidebar-order-details__title">
            {document?.subject || document?.file_name}
          </div>
          <div className="c-sidebar-order-details__cls-btn" onClick={close}>
            <CloseIcon />
          </div>
        </div>

        {document?.order_document?.order?.status === OrderStatus.Paid && (
          <div
            className={classNames('c-sidebar-order-details__status', {
              'c-sidebar-order-details__status--in-queue':
                document?.status === DocumentStatus.InQueue,
              'c-sidebar-order-details__status--in-progress':
                document?.status === DocumentStatus.InProgress,
              'c-sidebar-order-details__status--failure':
                document?.status === DocumentStatus.FeedbackRequired,
              'c-sidebar-order-details__status--completed':
                document?.status === DocumentStatus.Completed,
            })}
          >
            <div className="c-sidebar-order-details__status-value">
              <div
                className={classNames('c-status', {
                  'c-status--in-queue':
                    document?.status === DocumentStatus.InQueue,
                  'c-status--in-progress':
                    document?.status === DocumentStatus.InProgress,
                  'c-status--failure':
                    document?.status === DocumentStatus.FeedbackRequired,
                  'c-status--success':
                    document?.status === DocumentStatus.Completed,
                })}
              >
                <span className="c-status__icon ui-icon-wrapper c-icon-wrapper">
                  <DocumentStatusIcon status={document?.status} />
                </span>
                <span className="c-status__text">
                  <FormattedMessage id={`documentStatus.${document.status}`} />
                </span>
              </div>
            </div>
            <div className="c-sidebar-order-details__status-date">
              <FormattedRelativeDateTime value={document?.modified_at} />
            </div>
          </div>
        )}

        {error && (
          <div className="c-sidebar-order-details__status c-sidebar-order-details__status--failure">
            <div className="c-sidebar-order-details__status-value">
              <div className="c-status c-status--failure">
                <span className="c-status__icon ui-icon-wrapper c-icon-wrapper">
                  <FailureIcon />
                </span>
                <span className="c-status__text">{error}</span>
              </div>
            </div>
          </div>
        )}

        {children}
      </div>
    </div>
  );
};

export default DocumentDrawer;
