import { Order } from '@app/types/Order';
import { FormattedMessage, useIntl } from 'react-intl';
import { ReactComponent as IconLockTiny } from '@app/assets/icon-lock-tiny.svg';
import messages from './messages';
import useAuth from '@app/hooks/useAuth';
import React, { useContext } from 'react';
import { CheckoutContext } from '@app/providers/checkout-provider';
import FormError from '@app/components/FormError';
import FormattedRelativeDateTime from '@app/components/FormattedRelativeDateTime';
import InfoTooltip from '@app/components/InfoTooltip';
import { useFormikContext } from 'formik';

export type OrderSummaryProps = {
  order?: Order;
  disabled?: boolean;
  error?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
};

const OrderSummary: React.FC<OrderSummaryProps> = ({
  order,
  error,
  disabled,
}) => {
  const { isValid } = useFormikContext();
  const { formatNumber, formatMessage } = useIntl();
  const { user } = useAuth();
  const checkout = useContext(CheckoutContext);

  const canSubmit = Object.values(checkout.lines).every(
    ({ words_count_loading, words_count_error, pricing }) =>
      !words_count_loading &&
      !words_count_error &&
      pricing !== undefined &&
      pricing.id > 0,
  );

  const minimumNotReached = Object.values(checkout.lines).find(
    ({ words_count, pricing }) =>
      words_count !== null &&
      pricing?.document_min_length !== undefined &&
      words_count < pricing.document_min_length,
  );

  return (
    <div className="c-your-price-box">
      <h3 className="c-your-price-box__title t-headline-2-desktop t-headline-3-mobile">
        <FormattedMessage {...messages.yourPrice} />
      </h3>
      <div className="c-your-price-box__content">
        {order?.order_documents?.map((order_document) => (
          <div key={order_document.id}>
            <p className="c-your-price-box__filename t-body-2 t-bold">
              {order_document.document?.file_name}
              {order_document.document?.file_extension}
            </p>

            <ul className="c-your-price-box__list">
              {order_document.translation && (
                <li className="c-your-price-box__item">
                  <span className="c-your-price-box__activity">
                    <FormattedMessage {...messages.serviceTranslation} />
                  </span>
                  <span className="c-your-price-box__cost t-body-2">
                    {`${formatNumber(
                      checkout.lines[order_document.id]?.translation_amount,
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      },
                    )} ${checkout.currency}`}
                  </span>
                </li>
              )}

              {order_document.proofreading && (
                <li className="c-your-price-box__item">
                  <span className="c-your-price-box__activity">
                    <FormattedMessage {...messages.serviceProofreading} />
                  </span>
                  <span className="c-your-price-box__cost t-body-2">
                    {`${formatNumber(
                      checkout.lines[order_document.id]?.proofreading_amount,
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      },
                    )} ${checkout.currency}`}
                  </span>
                </li>
              )}

              {order_document.editing && (
                <li className="c-your-price-box__item">
                  <span className="c-your-price-box__activity">
                    <FormattedMessage {...messages.serviceEditing} />
                  </span>
                  <span className="c-your-price-box__cost t-body-2">
                    {`${formatNumber(
                      checkout.lines[order_document.id]?.editing_amount,
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      },
                    )} ${checkout.currency}`}
                  </span>
                </li>
              )}

              {order_document.formatting && (
                <li className="c-your-price-box__item">
                  <span className="c-your-price-box__activity">
                    <FormattedMessage {...messages.serviceFormatting} />
                  </span>
                  <span className="c-your-price-box__cost t-body-2">
                    {`${formatNumber(
                      checkout.lines[order_document.id]?.formatting_amount,
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      },
                    )} ${checkout.currency}`}
                  </span>
                </li>
              )}

              {order_document.plagiarism && (
                <li className="c-your-price-box__item">
                  <span className="c-your-price-box__activity">
                    <FormattedMessage {...messages.servicePlagiarism} />
                  </span>
                  <span className="c-your-price-box__cost t-body-2">
                    {`${formatNumber(
                      checkout.lines[order_document.id]?.plagiarism_amount,
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      },
                    )} ${checkout.currency}`}
                  </span>
                </li>
              )}

              {order_document.delivery &&
                checkout.lines[order_document.id]?.delivery_amount > 0 && (
                  <li className="c-your-price-box__item">
                    <span className="c-your-price-box__activity">
                      <FormattedMessage
                        id={`delivery.${order_document.delivery}`}
                      />
                      {` ${formatMessage(messages.delivery)}`}
                    </span>
                    <span className="c-your-price-box__cost t-body-2">
                      {`${formatNumber(
                        checkout.lines[order_document.id]?.delivery_amount,
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        },
                      )} ${checkout.currency}`}
                    </span>
                  </li>
                )}
            </ul>
          </div>
        ))}

        <div className="c-summary-esimated-delivery">
          <div className="c-summary-esimated-delivery__title">
            <FormattedMessage {...messages.estimatedDeliveryLabel} />
          </div>
          <ul className="c-summary-esimated-delivery__list">
            <li className="c-summary-esimated-delivery__item">
              <div className="c-summary-esimated-delivery__value">
                {checkout.delivery_date && (
                  <FormattedRelativeDateTime value={checkout.delivery_date} />
                )}
              </div>
              <div className="c-summary-esimated-delivery__icon">
                <InfoTooltip>
                  <FormattedMessage {...messages.deliveryDateTooltipText} />
                </InfoTooltip>
              </div>
            </li>
          </ul>
        </div>

        <div className="c-your-price-box__footer">
          <div className="c-your-price-box__total-label">
            <FormattedMessage {...messages.paymentDueLabel} />
            {minimumNotReached && (
              <div className="c-your-price-box__total-tooltip">
                <InfoTooltip>
                  <FormattedMessage
                    {...messages.paymentDueTooltipText}
                    values={{
                      words: minimumNotReached.pricing?.document_min_length,
                    }}
                  />
                </InfoTooltip>
              </div>
            )}
          </div>
          <div className="c-your-price-box__total-price">
            <strong>
              {`${formatNumber(checkout.total_amount, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })} `}
            </strong>
            {checkout.currency}
            <p className="c-your-price-box__total-price-tax-info">
              <FormattedMessage {...messages.paymentPriceTaxInfoLabel} />
            </p>
          </div>
        </div>

        {!user?.is_premium && (
          <div className="c-your-price-box__pay-by-invoice">
            <p className="t-body-2 t-bold g-gutter-top-2">
              <FormattedMessage {...messages.payByInvoiceLine1} />
            </p>
            <p className="t-body-2">
              <FormattedMessage
                {...messages.payByInvoiceLine2}
                values={{
                  aBookACall: function aBookACall(chunks: React.ReactNode) {
                    return (
                      <a
                        target="_blank"
                        rel="noreferrer"
                        className="ui-link-basic-underlined"
                        href="https://calendly.com/kontrolujeme/registrace"
                      >
                        {chunks}
                      </a>
                    );
                  },
                  aEmail: function aEmail(chunks: React.ReactNode) {
                    return (
                      <a
                        className="ui-link-basic-underlined"
                        href="mailto:support@kontroluje.me?subject=Platba%20fakturou"
                      >
                        {chunks}
                      </a>
                    );
                  },
                }}
              />
            </p>
          </div>
        )}
        <div className="c-your-price-box__action">
          {user?.is_premium ? (
            <button
              type="submit"
              className="c-button c-button--secondary"
              disabled={disabled || !canSubmit}
            >
              <FormattedMessage {...messages.confirmOrder} />
            </button>
          ) : (
            <button
              type="submit"
              className="c-button c-button--secondary"
              disabled={disabled || !canSubmit}
            >
              <span className="ui-icon-wrapper">
                <IconLockTiny />
              </span>
              <span className="c-button__label">
                <FormattedMessage {...messages.proceedToPayment} />
              </span>
            </button>
          )}

          {error && <FormError error={error?.response?.data} />}

          {!isValid && (
            <FormError error={formatMessage(messages.formInvalidMessage)} />
          )}

          <p className="g-gutter-top-2 t-body-3 t-lineheight-13 ui-color--navy-blue-01">
            <FormattedMessage
              {...messages.agreementCopy}
              values={{
                aTerms: function aTerms(chunks: React.ReactNode) {
                  return (
                    <a
                      target="_blank"
                      className="ui-link-basic-underlined"
                      href="#"
                    >
                      {chunks}
                    </a>
                  );
                },
                aQuality: function aQuality(chunks: React.ReactNode) {
                  return (
                    <a
                      target="_blank"
                      className="ui-link-basic-underlined"
                      href="#"
                    >
                      {chunks}
                    </a>
                  );
                },
              }}
            />
          </p>
          <p className="g-gutter-top-1-50 t-body-3 t-lineheight-13 ui-color--blue-06">
            <FormattedMessage {...messages.stripeCardPaymentInfo} />
          </p>
        </div>
      </div>
    </div>
  );
};

export default OrderSummary;
