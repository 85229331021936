export enum RouterUrl {
  Home = '/',
  SignIn = '/sign-in',
  SignUp = '/sign-up',
  SignUpConfirm = '/sign-up/confirm',
  AccountSettings = '/account-settings',
  AddDocument = '/add-document',
  OrderSubmit = '/order/:orderToken/submit',
  OrderConfirm = '/order/:orderToken/confirm',
  PaymentDeclined = '/order/:orderToken/failed',
  Documents = '/documents/:documentId?/:action?',
  ForgotPassword = '/forgot-password',
  ResetPassword = '/reset-password/:uid?/:token?',
}
