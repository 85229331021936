import { defineMessages } from 'react-intl';

export const scope = 'pages.SignUpPage';

export default defineMessages({
  formTitle: {
    id: `${scope}.formTitle`,
    defaultMessage: 'Sign up',
  },
});
