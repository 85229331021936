import { useMutation } from 'react-query';
import React from 'react';
import ForgotPasswordForm from './ForgotPasswordForm';
import { authApi } from '@app/utils/api';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import { Helmet } from 'react-helmet-async';
import { ApiUrl } from '@app/consts';
import { ForgotPasswordRequest } from '@app/types/ForgotPasswordRequest';
import SuccessResult from './SuccessResult';

const ForgotPasswordPage: React.FC = () => {
  const { formatMessage } = useIntl();

  const mutation = useMutation((values: ForgotPasswordRequest) =>
    authApi.post<void>(ApiUrl.PasswordReset, values).then(() => values),
  );

  return (
    <main className="l-main">
      <Helmet>
        <title>{formatMessage(messages.formTitle)}</title>
        <body className="ui-theme--authorization-page" />
      </Helmet>
      <div
        className="l-sec
    g-gutter-top-2-tablet
    g-gutter-top-3-desktop
    g-gutter-bottom-2-mobile
    g-gutter-bottom-2-tablet
    g-gutter-bottom-3-desktop"
      >
        <div className="l-inner">
          <div className="g-row">
            <div
              className="g-col-12
                g-col-tablet-sm-8
                g-col-tablet-6
                g-col-offset-tablet-sm-2
                g-col-offset-tablet-3
                g-col-offset-desktop-3"
            >
              {mutation.isSuccess ? (
                <SuccessResult email={mutation.data?.email} />
              ) : (
                <div
                  className="l-inner-whitebox-auth
                    ui-bg--light-03
                    g-gutter-top-4-desktop
                    g-gutter-top-4-tablet
                    g-gutter-top-8-mobile
                    g-gutter-bottom-4-desktop
                    g-gutter-bottom-4-tablet
                    g-gutter-bottom-4-mobile"
                >
                  <h2 className="t-headline-2 g-gutter-bottom-2">
                    <FormattedMessage {...messages.formTitle} />
                  </h2>

                  <p className="g-gutter-bottom-2">
                    <FormattedMessage {...messages.formDescription} />
                  </p>

                  <ForgotPasswordForm
                    onSubmit={mutation.mutate}
                    disabled={mutation.isLoading}
                    error={mutation.error}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ForgotPasswordPage;
