import classNames from 'classnames';

export type FormActionProps = {
  className?: string;
};

const FormAction: React.FC<FormActionProps> = ({ children, className }) => (
  <div className={classNames('c-form__action g-col-12', className)}>
    {children}
  </div>
);

export default FormAction;
