import classNames from 'classnames';
import { ReactComponent as CloseRoundedIcon } from '@app/assets/icon-close-rounded.svg';

export type ModalProps = {
  show?: boolean;
  modalTheme?: string;
  onHide?: () => void;
};

const Modal: React.FC<ModalProps> = ({
  show,
  onHide,
  children,
  modalTheme,
}) => (
  <div className={classNames('l-modal', { 'is-visible': show })}>
    <div className="l-modal__inner">
      <div className="l-modal__content">
        <div className="l-modal__content-inner">
          <div
            className={`l-modal__whitebox-content${
              modalTheme ? ` l-modal__whitebox-content--${modalTheme}` : ''
            }`}
          >
            <div className="l-modal__close" onClick={onHide}>
              <CloseRoundedIcon />
            </div>
            {children}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Modal;
