import { ApiUrl } from '@app/consts';
import { RouterUrl } from '@app/navigation/consts';
import { Order } from '@app/types/Order';
import { api } from '@app/utils/api';
import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import { generatePath, Link, useParams } from 'react-router-dom';
import messages from './messages';
import { serviceMessages } from '@app/messages';
import FormattedRelativeDateTime from '@app/components/FormattedRelativeDateTime';

const OrderConfirmPage: React.FC = () => {
  const { formatMessage, formatNumber } = useIntl();
  const { orderToken } = useParams<{ orderToken: string }>();

  const { data } = useQuery(['order', orderToken], () =>
    api.get<Order>(generatePath(ApiUrl.Order, { orderToken })),
  );

  const order = data?.data;

  return (
    <main className="l-main">
      <Helmet>
        <title>{formatMessage(messages.title)}</title>
      </Helmet>
      <div
        className="l-sec
    g-gutter-top-4-tablet
    g-gutter-top-4-desktop
    g-gutter-bottom-2-mobile
    g-gutter-bottom-4-tablet
    g-gutter-bottom-4-desktop"
      >
        <div className="l-inner">
          <div className="g-row">
            <div
              className="g-col-12
                g-col-tablet-8
                g-col-offset-tablet-2
                g-col-offset-desktop-2"
            >
              <h2
                className="t-headline-2
                    g-gutter-bottom-0-50"
              >
                <FormattedMessage {...messages.title} />
              </h2>
              <p
                className="g-gutter-bottom-3-desktop
                    g-gutter-bottom-2-tablet
                    g-gutter-bottom-2-mobile
                    t-body-1
                    ui-color--blue-05"
              >
                <FormattedMessage {...messages.message} />
              </p>
              <div className="">
                <div className="c-order-summary">
                  <div className="c-order-summary__header">
                    <span className="t-body-1 ui-color--blue-05">
                      <FormattedMessage {...messages.orderIdLabel} />
                    </span>
                    <p className="c-order-summary__id t-headline-2-desktop t-headline-3-mobile">
                      {order?.id}
                    </p>
                  </div>
                  <div className="c-order-summary__content">
                    <div className="g-row">
                      <div
                        className="g-col-12
                    g-col-tablet-6
                    g-gutter-bottom-1-desktop
                    g-gutter-bottom-0-50-tablet
                    g-gutter-bottom-0-50-mobile"
                      >
                        <span className="t-text-3 ui-color--blue-05">
                          <FormattedMessage {...messages.wordCountLabel} />
                        </span>
                      </div>
                      <div
                        className="g-col-12
                    g-col-tablet-6
                    g-gutter-bottom-1-desktop
                    g-gutter-bottom-1-50-tablet
                    g-gutter-bottom-1-50-mobile"
                      >
                        <ul className="c-order-summary__list">
                          {order?.order_documents?.map(({ document }) => (
                            <li
                              key={document?.id}
                              className="c-order-summary__item"
                            >
                              {document?.words_count}
                            </li>
                          ))}
                        </ul>
                      </div>

                      <div
                        className="g-col-12
                    g-col-tablet-6
                    g-gutter-bottom-1-desktop
                    g-gutter-bottom-0-50-tablet
                    g-gutter-bottom-0-50-mobile"
                      >
                        <span className="t-text-3 ui-color--blue-05">
                          <FormattedMessage {...messages.nameLabel} />
                        </span>
                      </div>
                      <div
                        className="g-col-12
                    g-col-tablet-6
                    g-gutter-bottom-1-desktop
                    g-gutter-bottom-1-50-tablet
                    g-gutter-bottom-1-50-mobile"
                      >
                        <ul className="c-order-summary__list">
                          {order?.order_documents?.map(({ document }) => (
                            <li
                              key={document?.id}
                              className="c-order-summary__item"
                            >
                              {document?.file_name}
                              {document?.file_extension}
                            </li>
                          ))}
                        </ul>
                      </div>

                      <div
                        className="g-col-12
                    g-col-tablet-6
                    g-gutter-bottom-1-desktop
                    g-gutter-bottom-0-50-tablet
                    g-gutter-bottom-0-50-mobile"
                      >
                        <span className="t-text-3 ui-color--blue-05">
                          <FormattedMessage {...messages.servicesLabel} />
                        </span>
                      </div>
                      <div
                        className="g-col-12
                    g-col-tablet-6
                    g-gutter-bottom-1-desktop
                    g-gutter-bottom-1-50-tablet
                    g-gutter-bottom-1-50-mobile"
                      >
                        <ul className="c-order-summary__list">
                          {order?.order_documents?.map(
                            ({
                              id,
                              proofreading,
                              editing,
                              formatting,
                              plagiarism,
                              translation
                            }) => (
                              <Fragment key={id}>
                                {proofreading && (
                                  <li className="c-order-summary__item">
                                    <FormattedMessage
                                      {...serviceMessages.proofreading}
                                    />
                                  </li>
                                )}
                                {editing && (
                                  <li className="c-order-summary__item">
                                    <FormattedMessage
                                      {...serviceMessages.editing}
                                    />
                                  </li>
                                )}
                                {formatting && (
                                  <li className="c-order-summary__item">
                                    <FormattedMessage
                                      {...serviceMessages.formatting}
                                    />
                                  </li>
                                )}
                                {plagiarism && (
                                  <li className="c-order-summary__item">
                                    <FormattedMessage
                                      {...serviceMessages.plagiarism}
                                    />
                                  </li>
                                )}
                                {translation && (
                                  <li className="c-order-summary__item">
                                    <FormattedMessage
                                      {...serviceMessages.translation}
                                    />
                                  </li>
                                )}
                              </Fragment>
                            ),
                          )}
                        </ul>
                      </div>

                      <div
                        className="g-col-12
                    g-col-tablet-6
                    g-gutter-bottom-1-desktop
                    g-gutter-bottom-0-50-tablet
                    g-gutter-bottom-0-50-mobile"
                      >
                        <span className="t-text-3 ui-color--blue-05">
                          <FormattedMessage {...messages.deliveryLabel} />
                        </span>
                      </div>
                      <div
                        className="g-col-12
                    g-col-tablet-6
                    g-gutter-bottom-1-desktop
                    g-gutter-bottom-1-50-tablet
                    g-gutter-bottom-1-50-mobile"
                      >
                        <ul className="c-order-summary__list">
                          <li className="c-order-summary__item">
                            {order?.order_documents?.[0]
                              ?.delivery_expected_at ? (
                              <FormattedRelativeDateTime
                                value={
                                  order.order_documents[0].delivery_expected_at
                                }
                              />
                            ) : (
                              '-'
                            )}
                            {` (${
                              order?.order_documents
                                ?.map(({ delivery }) =>
                                  formatMessage({ id: `delivery.${delivery}` }),
                                )
                                .join(', ') || '-'
                            })`}
                          </li>
                        </ul>
                      </div>

                      <div
                        className="g-col-12
                g-col-tablet-6
                g-gutter-bottom-0-50-tablet
                g-gutter-bottom-0-50-mobile"
                      >
                        <span className="t-body-1 ui-color--blue-05">
                          <FormattedMessage {...messages.costLabel} />
                        </span>
                      </div>
                      <div
                        className="g-col-12
                g-col-tablet-6"
                      >
                        <ul className="c-order-summary__list">
                          <li className="c-order-summary__item">
                            <span className="t-body-1 ui-color--blueberry-simpan-in-01">
                              {order?.total_amount
                                ? `${formatNumber(order?.total_amount, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })} ${order?.currency}`
                                : '-'}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="c-order-summary__footer">
                    <Link
                      to={generatePath(RouterUrl.Documents)}
                      className="ui-link-navy"
                    >
                      <FormattedMessage {...messages.continueToMyDocuments} />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default OrderConfirmPage;
