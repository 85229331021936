import ilustration from '@app/assets/ilustration-01.svg';
import { RouterUrl } from '@app/navigation/consts';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import messages from './messages';

const EmptyResult: React.FC = () => (
  <div
    className="l-sec
l-sec--app-complete-sign-up
g-gutter-top-4-mobile
g-gutter-top-4-tablet
g-gutter-bottom-4-mobile
g-gutter-bottom-4-tablet
g-gutter-bottom-5-desktop"
  >
    <div className="l-inner">
      <div className="g-row">
        <div className="g-col-12">
          <h1 className="t-center t-headline-2-desktop t-headline-3-mobile">
            <FormattedMessage {...messages.emptyTitle} />
          </h1>
        </div>
        <div
          className="g-col-12
        g-gutter-top-4-desktop
        g-gutter-top-4-tablet
        g-gutter-top-2-mobile"
        >
          <div className="c-complete-sign-up">
            <figure className="c-complete-sign-up__img">
              <img src={ilustration} alt="Ilustration" />
            </figure>
            <p className="c-complete-sign-up__desc t-body-1">
              <FormattedMessage {...messages.emptyMessage} />
            </p>
            <div className="c-complete-sign-up__action">
              <Link
                to={RouterUrl.AddDocument}
                className="c-button c-button--primary"
              >
                <span className="c-button__label">
                  <FormattedMessage {...messages.uploadDocument} />
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default EmptyResult;
