import { Field, FieldProps, useField } from 'formik';
import { ReactComponent as FormatAppWordTinyIcon } from '@app/assets/format-app-word-tiny-icon.svg';
import { ReactComponent as IconLoaderTiny } from '@app/assets/icon-loader-tiny.svg';
import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import { OrderDocument } from '@app/types/OrderDocument';
import DocumentField from './DocumentField';
import InfoTooltip from '@app/components/InfoTooltip';
import { DeliveryType } from '@app/types/DeliveryType';
import { Link } from 'react-router-dom';
import { RouterUrl } from '@app/navigation/consts';
import { CheckoutContext } from '@app/providers/checkout-provider';
import FormattedRelativeDateTime from '@app/components/FormattedRelativeDateTime';

export type DocumentFieldProps = {
  disabled?: boolean;
  name: string;
};

const OrderDocumentField: React.FC<DocumentFieldProps> = ({
  disabled,
  name,
}) => {
  const { formatNumber } = useIntl();
  const [{ value }] = useField<OrderDocument>(name);
  const { lines, currency } = useContext(CheckoutContext);
  const {
    pricing,
    total_amount,
    words_count,
    words_count_loading,
    words_count_error,
    proofreading_amount,
    proofreading_basic_amount,
    editing_amount,
    formatting_amount,
    plagiarism_amount,
    translation_amount,
    delivery_regular_amount,
    delivery_regular_date,
    delivery_express_amount,
    delivery_express_date,
    delivery_rapid_amount,
    delivery_rapid_date,
  } = lines[value.id];

  return (
    <div className="c-single-file-order is-opened">
      <div className="c-single-file-order__inner">
        <div className="c-single-file-order__top">
          <div className="c-single-file-order__icon">
            <FormatAppWordTinyIcon />
          </div>
          <div className="c-single-file-order__info">
            <p className="c-single-file-order__file-name t-body-2">
              {value?.document?.file_name}
              {value?.document?.file_extension}
            </p>
            <div className="c-single-file-order__words t-body-1">
              {words_count_loading ? (
                <div className="c-order-words-progress">
                  <div className="c-order-words-progress__icon">
                    <IconLoaderTiny />
                  </div>
                  <div className="c-order-words-progress__text t-body-3 ui-color--blue-06">
                    <FormattedMessage {...messages.wordsCountingMessage} />
                  </div>
                </div>
              ) : words_count_error ? (
                <span className="c-status--failure">
                  <FormattedMessage {...messages.wordsCountingError} />
                </span>
              ) : (
                <FormattedMessage
                  {...messages.wordsCount}
                  values={{
                    wordsCount: words_count,
                    strong: function strong(chunks: React.ReactNode) {
                      return <strong>{chunks}</strong>;
                    },
                  }}
                />
              )}
            </div>
            <p className="c-single-file-order__price t-body-1">
              {`${formatNumber(total_amount, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })} ${currency}`}
            </p>
          </div>
        </div>

        <div className="c-single-file-order__content">
          <div className="c-app-file-section-option g-gutter-top-3 g-gutter-bottom-4">
            <div className="c-app-file-section-option__heading">
              <span className="c-app-file-section-option__title">
                <FormattedMessage {...messages.servicesLabel} />
              </span>
            </div>
            <div className="c-app-file-section-option__content">
              <div className="f-field__basic-service-options-wrapper ui-form">
                <label className="f-field__basic-service f-field__basic-service--option">
                  <Field name={`${name}.translation`}>
                    {({ field, form: { setFieldValue } }: FieldProps) => (
                      <input
                        type="radio"
                        disabled={disabled}
                        {...field}
                        checked={!field.value}
                        onChange={({ target: { checked } }) => {
                          setFieldValue(field.name, !checked);
                          setFieldValue(`${name}.document.language`, '');
                          if (checked) {
                            setFieldValue(`${name}.proofreading`, true);
                          }
                        }}
                      />
                    )}
                  </Field>
                  <span className="f-field__basic-service__content">
                    <span className="checkmark"></span>
                    <span className="title">
                      <FormattedMessage {...messages.serviceProofreading} />
                    </span>
                    <span className="content">
                      <FormattedMessage
                        {...messages.serviceProofreadingDescription}
                      />
                    </span>
                    <span className="price">
                      <span className="price-label">
                        {words_count_loading && (
                          <FormattedMessage
                            {...messages.basicServicePriceLabel}
                          />
                        )}
                      </span>
                      <span className="price-value">
                        {words_count_loading && pricing
                          ? `${formatNumber(pricing.proofreading_price, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })} ${currency}`
                          : `${formatNumber(proofreading_basic_amount, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })} ${currency}`}
                        {words_count_loading && (
                          <span className="price-value-unit">
                            <FormattedMessage
                              {...messages.basicServicePriceUnit}
                            />
                          </span>
                        )}
                      </span>
                    </span>
                  </span>
                </label>

                <label className="f-field__basic-service f-field__basic-service--option">
                  <Field name={`${name}.translation`}>
                    {({ field, form: { setFieldValue } }: FieldProps) => (
                      <input
                        type="radio"
                        disabled={disabled}
                        {...field}
                        checked={field.value}
                        onChange={({ target: { checked } }) => {
                          setFieldValue(field.name, checked);
                          setFieldValue(`${name}.document.language`, '');
                          if (checked) {
                            if (!value.editing) {
                              setFieldValue(`${name}.proofreading`, false);
                            }
                            if (value.delivery === DeliveryType.Rapid) {
                              setFieldValue(
                                `${name}.delivery`,
                                DeliveryType.Express,
                              );
                            }
                          }
                        }}
                      />
                    )}
                  </Field>
                  <span className="f-field__basic-service__content">
                    <span className="checkmark"></span>
                    <span className="title">
                      <FormattedMessage {...messages.serviceTranslation} />
                    </span>
                    <span className="content">
                      <FormattedMessage
                        {...messages.serviceTranslationDescription}
                      />
                    </span>
                    <span className="price">
                      <span className="price-label">
                        {words_count_loading && (
                          <FormattedMessage
                            {...messages.basicServicePriceLabel}
                          />
                        )}
                      </span>
                      <span className="price-value">
                        {words_count_loading && pricing
                          ? `${formatNumber(pricing.translation_price, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })} ${currency}`
                          : `${formatNumber(translation_amount, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })} ${currency}`}
                        {words_count_loading && (
                          <span className="price-value-unit">
                            <FormattedMessage
                              {...messages.basicServicePriceUnit}
                            />
                          </span>
                        )}
                      </span>
                    </span>
                  </span>
                </label>
              </div>
            </div>
          </div>

          <span className="c-app-file-section-option-separator"></span>

          <div className="c-app-file-section-option g-gutter-top-3 g-gutter-bottom-4">
            <div className="c-app-file-section-option__heading">
              <span className="c-app-file-section-option__title">
                <FormattedMessage {...messages.briefYourEditor} />
              </span>
            </div>
            <DocumentField
              name={`${name}.document`}
              disabled={disabled}
              variant={value.translation ? 'translation' : 'proofreading'}
            />
          </div>

          <span className="c-app-file-section-option-separator"></span>

          <div className="c-app-file-section-option g-gutter-top-3 g-gutter-bottom-4">
            <div className="c-app-file-section-option__heading">
              <span className="c-app-file-section-option__title">
                <FormattedMessage {...messages.additionalServicesLabel} />
              </span>
            </div>
            <div className="c-app-file-section-option__content">
              {value.translation && (
                <p className="g-gutter-bottom-3 t-body-2">
                  <FormattedMessage
                    {...messages.translationAdditionalServices}
                  />
                </p>
              )}
              <div className="ui-form">
                <div className="c-pricing-services js-pricing-services">
                  {value.translation && (
                    <div className="c-pricing-services__option">
                      <div className="c-pricing-services__label">
                        <label className="f-field__checkbox">
                          <FormattedMessage {...messages.serviceProofreading} />
                          <Field name={`${name}.proofreading`}>
                            {({ field }: FieldProps) => (
                              <input
                                type="checkbox"
                                disabled={disabled || value.editing}
                                {...field}
                                checked={field.value}
                              />
                            )}
                          </Field>
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <InfoTooltip>
                        <FormattedMessage
                          {...messages.serviceProofreadingDescription}
                          values={{
                            a: function u(chunks: React.ReactNode) {
                              return (
                                <a
                                  href="mailto:support@kontroluje.me"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <i>{chunks}</i>
                                </a>
                              );
                            },
                          }}
                        />
                      </InfoTooltip>

                      <div className="c-pricing-services__cost">
                        {words_count_loading && pricing ? (
                          <FormattedMessage
                            {...messages.priceFrom}
                            values={{
                              price: formatNumber(pricing.proofreading_price, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }),
                              currency,
                            }}
                          />
                        ) : (
                          `${formatNumber(proofreading_amount, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })} ${currency}`
                        )}
                      </div>
                    </div>
                  )}

                  <div className="c-pricing-services__option js-pricing-services-option-editing">
                    <div className="c-pricing-services__label">
                      <label className="f-field__checkbox">
                        <FormattedMessage {...messages.serviceEditing} />
                        <Field name={`${name}.editing`}>
                          {({ field, form: { setFieldValue } }: FieldProps) => (
                            <input
                              type="checkbox"
                              disabled={disabled}
                              {...field}
                              checked={field.value}
                              onChange={({ target: { checked } }) => {
                                setFieldValue(field.name, checked);
                                if (checked) {
                                  setFieldValue(`${name}.proofreading`, true);
                                }
                              }}
                            />
                          )}
                        </Field>
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    <InfoTooltip>
                      <FormattedMessage
                        {...messages.serviceEditingDescription}
                        values={{
                          a: function u(chunks: React.ReactNode) {
                            return (
                              <a
                                href="mailto:support@kontroluje.me"
                                target="_blank"
                                rel="noreferrer"
                              >
                                <i>{chunks}</i>
                              </a>
                            );
                          },
                        }}
                      />
                    </InfoTooltip>

                    <div className="c-pricing-services__cost">
                      {words_count_loading && pricing ? (
                        <FormattedMessage
                          {...messages.priceFrom}
                          values={{
                            price: formatNumber(pricing.editing_price, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }),
                            currency,
                          }}
                        />
                      ) : (
                        `${formatNumber(editing_amount, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })} ${currency}`
                      )}
                    </div>
                  </div>

                  <div className="c-pricing-services__option js-pricing-services-option-formatting">
                    <div className="c-pricing-services__label">
                      <label className="f-field__checkbox">
                        <FormattedMessage {...messages.serviceFormatting} />
                        <Field name={`${name}.formatting`}>
                          {({ field }: FieldProps) => (
                            <input
                              type="checkbox"
                              disabled={disabled}
                              {...field}
                              checked={field.value}
                            />
                          )}
                        </Field>
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    <InfoTooltip>
                      <FormattedMessage
                        {...messages.serviceFormattingDescription}
                        values={{
                          a: function u(chunks: React.ReactNode) {
                            return (
                              <a
                                href="mailto:support@kontroluje.me"
                                target="_blank"
                                rel="noreferrer"
                              >
                                <i>{chunks}</i>
                              </a>
                            );
                          },
                        }}
                      />
                    </InfoTooltip>

                    <div className="c-pricing-services__cost">
                      {words_count_loading && pricing ? (
                        <FormattedMessage
                          {...messages.priceFrom}
                          values={{
                            price: formatNumber(pricing.formatting_price, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }),
                            currency: currency,
                          }}
                        />
                      ) : (
                        `${formatNumber(formatting_amount, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })} ${currency}`
                      )}
                    </div>
                  </div>

                  <div className="c-pricing-services__option js-pricing-services-option-plagiarism">
                    <div className="c-pricing-services__label">
                      <label className="f-field__checkbox">
                        <FormattedMessage {...messages.servicePlagiarism} />
                        <Field name={`${name}.plagiarism`}>
                          {({ field }: FieldProps) => (
                            <input
                              type="checkbox"
                              disabled={disabled}
                              {...field}
                              checked={field.value}
                            />
                          )}
                        </Field>
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    <InfoTooltip>
                      <FormattedMessage
                        {...messages.servicePlagiarismDescription}
                        values={{
                          a: function u(chunks: React.ReactNode) {
                            return (
                              <a
                                href="mailto:support@kontroluje.me"
                                target="_blank"
                                rel="noreferrer"
                              >
                                <i>{chunks}</i>
                              </a>
                            );
                          },
                        }}
                      />
                    </InfoTooltip>

                    <div className="c-pricing-services__cost">
                      {words_count_loading && pricing ? (
                        <FormattedMessage
                          {...messages.priceFrom}
                          values={{
                            price: formatNumber(pricing.plagiarism_price, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }),
                            currency: currency,
                          }}
                        />
                      ) : (
                        `${formatNumber(plagiarism_amount, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })} ${currency}`
                      )}
                    </div>
                  </div>

                  <div className="c-pricing-services__option js-pricing-services-option-premium"></div>
                </div>
              </div>
            </div>
          </div>

          <span className="c-app-file-section-option-separator"></span>

          <div className="c-app-file-section-option g-gutter-top-3 g-gutter-bottom-3">
            <div className="c-app-file-section-option__heading">
              <span className="c-app-file-section-option__title">
                <FormattedMessage {...messages.deliveryLabel} />
              </span>
            </div>
            <div className="c-app-file-section-option__content">
              <p className="g-gutter-bottom-3 t-body-2">
                <FormattedMessage {...messages.deliveryDescription} />
              </p>
              <div className="f-field__delivery-selector-wrapper ui-form">
                <label className="f-field__delivery-selector">
                  <Field name={`${name}.delivery`}>
                    {({ field }: FieldProps) => (
                      <input
                        type="radio"
                        className="f-field__delivery-selector__input"
                        disabled={disabled}
                        {...field}
                        value={DeliveryType.Regular}
                        checked={field.value === DeliveryType.Regular}
                      />
                    )}
                  </Field>
                  <span className="f-field__delivery-selector__content">
                    <span className="checkmark"></span>
                    <span className="title">
                      <FormattedMessage {...messages.deliveryRegularLabel} />
                    </span>
                    <span className="label">
                      {delivery_regular_date && (
                        <FormattedRelativeDateTime
                          value={delivery_regular_date}
                        />
                      )}
                    </span>
                    {!words_count_loading && (
                      <span className="price">
                        <strong>
                          {`${formatNumber(delivery_regular_amount, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })} `}
                        </strong>
                        {currency}
                      </span>
                    )}
                  </span>
                </label>

                <label className="f-field__delivery-selector">
                  <Field name={`${name}.delivery`}>
                    {({ field }: FieldProps) => (
                      <input
                        type="radio"
                        className="f-field__delivery-selector__input"
                        disabled={disabled}
                        {...field}
                        value={DeliveryType.Express}
                        checked={field.value === DeliveryType.Express}
                      />
                    )}
                  </Field>
                  <span className="f-field__delivery-selector__content">
                    <span className="checkmark"></span>
                    <span className="title">
                      <FormattedMessage {...messages.deliveryExpressLabel} />
                    </span>
                    <span className="label">
                      {delivery_express_date && (
                        <FormattedRelativeDateTime
                          value={delivery_express_date}
                        />
                      )}
                    </span>
                    {!words_count_loading && (
                      <span className="price">
                        <strong>
                          {`${formatNumber(delivery_express_amount, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })} `}
                        </strong>
                        {currency}
                      </span>
                    )}
                  </span>
                </label>

                <label className="f-field__delivery-selector">
                  {!value.translation && (
                    <>
                      <Field name={`${name}.delivery`}>
                        {({ field }: FieldProps) => (
                          <input
                            type="radio"
                            className="f-field__delivery-selector__input"
                            disabled={disabled}
                            {...field}
                            value={DeliveryType.Rapid}
                            checked={field.value === DeliveryType.Rapid}
                          />
                        )}
                      </Field>
                      <span className="f-field__delivery-selector__content">
                        <span className="checkmark"></span>
                        <span className="title">
                          <FormattedMessage {...messages.deliveryRapidLabel} />
                        </span>
                        <span className="label">
                          {delivery_rapid_date && (
                            <FormattedRelativeDateTime
                              value={delivery_rapid_date}
                            />
                          )}
                        </span>
                        {!words_count_loading && (
                          <span className="price">
                            <strong>
                              {`${formatNumber(delivery_rapid_amount, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })} `}
                            </strong>
                            {currency}
                          </span>
                        )}
                      </span>
                    </>
                  )}
                </label>
              </div>
            </div>
          </div>

          <span className="c-app-file-section-option-separator"></span>

          <div className="g-gutter-top-2">
            <Link
              to={RouterUrl.AddDocument}
              className="c-button c-button--remove"
            >
              <span className="c-button__label">
                <FormattedMessage {...messages.removeDocument} />
              </span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDocumentField;
