import { Field, FieldInputProps, FieldProps } from 'formik';
import { Document } from '@app/types/Document';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import FormGroup from '@app/components/FormGroup';
import FormLabel from '@app/components/FormLabel';
import KeywordsField from './KeywordsField';
import FormattedAutosuggestField from './FormattedAutosuggestField';
import {
  languageSuggestionsProofreading,
  languageSuggestionsTranslations,
  styleSuggestions,
  typeSuggestions,
} from './consts';
import { UserType } from '@app/types/UserType';
import Select from '@app/components/Select';

export type DocumentFieldProps = Partial<FieldInputProps<Document>> & {
  disabled?: boolean;
  name: string;
  variant?: 'translation' | 'proofreading';
};

const DocumentField: React.FC<DocumentFieldProps> = ({
  name,
  disabled,
  variant = 'proofreading',
}) => {
  const { formatMessage } = useIntl();

  return (
    <div className="c-app-file-section-option__content">
      <div className="c-form ui-form ui-form--app-upload-form ui-color--navy-01">
        <div className="g-row">
          <Field name={`${name}.user_type`}>
            {({ field, meta: { touched, error } }: FieldProps) => (
              <FormGroup
                className="g-gutter-bottom-1"
                error={touched && error && formatMessage({ id: error })}
              >
                <FormLabel htmlFor={field.name}>
                  <FormattedMessage {...messages.userTypeLabel} />
                </FormLabel>
                <Select
                  disabled={disabled}
                  placeholder={formatMessage(messages.userTypePlaceholder)}
                  {...field}
                >
                  {Object.values(UserType).map((userType) => (
                    <option key={userType} value={userType}>
                      {formatMessage({ id: `userType.${userType}` })}
                    </option>
                  ))}
                </Select>
              </FormGroup>
            )}
          </Field>

          <Field name={`${name}.type`}>
            {({ field, meta: { touched, error } }: FieldProps) => (
              <FormGroup
                className="g-gutter-bottom-1"
                error={touched && error && formatMessage({ id: error })}
              >
                <FormLabel htmlFor={field.name}>
                  <FormattedMessage {...messages.typeLabel} />
                </FormLabel>
                <FormattedAutosuggestField
                  {...field}
                  disabled={disabled}
                  className={'f-field__control'}
                  placeholder={formatMessage(messages.typePlaceholder)}
                  suggestions={typeSuggestions}
                />
              </FormGroup>
            )}
          </Field>

          <Field name={`${name}.subject`}>
            {({ field, meta: { touched, error } }: FieldProps) => (
              <FormGroup
                className="g-gutter-bottom-1"
                error={touched && error && formatMessage({ id: error })}
              >
                <FormLabel htmlFor={field.name}>
                  <FormattedMessage {...messages.subjectLabel} />
                </FormLabel>
                <input
                  className="f-field__control"
                  type="text"
                  placeholder={formatMessage(messages.subjectPlaceholder)}
                  disabled={disabled}
                  {...field}
                />
              </FormGroup>
            )}
          </Field>

          <Field name={`${name}.language`}>
            {({ field, meta: { touched, error } }: FieldProps) => (
              <FormGroup
                className="g-gutter-bottom-1"
                error={touched && error && formatMessage({ id: error })}
              >
                <FormLabel htmlFor={field.name}>
                  <FormattedMessage {...messages.languageLabel} />
                </FormLabel>
                <Select
                  disabled={disabled}
                  placeholder={formatMessage(
                    messages.subjectLanguagePlaceholder,
                  )}
                  {...field}
                >
                  {(variant === 'translation'
                    ? languageSuggestionsTranslations
                    : languageSuggestionsProofreading
                  ).map((suggestion) => (
                    <option
                      key={suggestion.id}
                      value={formatMessage(suggestion)}
                    >
                      {formatMessage(suggestion)}
                    </option>
                  ))}
                </Select>
              </FormGroup>
            )}
          </Field>

          <Field name={`${name}.style`}>
            {({ field, meta: { touched, error } }: FieldProps) => (
              <FormGroup
                className="g-gutter-bottom-1"
                error={touched && error && formatMessage({ id: error })}
              >
                <FormLabel htmlFor={field.name}>
                  <FormattedMessage {...messages.styleLabel} />
                </FormLabel>
                <Select
                  {...field}
                  disabled={disabled}
                  placeholder={formatMessage(messages.stylePlaceholder)}
                >
                  {styleSuggestions.map((suggestion) => (
                    <option
                      key={suggestion.id}
                      value={suggestion.defaultMessage}
                    >
                      {formatMessage(suggestion)}
                    </option>
                  ))}
                </Select>
              </FormGroup>
            )}
          </Field>

          <Field name={`${name}.keywords`}>
            {({ field, meta: { touched, error } }: FieldProps) => (
              <FormGroup
                className="f-field--textarea g-gutter-bottom-0-50"
                error={touched && error && formatMessage({ id: error })}
              >
                <FormLabel htmlFor={field.name}>
                  <FormattedMessage {...messages.keywordsLabel} />
                </FormLabel>
                <KeywordsField
                  rows={1}
                  placeholder={formatMessage(messages.keywordsPlaceholder)}
                  disabled={disabled}
                  {...field}
                ></KeywordsField>
              </FormGroup>
            )}
          </Field>

          <Field name={`${name}.notes`}>
            {({ field, meta: { touched, error } }: FieldProps) => (
              <FormGroup
                className="f-field--textarea g-gutter-bottom-2"
                error={touched && error && formatMessage({ id: error })}
              >
                <FormLabel htmlFor={field.name}>
                  <FormattedMessage {...messages.notesLabel} />
                </FormLabel>
                <textarea
                  rows={1}
                  placeholder={formatMessage(messages.notesPlaceholder)}
                  disabled={disabled}
                  {...field}
                ></textarea>
              </FormGroup>
            )}
          </Field>
        </div>
      </div>
    </div>
  );
};

export default DocumentField;
