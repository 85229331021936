import { useField } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import PhoneInput, { PhoneInputProps } from 'react-phone-number-input';
import FormGroup from './FormGroup';
import FormLabel from './FormLabel';

export interface FormPhoneInputFieldProps
  extends Omit<PhoneInputProps, 'value' | 'onChange'> {
  name: string;
  label?: string;
}

const FormPhoneInputField: React.FC<FormPhoneInputFieldProps> = ({
  name,
  className,
  label,
  ...inputProps
}) => {
  const [field, { touched, error }, form] = useField(name);
  const { formatMessage } = useIntl();

  return (
    <FormGroup
      className={className}
      error={touched && error && formatMessage({ id: error })}
    >
      <FormLabel htmlFor={field.name}>{label}</FormLabel>
      <PhoneInput
        {...field}
        onChange={(value) => form.setValue(value)}
        international
        defaultCountry="CZ"
        addInternationalOption={false}
        {...inputProps}
      />
    </FormGroup>
  );
};

export default FormPhoneInputField;
