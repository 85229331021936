import { FieldInputProps, useField } from 'formik';
import { TextareaHTMLAttributes, useCallback, useEffect, useRef } from 'react';

export type KeywordsFieldProps = TextareaHTMLAttributes<string> &
  FieldInputProps<string[]> & {
    disabled?: boolean;
  };

const KeywordsField: React.FC<KeywordsFieldProps> = ({
  name,
  disabled,
  placeholder,
}) => {
  const [field, meta, helpers] = useField(name);
  const ref = useRef<HTMLTextAreaElement>(null);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      helpers.setValue(
        event?.target.value.split(/[\n,]+/).filter((v) => v.length > 0),
      );
    },
    [helpers],
  );

  useEffect(() => {
    if (ref.current) {
      ref.current.value = meta.initialValue;
    }
  }, [meta.initialValue]);

  return (
    <textarea
      ref={ref}
      disabled={disabled}
      name={field.name}
      placeholder={placeholder}
      onBlur={field.onBlur}
      onChange={handleChange}
    ></textarea>
  );
};

export default KeywordsField;
