import * as Yup from 'yup';
import messages from './messages';

export const ResetPasswordSchema = Yup.object().shape({
  new_password1: Yup.string()
    .min(6, messages.newPassword1MinLength.id)
    .max(50, messages.newPassword1MaxLength.id)
    .required(messages.newPassword1Required.id),
  new_password2: Yup.string().required(messages.newPassword2Required.id),
});
