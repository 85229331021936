import { formatRelativeDateTime } from '@app/utils/intl';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

export type FormattedRelativeDateTimeProps = {
  value: string | number | Date | undefined;
};

const FormattedRelativeDateTime: React.FC<FormattedRelativeDateTimeProps> = ({
  value,
}) => {
  const intl = useIntl();
  const formatted = useMemo(() => formatRelativeDateTime(intl, value), [
    intl,
    value,
  ]);

  return <>{formatted}</>;
};

export default FormattedRelativeDateTime;
