import { ReactComponent as StatusCompleteIcon } from '@app/assets/icon-status-complete.svg';
import { RouterUrl } from '@app/navigation/consts';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import messages from './messages';

export type SuccessResultProps = {
  email?: string;
};

const SuccessResult: React.FC<SuccessResultProps> = ({ email }) => (
  <div
    className="l-inner-whitebox-auth
      ui-bg--light-03
      g-gutter-top-4-desktop
      g-gutter-top-4-tablet
      g-gutter-top-8-mobile
      g-gutter-bottom-4-desktop
      g-gutter-bottom-4-tablet
      g-gutter-bottom-4-mobile"
  >
    <StatusCompleteIcon />
    <h2 className="t-headline-2 g-gutter-bottom-2">
      <FormattedMessage {...messages.successHeader} />
    </h2>

    <p className="g-gutter-bottom-2">
      <FormattedMessage
        {...messages.successMessage1}
        values={{
          email,
          b: function b(chunks: React.ReactNode) {
            return <b>{chunks}</b>;
          },
        }}
      />
    </p>

    <p className="g-gutter-bottom-2 ui-color--blue-05">
      <FormattedMessage {...messages.successMessage2} />
    </p>

    <Link to={RouterUrl.SignIn} className="c-button c-button--primary">
      <span className="c-button__label">
        <FormattedMessage {...messages.backToLogin} />
      </span>
    </Link>
  </div>
);

export default SuccessResult;
