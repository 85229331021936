import classNames from 'classnames';
import React, { useState } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { Link } from 'react-scroll';
import messages from './messages';

export interface AccountSettingsNavigationItem {
  message: MessageDescriptor;
  href: string;
}

export interface AccountSettingsNavigationProps {
  items: AccountSettingsNavigationItem[];
}

const AccountSettingsNavigation: React.FC<AccountSettingsNavigationProps> = ({
  items,
}) => {
  const [activeLink, setActiveLink] = useState<string>(items[0]?.href);

  return (
    <div className="c-aside-sticky-navigation">
      <ul className="c-aside-sticky-navigation__list">
        {items.map(({ message, href }) => (
          <li
            key={href}
            className={classNames('c-aside-sticky-navigation__item', {
              'is-active': href === activeLink,
            })}
          >
            <Link
              className="c-aside-sticky-navigation__link"
              to={href}
              spy
              smooth
              onSetActive={setActiveLink}
            >
              <FormattedMessage {...message} />
            </Link>
          </li>
        ))}
      </ul>
      <div className="c-aside-sticky-navigation__bottom">
        <p className="t-body-3 t-bold">
          <FormattedMessage {...messages.doYouHaveAnyQuestions} />
        </p>
        <p className="t-body-3">
          <FormattedMessage
            {...messages.contactSupport}
            values={{
              a: function a(chunks: React.ReactNode) {
                return (
                  <a
                    href="mailto:support@kontroluje.me"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {chunks}
                  </a>
                );
              },
            }}
          />
        </p>
      </div>
    </div>
  );
};

export default AccountSettingsNavigation;
