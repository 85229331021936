import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import messages from './messages';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { ApiUrl } from '@app/consts';
import { api } from '@app/utils/api';
import OrderForm from './OrderForm';
import { Order } from '@app/types/Order';
import { useStripe } from '@stripe/react-stripe-js';
import { RouterUrl } from '@app/navigation/consts';
import { OrderStatus } from '@app/types/OrderStatus';
import React, { useState } from 'react';
import AuthModal from '@app/components/AuthModal';
import useAuth from '@app/hooks/useAuth';

const OrderSubmitPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const stripe = useStripe();
  const history = useHistory();
  const { user } = useAuth();
  const { orderToken } = useParams<{ orderToken: string }>();
  const [authModalVisible, setAuthModalVisible] = useState(false);
  const [order, setOrder] = useState<Order | null>(null);

  const query = useQuery(['order', orderToken], () =>
    api.get<Order>(generatePath(ApiUrl.Order, { orderToken })),
  );

  const mutation = useMutation(
    (order: Order) =>
      order.status !== OrderStatus.Input
        ? Promise.resolve(order)
        : api
            .post<Order>(
              generatePath(ApiUrl.OrderCheckout, {
                orderToken: order.token,
              }),
              order,
            )
            .then(({ data }) => data),
    {
      onSuccess: ({ token, status, payment }) => {
        if (status === OrderStatus.AwaitingPayment && payment != null) {
          const extraData = JSON.parse(payment.extra_data);
          if (stripe && 'session_id' in extraData) {
            stripe.redirectToCheckout({
              sessionId: extraData.session_id,
            });
            return;
          }
        } else if (status === OrderStatus.Paid) {
          history.push(
            generatePath(RouterUrl.OrderConfirm, { orderToken: token }),
          );
          return;
        }
        history.push(
          generatePath(RouterUrl.PaymentDeclined, { orderToken: token }),
        );
      },
    },
  );

  const handleSubmit = (order: Order) => {
    if (user) {
      mutation.mutate(order);
    } else {
      setOrder(order);
      setAuthModalVisible(true);
    }
  };

  const handleAuth = () => {
    if (order) {
      mutation.mutate(order);
    }
    setAuthModalVisible(false);
  };

  return (
    <main className="l-main">
      <Helmet>
        <title>{formatMessage(messages.title)}</title>
        <body className="ui-theme--checkout-page" />
      </Helmet>

      {query.data?.data && (
        <OrderForm
          order={query.data?.data}
          onSubmit={handleSubmit}
          disabled={
            query.isLoading ||
            mutation.isLoading ||
            (query.data?.data && query.data?.data.status !== OrderStatus.Input)
          }
          error={query.error || mutation.error}
        />
      )}

      <AuthModal
        show={authModalVisible}
        onHide={() => setAuthModalVisible(!authModalVisible)}
        onAuth={handleAuth}
      />
    </main>
  );
};

export default OrderSubmitPage;
