import { defineMessages } from 'react-intl';

export const scope = 'documentStatus';

export default defineMessages({
  inQueue: {
    id: `${scope}.in_queue`,
    defaultMessage: 'In queue',
  },
  inProgress: {
    id: `${scope}.in_progress`,
    defaultMessage: 'In progress',
  },
  feedbackRequired: {
    id: `${scope}.feedback_required`,
    defaultMessage: 'Feedback required',
  },
  completed: {
    id: `${scope}.completed`,
    defaultMessage: 'Completed',
  },
});
