import { Document } from '@app/types/Document';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

export type DocumentDetailsProps = {
  document?: Document | null;
};

const DocumentDetails: React.FC<DocumentDetailsProps> = ({ document }) => {
  return (
    <div className="c-sidebar-order-details__full-informations">
      <div className="c-order-overview-informations">
        <ul className="c-order-overview-informations__list">
          <li className="c-order-overview-informations__item">
            <p className="ui-color--navy-04">
              <FormattedMessage {...messages.documentFileLabel} />
            </p>
            <p>
              {document?.file_name}
              {document?.file_extension}
            </p>
          </li>

          <li className="c-order-overview-informations__item">
            <p className="ui-color--navy-04">
              <FormattedMessage {...messages.typeLabel} />
            </p>
            <p>{document?.type || '-'}</p>
          </li>

          <li className="c-order-overview-informations__item">
            <p className="ui-color--navy-04">
              <FormattedMessage {...messages.subjectLabel} />
            </p>
            <p>{document?.subject || '-'}</p>
          </li>

          <li className="c-order-overview-informations__item">
            <p className="ui-color--navy-04">
              <FormattedMessage {...messages.languageLabel} />
            </p>
            <p>{document?.language || '-'}</p>
          </li>

          <li className="c-order-overview-informations__item">
            <p className="ui-color--navy-04">
              <FormattedMessage {...messages.styleLabel} />
            </p>
            <p>{document?.style || '-'}</p>
          </li>

          <li className="c-order-overview-informations__item">
            <p className="ui-color--navy-04">
              <FormattedMessage {...messages.keywordsLabel} />
            </p>
            <p>{document?.keywords?.join(', ') || '-'}</p>
          </li>

          <li className="c-order-overview-informations__item">
            <p className="ui-color--navy-04">
              <FormattedMessage {...messages.notesLabel} />
            </p>
            <p>{document?.notes || '-'}</p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default DocumentDetails;
