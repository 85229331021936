import * as Yup from 'yup';
import messages from './messages';

export const SignupBaseSchema = Yup.object().shape({
  email: Yup.string()
    .email(messages.emailInvalid.id)
    .required(messages.emailRequired.id),
  password1: Yup.string()
    .min(6, messages.password1MinLength.id)
    .max(50, messages.password1MaxLength.id)
    .required(messages.password1Required.id),
  is_marketing_agree: Yup.boolean(),
  is_terms_privacy_agree: Yup.boolean().oneOf(
    [true],
    messages.termsAndPrivacyAgreeRequired.id,
  ),
});

export const SignupSchema = Yup.object()
  .shape({
    type: Yup.string().nullable().required(messages.typeRequired.id),
    first_name: Yup.string().required(messages.firstNameRequired.id),
    last_name: Yup.string().required(messages.lastNameRequired.id),
    phone: Yup.string().required(messages.phoneRequired.id),
    password2: Yup.string().required(messages.password2Required.id),
  })
  .concat(SignupBaseSchema);
