import { useFormik } from 'formik';
import FormAction from '@app/components/FormAction';
import FormError from '@app/components/FormError';
import FormGroup from '@app/components/FormGroup';
import FormLabel from '@app/components/FormLabel';
import { LoginRequest } from '@app/types/LoginRequest';
import { SignInSchema } from './consts';
import { FormattedMessage, useIntl } from 'react-intl';
import React from 'react';
import messages from './messages';
import { Link } from 'react-router-dom';
import { RouterUrl } from '@app/navigation/consts';
import classNames from 'classnames';

export type SignInFormProps = {
  onSubmit: (values: LoginRequest) => void;
  disabled?: boolean;
  error?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  compact?: boolean;
};

const SignInForm: React.FC<SignInFormProps> = ({
  onSubmit,
  disabled,
  error,
  compact,
}) => {
  const { formatMessage } = useIntl();
  const formik = useFormik<LoginRequest>({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: SignInSchema,
    onSubmit,
  });

  return (
    <form
      className="c-form ui-form ui-color--navy-01"
      autoComplete="off"
      onSubmit={formik.handleSubmit}
    >
      <div className="g-row">
        <FormGroup
          className="g-gutter-bottom-0-75"
          error={
            formik.touched.email &&
            formik.errors.email &&
            formatMessage({ id: formik.errors.email })
          }
        >
          <FormLabel htmlFor="email">
            <FormattedMessage {...messages.emailLabel} />
          </FormLabel>
          <input
            className="f-field__control"
            placeholder={formatMessage(messages.emailPlaceholder)}
            type="email"
            disabled={disabled}
            {...formik.getFieldProps('email')}
          />
        </FormGroup>

        <FormGroup
          className="g-gutter-bottom-0-75"
          error={
            formik.touched.password &&
            formik.errors.password &&
            formatMessage({ id: formik.errors.password })
          }
        >
          <FormLabel htmlFor="password">
            <FormattedMessage {...messages.passwordLabel} />
          </FormLabel>
          <input
            className="f-field__control"
            placeholder={formatMessage(messages.passwordPlaceholder)}
            type="password"
            disabled={disabled}
            {...formik.getFieldProps('password')}
          />
        </FormGroup>

        <FormAction className="g-gutter-top-1">
          {!compact && (
            <Link
              to={RouterUrl.ForgotPassword}
              className="c-form__action-link ui-color--navy-02 t-body-2"
            >
              <FormattedMessage {...messages.forgotPassword} />
            </Link>
          )}
          <button
            type="submit"
            className={classNames('c-button c-button--primary', {
              'c-button--to-right': compact,
            })}
            disabled={disabled}
          >
            <span className="c-button__label">
              <FormattedMessage {...messages.submitButton} />
            </span>
          </button>
        </FormAction>

        {error && (
          <div className="g-col-12">
            <FormError error={error?.response?.data} />
          </div>
        )}
      </div>
    </form>
  );
};

export default SignInForm;
