import useAuth from '@app/hooks/useAuth';
import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { RouterUrl } from './consts';

const PrivateRoute: React.FC<RouteProps> = ({
  component: Component,
  ...props
}) => {
  const { user, initialised } = useAuth();
  return (
    <Route
      {...props}
      render={(props) =>
        user && Component ? (
          <Component {...props} />
        ) : initialised ? (
          <Redirect
            to={{
              pathname: RouterUrl.SignIn,
              search: new URLSearchParams({
                redirectTo: `${props.location.pathname}${props.location.search}${props.location.hash}`,
              }).toString(),
            }}
          />
        ) : (
          <></>
        )
      }
    />
  );
};

export default PrivateRoute;
