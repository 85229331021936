import { useField } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import FormGroup from './FormGroup';
import FormLabel from './FormLabel';
import Select, { SelectProps } from './Select';

export interface FormSelectFieldProps extends SelectProps {
  name: string;
  label?: string;
}

const FormSelectField: React.FC<FormSelectFieldProps> = ({
  name,
  className,
  label,
  children,
  ...inputProps
}) => {
  const [field, { touched, error }] = useField(name);
  const { formatMessage } = useIntl();

  return (
    <FormGroup
      className={className}
      error={touched && error && formatMessage({ id: error })}
    >
      <FormLabel htmlFor={field.name}>{label}</FormLabel>
      <Select {...field} {...inputProps}>
        {children}
      </Select>
    </FormGroup>
  );
};

export default FormSelectField;
