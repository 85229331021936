import { Document } from '@app/types/Document';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import React, { useMemo } from 'react';
import { serviceMessages } from '@app/messages';
import messages from './messages';
import FormattedRelativeDateTime from '@app/components/FormattedRelativeDateTime';
import { generatePath } from 'react-router';
import { RouterUrl } from '@app/navigation/consts';
import { Link } from 'react-router-dom';

export type DocumentDetailsProps = {
  document?: Document | null;
};

const DocumentDetails: React.FC<DocumentDetailsProps> = ({ document }) => {
  const { formatMessage, formatNumber } = useIntl();

  const services = useMemo(
    () =>
      [
        document?.order_document?.translation &&
          formatMessage(serviceMessages.translation),
        document?.order_document?.proofreading &&
          formatMessage(serviceMessages.proofreading),
        document?.order_document?.formatting &&
          formatMessage(serviceMessages.formatting),
        document?.order_document?.editing &&
          formatMessage(serviceMessages.editing),
        document?.order_document?.plagiarism &&
          formatMessage(serviceMessages.plagiarism),
      ]
        .filter((v) => !!v)
        .join(', '),
    [formatMessage, document?.order_document],
  );

  const receiptLink = useMemo(() => {
    if (!document?.order_document?.order?.payment?.extra_data) {
      return null;
    }

    const extraData = JSON.parse(
      document?.order_document?.order?.payment?.extra_data,
    );

    return extraData.receipt_url as string;
  }, [document?.order_document?.order?.payment?.extra_data]);

  return (
    <div className="c-sidebar-order-details__informations">
      <table cellSpacing="0" cellPadding="0">
        <tbody>
          <tr>
            <td
              className="order"
              data-th={formatMessage(messages.orderIdLabel)}
            >
              {document?.order_document?.order?.id || '-'}
            </td>
            <td
              className="words"
              data-th={formatMessage(messages.wordsCountLabel)}
            >
              {document?.words_count ? (
                <FormattedNumber value={document?.words_count} />
              ) : (
                '-'
              )}
            </td>
            <td
              className="services"
              data-th={formatMessage(messages.servicesLabel)}
            >
              {services}
            </td>
            <td
              className="submited"
              data-th={formatMessage(messages.createdAtLabel)}
            >
              {document?.created_at ? (
                <FormattedRelativeDateTime value={document?.created_at} />
              ) : (
                '-'
              )}
            </td>
            <td
              className="expected"
              data-th={formatMessage(messages.deliveryExpectedAtLabel)}
            >
              {document?.order_document?.delivery_expected_at ? (
                <FormattedRelativeDateTime
                  value={document?.order_document?.delivery_expected_at}
                />
              ) : (
                '-'
              )}
            </td>
            <td className="price" data-th={formatMessage(messages.priceLabel)}>
              {document?.order_document?.total_amount
                ? `${formatNumber(document.order_document?.total_amount, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })} ${document.order_document?.order?.currency}`
                : '-'}
            </td>
            <td
              className="original-file"
              data-th={formatMessage(messages.originalFileLabel)}
            >
              {document?.file ? (
                <a
                  className="ui-link-basic-underlined"
                  href={document.file}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FormattedMessage {...messages.download} />
                </a>
              ) : (
                '-'
              )}
            </td>
            <td
              className="clean-copy"
              data-th={formatMessage(messages.cleanCopyFileLabel)}
            >
              {document?.clean_copy_file ? (
                <a
                  className="ui-link-basic-underlined"
                  href={document?.clean_copy_file}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FormattedMessage {...messages.download} />
                </a>
              ) : (
                '-'
              )}
            </td>
            <td
              className="tracked-changes"
              data-th={formatMessage(messages.trackedChangesFileLabel)}
            >
              {document?.tracked_changes_file ? (
                <a
                  className="ui-link-basic-underlined"
                  href={document?.tracked_changes_file}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FormattedMessage {...messages.download} />
                </a>
              ) : (
                '-'
              )}
            </td>
            <td
              className="certificate"
              data-th={formatMessage(messages.certificateFileLabel)}
            >
              {document?.certificate_file ? (
                <a
                  className="ui-link-basic-underlined"
                  href={document?.certificate_file}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FormattedMessage {...messages.download} />
                </a>
              ) : (
                '-'
              )}
            </td>
            <td
              className="order-details"
              data-th={formatMessage(messages.orderDetailsLabel)}
            >
              <Link
                className="ui-link-basic-underlined"
                to={generatePath(RouterUrl.Documents, {
                  documentId: document?.id,
                  action: 'order',
                })}
              >
                <FormattedMessage {...messages.orderDetailsLink} />
              </Link>
            </td>
            <td
              className="order-details"
              data-th={formatMessage(messages.receiptLabel)}
            >
              {receiptLink ? (
                <a
                  className="ui-link-basic-underlined"
                  href={receiptLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FormattedMessage {...messages.receiptLink} />
                </a>
              ) : (
                '-'
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default DocumentDetails;
